import React from "react";

/*
 *   Reflection Content Formatting
 *
 *   An ad-hoc solution for improving the visual formatting of
 *   safety-sensitive responses. We will establish a more scalable
 *   approach iteratively from here based on our needs.
 *
 */

//   FORMAT PATTERNS

/*   Numbered Substrings  */
//   e.g. '1) Uno'  '2) Dos'  '3) Tres'

/*   Standard or Numbered Substrings  */
//   e.g. 'Please follow these steps:'  '1) This'  '2) That'  '3) Something Else'
const matchStdOrNumSubstrings = /(^.)(?:(?!\d+\)).)*|(\d+\))(?:(?!\d+\)).)*/g;

const wrapStringInDiv = (displayString: string): any => (
  <div key={displayString} style={{ marginBottom: "15px" }}>
    {displayString}
  </div>
);

const formatContent = (content: string): HTMLDivElement[] => {
  const matched = content.match(matchStdOrNumSubstrings);
  if (matched) {
    return matched.map((substring) => wrapStringInDiv(substring));
  }
  return [];
};

export default formatContent;
