/** Adds CSRF cookie to headers. */
export const configureCSRF = (headers = {}) => {
  return {
    ...headers,
    "X-CSRF-TOKEN": window.virta.csrf_token,
  };
};

export const asyncConfigureCSRF = async (headers = {}) =>
  configureCSRF(headers);
