import classNames from "classnames/bind";
import React from "react";

import styles from "./styles.css";

const cx = classNames.bind(styles);

interface Props {
  title?: string;
  children: any;
  bordered?: boolean;
}

const TableView: React.FC<Props> = ({
  title,
  children,
  bordered,
}: Props): React.ReactElement<any> => (
  <div>
    {title && <div className={styles.title}>{title}</div>}
    <div className={cx(styles.tableview, { bordered })}>{children}</div>
  </div>
);

export default TableView;
