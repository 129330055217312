import { AnyAction, Dispatch, Middleware } from "redux";

import { BiomarkerFeedback } from "../biomarker_feedback_types";
import {
  SUBMIT_KETONES_FAILURE,
  SUBMIT_KETONES_SUCCESS,
} from "../constants/ActionTypes";

import {
  BiomarkerSubmissionStatus,
  logEventBiomarkerSubmission,
} from "./analytics";

export const logAnalytics: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === SUBMIT_KETONES_SUCCESS) {
      logEventBiomarkerSubmission(
        BiomarkerSubmissionStatus.Success,
        action.biomarkerType,
        action.timestamp,
        {
          ketone_feedback_values: action.feedback.map(
            (f: BiomarkerFeedback) => f.key
          ),
        }
      );
    }

    if (action.type === SUBMIT_KETONES_FAILURE) {
      logEventBiomarkerSubmission(
        BiomarkerSubmissionStatus.Fail,
        action.biomarkerType,
        action.timestamp,
        {
          ketone_feedback_values: action.feedback.map(
            (f: BiomarkerFeedback) => f.key
          ),
        }
      );
    }

    return next(action);
  };
