import {
  TOAST_NOTIFICATION_DISMISSED,
  TOAST_NOTIFICATION_TRIGGERED,
} from "../../../constants/ActionTypes";

import { normalizeToastNotification } from "./schema";
import { NotificationKind } from "./types";

/**
 * Creates an action object of for the TOAST_NOTIFICATION_TRIGGERED type
 * @param kind - the type of notification to send
 * @param message - the message to display
 * @param timeoutLength - the amount of time to display the message for, in milliseconds
 * @returns a dispatchable action object
 */
export const triggerToastNotification = (
  kind: NotificationKind,
  message: string,
  timeoutLength = 4500
) => {
  const notificationId = String(Date.now());
  return {
    type: TOAST_NOTIFICATION_TRIGGERED as typeof TOAST_NOTIFICATION_TRIGGERED,
    payload: normalizeToastNotification({
      kind,
      message,
      notificationId,
    }),
    meta: {
      timeoutLength,
      notificationId,
    },
  };
};

/**
 * Dismisses a toast notification based on the notificationId
 * @param notificationId - the id of the notification to dismiss
 * @returns a dispatchable action object
 */
export const dismissToastNotification = (notificationId: string) => ({
  type: TOAST_NOTIFICATION_DISMISSED as typeof TOAST_NOTIFICATION_DISMISSED,
  notificationId,
});
