import {
  RECEIVE_GRAPH_DATA,
  TOGGLE_GRAPH_TIMEFRAME,
} from "../constants/ActionTypes";

export const graphData = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_GRAPH_DATA:
      return action.graphData;
    default:
      return state;
  }
};

export const graphTimeframe = (state = "month", action: any) => {
  switch (action.type) {
    case TOGGLE_GRAPH_TIMEFRAME:
      return action.graphTimeframe;
    default:
      return state;
  }
};
