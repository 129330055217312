import PropTypes from "prop-types";
import React from "react";

import {
  METRIC_CARD_TYPE_FOR_TASK_TYPE,
  METRIC_TASK_TYPES_LIST,
} from "../../../task_cards/constants";
import { isIOs } from "../../../virta_user_agent";
import ActionTaskCard from "../ActionTaskCard";
import HealthAssessmentCard from "../HealthAssessmentCard";
import VideoTaskCard from "../VideoTaskCard";
import VideoTaskCardNativeMobilePlayer from "../VideoTaskCardNativeMobilePlayer";

const _shouldUseNativePlayer = (userAgent) => isIOs(userAgent);

function _getVideoTaskCardForUserAgent(task) {
  if (_shouldUseNativePlayer(window.navigator.userAgent)) {
    return <VideoTaskCardNativeMobilePlayer key={task.id} task={task} />;
  }
  return <VideoTaskCard testId={task.type} key={task.id} task={task} />;
}

export default function CardFromTask({ task }) {
  if (METRIC_TASK_TYPES_LIST.indexOf(task.type) !== -1) {
    return (
      <HealthAssessmentCard
        testId={task.type}
        key={task.id}
        task={task}
        type={METRIC_CARD_TYPE_FOR_TASK_TYPE[task.type]}
      />
    );
  }

  if (task.type === "video") {
    return _getVideoTaskCardForUserAgent(task);
  }

  if (task.type === "action") {
    return <ActionTaskCard key={task.id} task={task} />;
  }

  return null;
}

CardFromTask.propTypes = {
  task: PropTypes.object.isRequired,
};
