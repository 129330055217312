import { combineReducers } from "redux";

import {
  SET_ASSISTANT_OPEN,
  SET_ASSISTANT_SEARCH_TERM,
} from "../constants/ActionTypes";

export interface AssistantState {
  isAssistantOpen: boolean;
  assistantSearchTerm: string;
}

export const isAssistantOpen = (state = false, action: any) => {
  switch (action.type) {
    case SET_ASSISTANT_OPEN:
      return action.isOpen;
    default:
      return state;
  }
};

export const assistantSearchTerm = (state = "", action: any) => {
  switch (action.type) {
    case SET_ASSISTANT_SEARCH_TERM:
      return action.searchTerm;
    default:
      return state;
  }
};

export default combineReducers({
  isAssistantOpen,
  assistantSearchTerm,
});
