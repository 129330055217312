import PropTypes from "prop-types";
import React from "react";

import { AjaxButton } from "../../../common-components/Button";
import { logTaskClickEvent } from "../../../lib/analytics";
import store from "../../../store";
import { completeTask } from "../../../task_cards/actions";
import Card from "../Card";

const OneButtonActionTaskCard = ({ task }) => {
  const { primary_cta_label, id, icon, header, title, type } = task;
  const dispatchCompleteTask = (errorHandler) =>
    store.dispatch(completeTask(id, errorHandler));
  const primaryButton = (
    <AjaxButton
      name={title}
      ajaxAction={(togglePendingState) => {
        dispatchCompleteTask(togglePendingState);
        logTaskClickEvent(
          {
            taskName: title,
            taskText: primary_cta_label,
            taskType: type,
          },
          {
            buttonCategory: "progression",
            ctaUrl: null,
            phaseTaskCardType: type,
            phaseContentSequenceId: id,
          }
        );
      }}
      style="PrimaryBtnLight"
    >
      {primary_cta_label}
    </AjaxButton>
  );
  return (
    <Card
      testId="OneButtonActionTaskCard"
      primaryButton={primaryButton}
      type={icon}
      header={header}
      name={title}
    />
  );
};

OneButtonActionTaskCard.propTypes = {
  task: PropTypes.object.isRequired,
};

export default OneButtonActionTaskCard;
