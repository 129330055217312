import React from "react";

import PAModal from "../../common-components/PAModal/";
import { UnlockedVideoPlayer } from "../../educational_tools/components/HeroVideoPlayer/";
import { logVideoEvent } from "../../lib/analytics";

import styles from "./styles.css";

const STOPPED_VIDEO_EVENT_NAME = "stopped";
interface Props {
  isOpen: boolean;
  closeFn: () => void;
  id: number;
  videoUrl: string;
  posterUrl: string;
  autoPlay?: boolean;
  contentLabel: string;
  videoTitle: string;
}

class PAVideoModal extends React.Component<Props> {
  public childRef: any = null;
  public video: any = null;
  constructor(props: Props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  public render() {
    const {
      isOpen,
      closeFn,
      id,
      videoUrl,
      videoTitle,
      posterUrl,
      autoPlay = true,
      contentLabel = "Video",
    } = this.props;

    return (
      <PAModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        requestCloseFn={() => {
          logVideoEvent({
            action: STOPPED_VIDEO_EVENT_NAME,
            videoId: this.props.id,
            videoTitle: this.props.videoTitle,
            videoDuration: this.childRef.duration,
            videoEventTime: this.childRef.currentTime,
            currentUrl: window.location.href,
          });
          closeFn();
        }}
      >
        <div className={styles.videoContainer}>
          <UnlockedVideoPlayer
            setRef={this.setRef}
            ref={(elem) => {
              this.video = elem;
            }}
            id={id}
            videoTitle={videoTitle}
            videoUrl={videoUrl}
            posterUrl={posterUrl}
            autoPlay={autoPlay}
          />
        </div>
      </PAModal>
    );
  }

  private setRef(input: any) {
    this.childRef = input;
  }
}

export default PAVideoModal;
