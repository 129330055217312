import React from "react";

interface Props {
  task: {
    id: string;
    video_id: number;
    video_source_url: string;
  };
}

class NativeMobileVideoPlayer extends React.Component<Props> {
  public render() {
    const { id, video_id, video_source_url } = this.props.task;

    return (
      <video
        id={id}
        data-role="video_task_card_player"
        data-video-id={video_id}
        style={{ display: "none" }}
      >
        <source
          key={`${id}-video-source`}
          src={video_source_url}
          type="video/mp4"
        />
      </video>
    );
  }
}

export default NativeMobileVideoPlayer;
