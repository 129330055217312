import React, { ReactNode } from "react";

import VirtaIcon from "./../VirtaIcon";

interface Props {
  title: string;
}

const PageHeader: React.FC<Props> = ({ title }: Props) => (
  <PageHeaderContainer>
    <span>{title}</span>
  </PageHeaderContainer>
);

interface PageHeaderContainerProps {
  children?: ReactNode | undefined;
}

export const PageHeaderContainer: React.FC<PageHeaderContainerProps> = ({
  children,
}: PageHeaderContainerProps) => (
  <div className="page-title-bar">
    <div className="Grid">
      <div className="Cell s2">
        <a className="navbar-logo" href="/">
          <VirtaIcon />
        </a>
      </div>
      <div className="Cell s8">
        <div className="page-title">{children}</div>
      </div>
      <div className="Cell s2" />
    </div>
  </div>
);

export default PageHeader;
