import {
  getCarePlanFailure,
  getCarePlanRequest,
  getCarePlanSuccess,
} from "./action_creators";
import { retrieveUserCarePlan } from "./api";

export const fetchUserCarePlan = () => (dispatch) => {
  dispatch(getCarePlanRequest());

  return retrieveUserCarePlan()
    .then((json) => {
      dispatch(getCarePlanSuccess(json));
    })
    .catch((error) => {
      const errMessage =
        (error && error.message) ||
        "There was an issue retrieving your Care Plan";
      dispatch(getCarePlanFailure(errMessage));
    });
};
