import { HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";

import {
  GraphQLClient,
  GraphQLInMemoryCache,
  createObservabilityLink,
  useQuery,
} from "@virtahealth/utils";

import { configureCSRF } from "./utils/csrf_utils";

export const useCachedQueryWithExpiration = (
  query: any,
  params: any,
  cacheKey: string,
  exp = 3600
) => {
  let fetchPolicy = "network-only";
  const now = new Date();
  const cachedAt = localStorage.getItem(cacheKey);
  if (cachedAt) {
    const cachedDate = new Date(cachedAt);
    const diff = (now.getTime() - cachedDate.getTime()) / 1000;
    if (diff < exp) {
      fetchPolicy = "cache-first";
    }
  }
  localStorage.setItem(cacheKey, now.toISOString());

  return useQuery(query, { ...params, fetchPolicy });
};

const cache = new GraphQLInMemoryCache({
  typePolicies: {
    PatientUser: {
      keyFields: ["virtaId"],
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: configureCSRF(headers),
  };
});

let client: GraphQLClient<any> | null = null;

const gqlClient = async (useCache = true) => {
  if (client && useCache) {
    return client;
  }
  await init(useCache);
  return client;
};

const init = async (useCache = true) => {
  if (useCache) {
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  client = new GraphQLClient({
    cache,
    link: authLink.concat(
      createObservabilityLink().concat(
        new HttpLink({
          uri: "/graphql",
          credentials: "same-origin",
        })
      )
    ),
  });
};

init();

export default gqlClient;
