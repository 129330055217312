export const discover_tab_search = "discover_tab_search";
export const deprecate_wordpress_rc_redirect_exceptions =
  "deprecate-wordpress-rc-redirect-exceptions";
export const discover_tab = "discover_tab";
// TODO: this flag is fully on - this can be deprecated and removed from the codebase
export const sanity_actions = "cp-onboarding-sanity-actions";
export const content_recommendations = "content-recommendations";
export const community_small_groups = "pa_small_groups";
export const biomarker_achievement_service = "achievement_service_enabled";
export const enable_native_supplies_form = "native-supply-request-form";
export const assistant_web_enabled = "assistant_web_enabled";
export const assistant_card_feedback = "assistant_card_feedback";
export const assistant_community_comments = "assistant-community-comments";
export const assistant_chat_messages = "assistant-chat-messages";
export const display_virta_insights = "display-virta-insights";
// TODO: this flag is fully on - this can be deprecated and removed from the codebase
export const use_refresh_token = "use-refresh-token-pa-web";
export const bookmarks = "bookmarks";
export const hide_unused_features = "hide_unused_features";
export const hide_healthy_habits = "hide-healthy-habits";
