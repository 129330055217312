import { keyBy } from "lodash";
import { combineReducers } from "redux";

import {
  GET_USER_ACTION_LIST_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_SUCCESS,
  SUBMIT_NEW_SETTINGS_FAILURE,
  SUBMIT_NEW_SETTINGS_REQUEST,
  SUBMIT_NEW_SETTINGS_SUCCESS,
  UPDATE_NEW_SETTINGS,
  UPDATE_USER_SETTINGS,
} from "../constants/ActionTypes";
import { RootAction } from "../types";

import { SUBMISSION_STATUS } from "./constants";

export type UserNotificationSetting =
  | "notification:community:like"
  | "notification:community:comment"
  | "notification:community:activity"
  | "action-list:reminders";

export type UserSettingName = UserNotificationSetting;

export type UserSettingValue = "enabled" | "disabled";
export interface UserSettingJSON {
  created_on?: string;
  name: UserSettingName;
  type: string;
  updated_on?: string;
  user_id: number;
  value: UserSettingValue;
}

export interface UserSetting {
  name: UserSettingName;
  value: string;
}

export interface State {
  settings: UserSetting[];
  newSettings: UserSetting[];
  newSettingsSubmitStatus: string;
}

const defaultSettings: UserSetting[] = [];
const defaultNewSettings: UserSetting[] = [];

export const settings = (
  state: UserSetting[] = defaultSettings,
  action: RootAction
) => {
  switch (action.type) {
    case GET_USER_SETTINGS_SUCCESS:
    case UPDATE_USER_SETTINGS:
    case GET_USER_ACTION_LIST_SETTINGS_SUCCESS:
      const keyedSettings = keyBy(action.settings, (setting) => setting.name);
      return {
        ...state,
        ...keyedSettings,
      };
    default:
      return state;
  }
};

export const newSettings = (
  state: UserSetting[] = defaultNewSettings,
  action: RootAction
) => {
  switch (action.type) {
    case UPDATE_NEW_SETTINGS:
      return action.settings;
    case SUBMIT_NEW_SETTINGS_SUCCESS:
      return [];
    default:
      return state;
  }
};

export const newSettingsSubmitStatus = (state = "", action: RootAction) => {
  switch (action.type) {
    case SUBMIT_NEW_SETTINGS_SUCCESS:
      return SUBMISSION_STATUS.SUCCESS;
    case SUBMIT_NEW_SETTINGS_FAILURE:
      return SUBMISSION_STATUS.FAILURE;
    case SUBMIT_NEW_SETTINGS_REQUEST:
      return SUBMISSION_STATUS.REQUEST;
    default:
      return state;
  }
};

export const userSettings = combineReducers<State, RootAction>({
  settings,
  newSettings,
  newSettingsSubmitStatus,
});

// selectors
export const selectUserSettings = (state: any) =>
  Object.keys(state.userSettings.settings).map(
    (key) => state.userSettings.settings[key]
  );
export const settingsSubmitStatus = (state: any) =>
  state.userSettings.newSettingsSubmitStatus;
