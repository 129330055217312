import React from "react";

import styles from "./styles.css";

interface Props {
  currentProgress: number;
}

const ProgressBar: React.FC<Props> = ({ currentProgress }: Props) => {
  const currentProgressPercentage = `${currentProgress}%`;
  const progressBarLeftOffset = `${currentProgress - 100}%`;

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBarGroove}>
        <div
          className={styles.progressBar}
          style={{ left: progressBarLeftOffset }}
        />
      </div>
      <div className={styles.currentProgressIndicator}>
        {currentProgressPercentage}
      </div>
    </div>
  );
};

export default ProgressBar;
