import React from "react";

import {
  intlFormatMessage,
  messages as intlMessages,
} from "@virtahealth/utils";

import { Button } from "../../common-components/Button";
import PAModal, {
  PAModalBody,
  PAModalFooter,
  PAModalHeader,
} from "../../common-components/PAModal/";
import formatContent from "../formatting";

interface Props {
  isOpen: boolean;
  response: any;
  closeFn: () => void;
}

const ReflectionResponseModal = ({ isOpen, response, closeFn }: Props) => {
  const { title, content } = response;

  return (
    <PAModal
      style={{ content: { maxWidth: 650 } }}
      contentLabel={intlFormatMessage(
        intlMessages.glucoseAccessibilityMessages
      )}
      isOpen={isOpen}
      requestCloseFn={closeFn}
    >
      <PAModalHeader>{title}</PAModalHeader>
      <PAModalBody>{formatContent(content)}</PAModalBody>
      <PAModalFooter>
        <Button style={"PrimaryBtn"} onClick={() => closeFn()}>
          {intlFormatMessage(intlMessages.ok)}
        </Button>
      </PAModalFooter>
    </PAModal>
  );
};

export default ReflectionResponseModal;
