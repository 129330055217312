// This is where we will have the top level Redux store
// once PA is a SPA(Single Page Application)
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import patientActions from "./action_list/reducer";
import { carePlan } from "./care_plan/reducers";
import { chatMessages } from "./chat/reducers";
import community from "./community/reducers";
import { notificationTimeoutScheduler } from "./community/state/toast_notifications/middleware";
import {
  allVideos,
  blockedByTasks,
  latestAvailableVideo,
} from "./educational_tools/reducers";
import { goalProgress, phaseModalState } from "./goal_progress/reducers";
import { graphData, graphTimeframe } from "./graphs/reducers";
import { groupedMiniCards, nextUrl } from "./history/reducers";
import { ketonesForm } from "./ketones/reducers";
import { logAnalytics } from "./lib/middleware";
import assistant from "./navbar/reducers";
import { userProfile } from "./profile/reducers";
import { biomarkerTasks, tasks } from "./task_cards/reducers";
import { initializeUser } from "./user/initializers";
import { user } from "./user/reducers";
import { userSettings } from "./user_settings/reducers";
import { weeklySummary } from "./weekly_summary/reducers";

// https://github.com/zalmoxisus/redux-devtools-extension
// Must download the Chrome plugin to access the tools:
// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
const composeEnhancers = composeWithDevTools({});
const middleware = [thunk, notificationTimeoutScheduler, logAnalytics];
const store = createStore(
  combineReducers({
    groupedMiniCards,
    tasks,
    biomarkerTasks,
    goalProgress,
    allVideos,
    latestAvailableVideo,
    blockedByTasks,
    nextUrl,
    phaseModalState,
    chatMessages,
    weeklySummary,
    userProfile,
    userSettings,
    graphData,
    graphTimeframe,
    community,
    ketonesForm,
    carePlan,
    user,
    patientActions,
    assistant,
  }),
  composeEnhancers(applyMiddleware(...middleware))
);

const initializers = () => {
  const dispatch = (action) => store.dispatch(action);
  initializeUser(dispatch);
};

initializers();

export default store;
