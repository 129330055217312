import React from "react";

import { Button } from "../../common-components/Button";
import PAModal, {
  PAModalBody,
  PAModalFooter,
} from "../../common-components/PAModal";

interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
  confirmButtonText?: string;
  onConfirm: () => void;
  requestCloseFn: () => void;
  interstitialMessage?: string;
  showInterstitial?: () => void;
}

const PAConfirmModal: React.FC<Props> = ({
  isOpen,
  children,
  confirmButtonText,
  onConfirm,
  requestCloseFn,
  showInterstitial,
  interstitialMessage,
}: Props) => {
  const DEFAULT_MESSAGE = "Are you sure you want to complete this action?";
  const onConfirmClick = () => {
    onConfirm();
    if (interstitialMessage || !showInterstitial) {
      requestCloseFn();
    } else {
      showInterstitial();
    }
  };
  const confirmButton = (
    <PAModalFooter>
      <Button style="SecondaryBtn" onClick={requestCloseFn}>
        Cancel
      </Button>
      <Button style="PrimaryBtn" onClick={onConfirmClick}>
        {confirmButtonText || "Confirm"}
      </Button>
    </PAModalFooter>
  );

  return (
    <PAModal isOpen={isOpen} isDismissable={false}>
      <PAModalBody>
        {interstitialMessage || children || DEFAULT_MESSAGE}
      </PAModalBody>
      <PAModalFooter>
        {interstitialMessage ? (
          <Button style="PrimaryBtn" onClick={requestCloseFn}>
            Okay
          </Button>
        ) : (
          confirmButton
        )}
      </PAModalFooter>
    </PAModal>
  );
};

export default PAConfirmModal;
