import {
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import { combineReducers } from "redux";

import {
  COMPLETE_PATIENT_ACTION_SUCCESS,
  LOAD_PATIENT_ACTIONS_SUCCESS,
  LOAD_PATIENT_ACTION_SUCCESS,
} from "../constants/ActionTypes";

import {
  CompletePatientActionSuccessAction,
  LoadPatientActionSuccessAction,
  LoadPatientActionsSuccessAction,
  PatientActionActions,
} from "./actions";
import {
  PatientAction,
  PatientActionCompletion,
  PatientActionIDsByDate,
} from "./types";

export interface PatientActionsStoreState {
  byId: {
    [key: string]: PatientAction;
  };
  byDate: {
    // ISO8601 Date String indicating the start of the day
    [key: string]: Array<PatientAction["patientActionId"]>;
  };
}

export const initialState: PatientActionsStoreState = {
  byId: {},
  byDate: {},
};

const patientActionsByIdReducer = (
  state: PatientActionsStoreState["byId"] = initialState.byId,
  action: PatientActionActions
) => {
  switch (action.type) {
    case LOAD_PATIENT_ACTIONS_SUCCESS:
      const knownAction = action as LoadPatientActionsSuccessAction;
      const keyedById = knownAction.payload.reduce(
        (acc: PatientActionsStoreState["byId"], patientAction) => {
          acc[patientAction.patientActionId] = patientAction;
          return acc;
        },
        {}
      );
      return {
        ...state,
        ...keyedById,
      };

    case LOAD_PATIENT_ACTION_SUCCESS:
      if (action.payload) {
        const loadSuccessAction = action as LoadPatientActionSuccessAction;
        return {
          ...state,
          [loadSuccessAction.meta]: action.payload,
        };
      }

    // eslint-disable-next-line no-fallthrough
    case COMPLETE_PATIENT_ACTION_SUCCESS:
      const patientActionSuccessAction =
        action as CompletePatientActionSuccessAction;
      const patientActionToUpdate = state[patientActionSuccessAction.meta];
      const { completions } = patientActionToUpdate;
      let updatedCompletions: PatientActionCompletion[] = [];

      const updatedCompletion = patientActionSuccessAction.payload;

      // check if a completion exists for this day
      const matchingCompletionIndex = completions.findIndex((completion) => {
        return isSameDay(
          new Date(completion.completedOnUtc),
          new Date(updatedCompletion.completedOnUtc)
        );
      });

      // existing completion has been updated
      if (matchingCompletionIndex > -1) {
        updatedCompletions = [
          ...completions.slice(0, matchingCompletionIndex),
          updatedCompletion,
          ...completions.slice(matchingCompletionIndex + 1),
        ];
      }
      // adding a new completion
      else {
        updatedCompletions = [...completions, updatedCompletion];
      }

      return {
        ...state,
        [patientActionSuccessAction.meta]: {
          ...patientActionToUpdate,
          completions: updatedCompletions,
        },
      };

    default:
      return state;
  }
};

const patientActionsByDateReducer = (
  state: PatientActionsStoreState["byDate"] = initialState.byDate,
  action: LoadPatientActionsSuccessAction
) => {
  switch (action.type) {
    case LOAD_PATIENT_ACTIONS_SUCCESS:
      const { after, before } = action.meta;
      const patientActions = action.payload;
      const interval: Interval = {
        start: new Date(after),
        end: new Date(before),
      };
      const actionIDsByDate = eachDayOfInterval(interval).reduce(
        (byDate: PatientActionIDsByDate, currentDay) => {
          const dateStr = currentDay.toISOString();
          byDate[dateStr] = patientActions
            .filter((patientAction) => {
              const start = new Date(patientAction.startDateUtc);
              const end = patientAction.endDateUtc
                ? new Date(patientAction.endDateUtc)
                : new Date();
              return isWithinInterval(currentDay, {
                start: startOfDay(start),
                end: endOfDay(end),
              });
            })
            .map((patientAction) => patientAction.patientActionId);
          return byDate;
        },
        {}
      );
      return {
        ...state,
        ...actionIDsByDate,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId: patientActionsByIdReducer,
  byDate: patientActionsByDateReducer,
});
