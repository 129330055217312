import React from "react";
import Modal from "react-modal";

import Icon from "../../common-components/Icon/components";

import styles from "./styles.css";

interface ChildProps {
  children: any;
  style?: object;
}

const PAModalHeader: React.FC<ChildProps> = ({
  children,
  style,
}: ChildProps) => (
  <div className={styles.modalHeader} style={style}>
    {children}
  </div>
);
const PAModalBody: React.FC<ChildProps> = ({ children, style }: ChildProps) => (
  <div className={styles.modalBody} style={style}>
    {children}
  </div>
);
const PAModalFooter: React.FC<ChildProps> = ({
  children,
  style,
}: ChildProps) => (
  <div className={styles.modalFooter} style={style}>
    {children}
  </div>
);

interface Props {
  isOpen: boolean;
  children: any;
  contentLabel?: string | null | undefined;
  requestCloseFn?: () => void;
  onAfterOpen?: () => void;
  isDismissable?: boolean;
  style?: object;
  testId?: string;
}

interface CloseButtonProps {
  requestCloseFn?: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  requestCloseFn,
}: CloseButtonProps) => (
  <button
    role="button"
    tabIndex={0}
    aria-label="Close this popup"
    className={styles.closeButton}
    onClick={requestCloseFn}
  >
    <Icon name="CloseColor" />
  </button>
);

const PAModal: React.FunctionComponent<Props> = ({
  isOpen,
  children,
  contentLabel,
  requestCloseFn,
  onAfterOpen,
  isDismissable = true,
  style: inlineStyles,
  testId = "pa-modal",
}: Props) => {
  return isOpen ? (
    <Modal
      testId={testId}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={isDismissable ? requestCloseFn : null}
      onAfterOpen={onAfterOpen}
      className={styles.modalWrapper}
      overlayClassName={styles.overlay}
      closeTimeoutMS={500}
      style={inlineStyles}
      shouldCloseOnOverlayClick={isDismissable}
      ariaHideApp={false}
    >
      <div className={styles.modalBox}>
        {isDismissable && <CloseButton requestCloseFn={requestCloseFn} />}
        {children}
      </div>
    </Modal>
  ) : null;
};

export { PAModal as default, PAModalHeader, PAModalBody, PAModalFooter };
