import React from "react";

import { ColorValue } from "@virtahealth/design-styles";

import styles from "./styles.css";

interface Props {
  title: string;
  icon?: string; // icon name that references CSS class
  color?: ColorValue;
  fullWidth?: boolean;
  subtitle?: string;
}

const SectionHeader: React.FC<Props> = ({
  title,
  icon,
  color,
  fullWidth,
  subtitle,
}) => (
  <div
    className={`${styles.container} ${
      fullWidth ? styles.containerFullWidth : ""
    }`}
  >
    <div className={`${styles.titleIconRow} ${subtitle && "mb1"}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.iconContainer} style={{ backgroundColor: color }}>
        <i className={`icon-${icon} color-white`} />
      </div>
    </div>
    {subtitle && <div className="explanatory">{subtitle}</div>}
  </div>
);

export default SectionHeader;
