export const BADGE_IMAGE_PATH = "/static/imgs/achievements/";

export const GLUCOSE_STREAK_BADGE_IMAGES = {
  "achievement-glucose-3-days-streak": "achievement-glucose-3-days-streak.svg",
  "achievement-glucose-5-days-streak": "achievement-glucose-5-days-streak.svg",
  "achievement-glucose-1-week-streak": "achievement-glucose-1-week-streak.svg",
  "achievement-glucose-2-weeks-streak":
    "achievement-glucose-2-weeks-streak.svg",
  "achievement-glucose-1-month-streak":
    "achievement-glucose-1-month-streak.svg",
  "achievement-glucose-2-months-streak":
    "achievement-glucose-2-months-streak.svg",
  "achievement-glucose-3-months-streak":
    "achievement-glucose-3-month-streak.svg",
};

export const KETONES_STREAK_BADGE_IMAGES = {
  "achievement-ketones-3-days-streak": "achievement-ketones-3-days-streak.svg",
  "achievement-ketones-5-days-streak": "achievement-ketones-5-days-streak.svg",
  "achievement-ketones-1-week-streak": "achievement-ketones-1-week-streak.svg",
  "achievement-ketones-2-weeks-streak":
    "achievement-ketones-2-weeks-streak.svg",
  "achievement-ketones-1-month-streak":
    "achievement-ketones-1-month-streak.svg",
  "achievement-ketones-2-months-streak":
    "achievement-ketones-2-months-streak.svg",
  "achievement-ketones-3-months-streak":
    "achievement-ketones-3-months-streak.svg",
};

export const KETOSIS_STREAK_BADGE_IMAGES = {
  "achievement-back-in-ketosis": "achievement-back-in-ketosis.svg",
  "achievement-ketosis-3-days-streak": "achievement-ketosis-3-days-streak.svg",
  "achievement-ketosis-5-days-streak": "achievement-ketosis-5-days-streak.svg",
  "achievement-ketosis-1-week-streak": "achievement-ketosis-1-week-streak.svg",
  "achievement-ketosis-2-weeks-streak": "achievement-ketosis-2-week-streak.svg",
  "achievement-ketosis-1-month-streak":
    "achievement-ketosis-1-month-streak.svg",
  "achievement-ketosis-2-months-streak":
    "achievement-ketosis-2-months-streak.svg",
  "achievement-ketosis-3-months-streak":
    "achievement-ketosis-3-months-streak.svg",
};

export const BADGE_IMAGES: { [key: string]: string } = {
  placeholder: "placeholder_badge.png",
  ketones_entered_nutritional_ketosis:
    "ketones_entered_nutritional_ketosis.png",
  ketones_in_optimal_ketosis_range: "ketones_in_optimal_ketosis_range.png",
  glucose_out_of_diabetic_range: "glucose_out_of_diabetic_range.png",
  ...GLUCOSE_STREAK_BADGE_IMAGES,
  ...KETONES_STREAK_BADGE_IMAGES,
  ...KETOSIS_STREAK_BADGE_IMAGES,
};
