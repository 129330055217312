const _KETONE_TASK_TYPE = "ketone_measurement";
const _SYMPTOMS_TASK_TYPE = "symptoms";
const _BLOOD_PRESSURE_TASK_TYPE = "blood_pressure_measurement";
const _WEIGHT_TASK_TYPE = "weight_measurement";
const _GLUCOSE_TASK_TYPE = "glucose_measurement";
export const VIDEO_TASK_TYPE = "video";
export const ACTION_TASK_TYPE = "action";

export const METRIC_TASK_TYPES_LIST = [
  _KETONE_TASK_TYPE,
  _SYMPTOMS_TASK_TYPE,
  _BLOOD_PRESSURE_TASK_TYPE,
  _WEIGHT_TASK_TYPE,
  _GLUCOSE_TASK_TYPE,
];

export const METRIC_CARD_TYPE_FOR_TASK_TYPE = {
  [_KETONE_TASK_TYPE]: "Ketones",
  [_SYMPTOMS_TASK_TYPE]: "Symptoms",
  [_BLOOD_PRESSURE_TASK_TYPE]: "BloodPressure",
  [_WEIGHT_TASK_TYPE]: "Weight",
  [_GLUCOSE_TASK_TYPE]: "Glucose",
};
