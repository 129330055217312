import { get, map, toPairs } from "lodash";
import moment from "moment";

import charan from "../charan_csrf_wrapper";

const _mappedMiniCard = (miniCard) => ({
  name: get(miniCard, "activity_name"),
  type: get(miniCard, "activity_type_id"),
  unit: get(miniCard, "unit_types_string"),
  value: get(miniCard, "value"),
  verbForActivity: get(miniCard, "verb_for_activity"),
  time: moment(get(miniCard, "occurred_on"), "YYYY-MM-DD HH:mm:ss").format(
    "h:mm a"
  ),
});

const _mappedGroupedMiniCards = (miniCardsPerDate) => {
  const [date, miniCards] = toPairs(miniCardsPerDate)[0];
  return { [date]: map(miniCards, _mappedMiniCard) };
};

const decomposeJSON = (json) => ({
  nextUrl: json.next || null,
  groupedMiniCards: map(json.data, _mappedGroupedMiniCards),
});

export const retrieveMiniCards = (url) =>
  charan.get(url).then((json) => decomposeJSON(json));

const api = { retrieveMiniCards };

export default api;
