import { Reflection, ReflectionData } from "../biomarker_feedback_types";

import api from "./api";

function submitReflection(
  reflection: ReflectionData,
  reflectionresponseModalCallback: (response: Reflection) => void
): Promise<void> {
  if (!reflection) {
    return Promise.resolve();
  }

  return api
    .postReflection(reflection)
    .then((data: any) => {
      const response = data.feedback[0];
      setTimeout(() => reflectionresponseModalCallback(response), 300);
    })
    .catch(() => Promise.resolve());
}

export default submitReflection;
