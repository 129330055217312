import * as Sentry from "@sentry/browser";

import {
  RECEIVE_MINI_CARDS,
  RECEIVE_NEXT_HISTORY_URL,
} from "../constants/ActionTypes";

import api from "./api";

export const fetchMiniCards = (url) => (dispatch, getState) =>
  api
    .retrieveMiniCards(url || getState().nextUrl)
    .then(({ nextUrl, groupedMiniCards }) => {
      dispatch({ type: RECEIVE_MINI_CARDS, groupedMiniCards });
      dispatch({ type: RECEIVE_NEXT_HISTORY_URL, nextUrl });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
