import {
  GET_CARE_PLAN_FAILURE,
  GET_CARE_PLAN_REQUEST,
  GET_CARE_PLAN_SUCCESS,
} from "../constants/ActionTypes";

interface CareTeamMemberJSON {
  fullname: string | null;
  photo_url: string | null;
}

interface UserCareTeamJSON {
  permanent_coach: CareTeamMemberJSON;
  covering_coach: CareTeamMemberJSON | null;
  permanent_clinician: CareTeamMemberJSON | null;
  covering_clinician: CareTeamMemberJSON | null;
}

export interface CareTeamMember {
  fullname: string | null;
  photoUrl: string | null;
}

export interface UserCareTeam {
  permanentCoach: CareTeamMember;
  coveringCoach: CareTeamMember | null;
  permanentClinician: CareTeamMember | null;
  coveringClinician: CareTeamMember | null;
}

export type UserTrackingType =
  | "ketone"
  | "weight"
  | "blood_pressure"
  | "glucose"
  | "symptom";

interface UserTrackingJSON {
  type: UserTrackingType;
  formatted_frequency: string;
}

export interface UserTracking {
  type: UserTrackingType;
  formattedFrequency: string;
}

export interface UserNutritionJSON {
  carb_target_grams: number | null;
  protein_target_ounces: number | null;
}

export interface UserNutrition {
  carbTargetGrams: number | null;
  proteinTargetOunces: number | null;
}

export interface UserNutritionState {
  isFetching: boolean;
  error: string | null;
  data: UserNutrition | null;
}

export interface UserMedication {
  id: number;
  proprietaryName: string | null;
  formattedDose: string | null;
  formattedStrength: string | null;
  formattedFrequency: string | null;
  dosageNotes: string | null;
  formattedDescription: string | null;
}

export interface VirtaMedication {
  id: string;
  drugName: string;
  currentDosages: VirtaDosage[];
}

export interface VirtaDosage {
  dose: string;
  unit: string;
  frequency: string;
  additionalInstructions: string;
}

export interface CarePlanJSON {
  user_care_team: UserCareTeamJSON;
  user_tracking: UserTrackingJSON[];
  user_nutrition: UserNutritionJSON;
  user_medications: VirtaMedication[];
}

export interface UserCarePlan {
  userCareTeam: UserCareTeam | null;
  userTracking: UserTracking[];
  userNutrition: UserNutrition | null;
  userMedications: VirtaMedication[];
}

export interface UserCarePlanState {
  isFetching: boolean;
  error: string | null;
  data: UserCarePlan | null;
}

export const initialState = {
  isFetching: false,
  error: null,
  data: null,
};

export const carePlan = (
  state: UserCarePlanState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_CARE_PLAN_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_CARE_PLAN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: parseCarePlan(action.data),
      };
    }
    case GET_CARE_PLAN_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

const parseCarePlan = (data: CarePlanJSON): UserCarePlan | null => {
  if (!data) {
    return null;
  }

  const { user_care_team, user_tracking, user_nutrition, user_medications } =
    data;

  return {
    userCareTeam: parseUserCareTeam(user_care_team),
    userTracking: parseUserTracking(user_tracking),
    userNutrition: parseUserNutrition(user_nutrition),
    userMedications: user_medications,
  };
};

const parseUserCareTeam = (data: UserCareTeamJSON): UserCareTeam | null => {
  if (!data) {
    return null;
  }

  const {
    permanent_coach,
    covering_coach,
    permanent_clinician,
    covering_clinician,
  } = data;

  const coveringCoach = covering_coach
    ? {
        fullname: covering_coach.fullname,
        photoUrl: covering_coach.photo_url,
      }
    : null;

  const permanentClinician = permanent_clinician
    ? {
        fullname: permanent_clinician.fullname,
        photoUrl: permanent_clinician.photo_url,
      }
    : null;

  const coveringClinician = covering_clinician
    ? {
        fullname: covering_clinician.fullname,
        photoUrl: covering_clinician.photo_url,
      }
    : null;

  return {
    permanentCoach: {
      fullname: permanent_coach.fullname,
      photoUrl: permanent_coach.photo_url,
    },
    coveringCoach,
    permanentClinician,
    coveringClinician,
  };
};

const parseUserTracking = (data: UserTrackingJSON[]): UserTracking[] => {
  if (!data) {
    return [];
  }

  return data.map(({ type, formatted_frequency }) => ({
    type,
    formattedFrequency: formatted_frequency,
  }));
};

const parseUserNutrition = (data: UserNutritionJSON): UserNutrition | null => {
  if (!data) {
    return null;
  }

  return {
    carbTargetGrams: data.carb_target_grams,
    proteinTargetOunces: data.protein_target_ounces,
  };
};
