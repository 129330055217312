import { SET_USER } from "../constants/ActionTypes";
import { RootAction } from "../types";
import { PlanDefinitionName } from "../types/planDefinition";

export interface UserState {
  id: number;
  email: string;
  participantType: string;
  participantSubtype?: string;
  adaptationDate?: string;
  registrationDate: string;
  daysOnVirta: number;
  virtaId: string;
  coachId: number;
  planDefinition: PlanDefinitionName;
}

export const user = (state = null, action: RootAction) => {
  switch (action.type) {
    case SET_USER:
      return parseUserData(action.loggedInUser);
    default:
      return state;
  }
};

export const parseUserData = (data: any) => {
  if (!data) {
    return null;
  }
  // because Python named attributes...
  // eslint-disable-next-line camelcase
  const {
    id,
    email,
    participant_subtype,
    participant_type,
    adapted_on,
    days_on_virta,
    registered_on,
    virta_id,
    coach_id,
    current_plan_definition,
  } = data;

  return {
    id,
    email,
    participantType: participant_type,
    participantSubtype: participant_subtype,
    adaptationDate: adapted_on,
    registrationDate: registered_on,
    daysOnVirta: days_on_virta,
    virtaId: virta_id || "",
    coachId: coach_id,
    planDefinition: current_plan_definition || "",
  };
};
