import React from "react";

import styles from "./styles.css";

interface Props {
  children: React.ReactNode;
  orientation?: "vertical" | "horizontal";
  gutters?: boolean;
}

const SpaceBetweenChildren: React.FC<Props> = ({
  children,
  orientation = "vertical",
  gutters = false,
}: Props): React.ReactElement<any> => (
  <div className={`${styles[orientation]} ${gutters ? styles.gutters : ""}`}>
    {children}
  </div>
);

export default SpaceBetweenChildren;
