/* eslint-disable @typescript-eslint/no-explicit-any */
import { VirtaClient } from "@virtahealth/components";

import charan from "../charan_csrf_wrapper";

export class PAClient implements VirtaClient {
  private client: VirtaClient;

  public constructor() {
    this.client = charan;
  }

  public get(url: string, headers?: unknown) {
    return this.client.get(url, headers).then((response: any) => {
      if (response.data) {
        return response.data;
      }

      return response;
    });
  }

  public getCached(url: string, cacheName: string, headers?: unknown) {
    if (this.client.getCached) {
      return this.client
        .getCached(url, cacheName, headers)
        .then((response: any) => {
          if (response.data) {
            return response.data;
          }

          return response;
        });
    } else {
      return Promise.resolve();
    }
  }

  public patch(url: string, body: any) {
    return this.client.patch(url, body).then((response: any) => {
      if (response.data) {
        return response.data;
      }

      return response;
    });
  }

  public post(url: string, body: any) {
    return this.client.post(url, body).then((response: any) => {
      if (response.data) {
        return response.data;
      }

      return response;
    });
  }

  public delete(url: string) {
    return this.client.delete(url).then((response: any) => {
      if (response.data) {
        return response.data;
      }

      return response;
    });
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
