import { includes, startsWith } from "lodash";

export const timestampParamKey = "?t=";
export const getUrlWithoutTimestamp = (url: string): string =>
  url.split(timestampParamKey)[0];
export const getUrlWithTimestamp = (url: string): string =>
  `${url}${timestampParamKey}${new Date().getTime()}`;
// In order for img src to reload, we need a unique url

// determines whether provided url is local to the Virta webpage
export const isVirtaUrl = (url: string) =>
  startsWith(url, "/api/v") ||
  startsWith(url, "/assets") ||
  includes(url, window.location.host);
