import classNames from "classnames/bind";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.css";

const cx = classNames.bind(styles);

interface Props {
  href?: string;
  name?: string;
  onClick?: () => void;
  children: any;
  style?: any;
  additionalStyle?: string;
  id?: string;
  labelText?: string;
  className?: string;
  role?: string;
}

export interface LinkButtonProps {
  href: string;
  name?: string;
  onClick?: () => Promise<any> | void;
  children: any;
  style?: any;
  additionalStyle?: any;
  id?: string;
  labelText?: string;
}

interface ButtonWithNoTracking {
  href?: string;
  onClick?: () => void;
  children: any;
  style?: any;
  additionalStyle?: string;
  id?: string;
  labelText?: string;
  className?: string;
  role?: string;
}

interface ButtonTrackingProperties {
  name: string | null | undefined;
  style: string | null | undefined;
  text: string | null | undefined;
  url: string | null | undefined;
}

const handleButtonClick = (
  buttonProperties: ButtonTrackingProperties,
  onClick?: () => Promise<any> | void
) => {
  if (onClick && typeof onClick === "function") {
    return onClick();
  }
};

/**
 * @deprecated Please use Link from '@virtahealth/design-components'
 */
const LinkButton: React.FC<LinkButtonProps> = ({
  href,
  style,
  name,
  onClick,
  children,
  additionalStyle,
  id,
}: LinkButtonProps) => (
  <Link
    to={href}
    className={cx(style, additionalStyle)}
    onClick={() =>
      handleButtonClick({ style, name, url: href, text: children }, onClick)
    }
    id={id}
  >
    {children}
  </Link>
);

/**
 * @deprecated Please use Button from '@virtahealth/design-components'
 */
const Button: React.FC<Props> = ({
  href,
  style = "PrimaryBtn",
  name,
  onClick,
  children,
  additionalStyle,
  id,
  labelText,
}: Props) => (
  <a
    href={href}
    tabIndex={0}
    role="button"
    aria-label={labelText}
    className={cx(style, additionalStyle)}
    onClick={() =>
      handleButtonClick({ style, name, url: href, text: children }, onClick)
    }
    id={id}
  >
    {children}
  </a>
);

/**
 * @deprecated Please use Button from '@virtahealth/design-components'
 */
const ButtonWithNoDefaultTracking: React.FC<ButtonWithNoTracking> = ({
  href,
  style,
  onClick,
  children,
  additionalStyle,
  id,
  labelText,
}: ButtonWithNoTracking) => (
  <a
    href={href}
    tabIndex={0}
    role="button"
    aria-label={labelText}
    className={cx(style, additionalStyle)}
    onClick={onClick}
    id={id}
  >
    {children}
  </a>
);

export interface ButtonWithUrlProps {
  clickThroughUrl: string;
  name: string;
  onClick?: () => void;
  children: any;
  target?: string;
  style?: string;
  className?: string;
}

/**
 * @deprecated Please use Button from '@virtahealth/design-components'
 */
const ButtonWithUrl: React.FC<ButtonWithUrlProps> = ({
  clickThroughUrl,
  name,
  onClick,
  target,
  style = "PrimaryBtn",
  children,
  className,
}: ButtonWithUrlProps) => (
  <a
    href={clickThroughUrl}
    onClick={() =>
      handleButtonClick(
        { name, style, url: clickThroughUrl, text: children },
        onClick
      )
    }
    rel="noopener noreferrer"
    target={target || "_self"}
    className={[styles[style], className].join(" ").trim()}
  >
    {children}
  </a>
);

interface AjaxButtonProps {
  ajaxAction: (fn: () => void) => void;
  style?: any;
  children?: any;
  name?: string | null;
}

interface AjaxButtonState {
  isPending: boolean;
}

/**
 * @deprecated Please use Button from '@virtahealth/design-components'
 */
class AjaxButton extends React.Component<AjaxButtonProps, AjaxButtonState> {
  public static defaultProps: {
    style: "";
  };

  public state: AjaxButtonState = {
    isPending: false,
  };

  constructor(props: AjaxButtonProps) {
    super(props);
  }

  public render() {
    const { style, children } = this.props;

    return (
      <a
        href=""
        className={cx(style, { isDisabled: this.state.isPending })}
        onClick={(e) => this.generateClickAction(e)}
      >
        {children}
      </a>
    );
  }

  private generateClickAction(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.togglePendingState();
    this.props.ajaxAction(this.togglePendingState);
  }

  private togglePendingState() {
    const currentState = this.state.isPending;
    this.setState({ isPending: !currentState });
  }
}

export {
  Button,
  ButtonWithUrl,
  AjaxButton,
  LinkButton,
  ButtonWithNoDefaultTracking,
};
