/* eslint-disable max-lines */
import { combineReducers } from "redux";

import {
  CLEAR_EXPANDED_POST,
  DEFAULT_EDIT_POST_OR_COMMENT_STATE,
  DELETE_COMMUNITY_POST_FOR_TOPIC,
  FETCHING_COMMUNITY_FEED,
  FETCHING_COMMUNITY_POST,
  FETCHING_COMMUNITY_POST_FAILURE,
  FETCHING_COMMUNITY_POST_SUCCESS,
  FETCHING_COMMUNITY_USER,
  GET_FEED_FOR_TOPIC,
  GET_TOPICS_REQUEST,
  POST_COMMUNITY_POST_MEDIA_REQUEST,
  POST_COMMUNITY_POST_MEDIA_SUCCESS,
  POST_COMMUNITY_PROFILE_PICTURE_FAILURE,
  POST_COMMUNITY_PROFILE_PICTURE_REQUEST,
  POST_COMMUNITY_PROFILE_PICTURE_SUCCESS,
  RECEIVE_COMMUNITY_FEED,
  RECEIVE_COMMUNITY_POST,
  RECEIVE_COMMUNITY_USER,
  RECEIVE_COMMUNITY_USER_ACTIONS,
  RECEIVE_NEWLY_PUBLISHED_POST,
  RECEIVE_NEW_POST_FOR_TOPIC,
  RECEIVE_UPDATED_POST_FOR_TOPIC,
  REMOVE_ALL_COMMUNITY_POST_MEDIA,
  REMOVE_COMMUNITY_POST_MEDIA,
  UPDATE_COMMUNITY_FEED,
  UPDATE_COMMUNITY_POST_MEDIA_REQUEST,
  UPDATE_COMMUNITY_POST_MEDIA_SUCCESS,
  UPDATE_COMMUNITY_PROFILE_PICTURE_FAILURE,
  UPDATE_COMMUNITY_PROFILE_PICTURE_REQUEST,
  UPDATE_COMMUNITY_PROFILE_PICTURE_SUCCESS,
  UPDATE_EDIT_POST_OR_COMMENT,
  UPDATE_EDIT_POST_OR_COMMENT_BODY,
  UPDATE_EDIT_POST_OR_COMMENT_ERROR,
  UPDATE_EDIT_POST_OR_COMMENT_MEDIA,
  UPDATE_EXPANDED_POST_ID,
  UPDATE_EXPANDED_POST_POST,
  UPDATE_NEXT_PAGE,
  UPDATE_SELECTED_TOPIC_CATEGORY_ID,
  UPDATE_TOPIC_ID,
  UPDATING_COMMUNITY_USER,
} from "../constants/ActionTypes";
import { RootState } from "../types";

import {
  DEFAULT_TOPIC_CATEGORY_ID,
  DEFAULT_TOPIC_NAME,
  DEFAULT_TOPIC_POSITION,
} from "./constants";
import toastNotifications, {
  ToastNotificationsReduxState,
} from "./state/toast_notifications/reducer";
import { getUrlWithoutTimestamp } from "./utils";

export interface CommunityUser {
  id: number | null;
  postsAndComments: Array<CommunityPost | CommunityComment>;
  daysOnVirta: string | null;
  displayName: string | null;
  isActive: boolean;
  profilePictureUrl: string | null;
  role: string | null;
  virtaUserId: number | null;
  tosAcceptedOn: boolean | null;
  isFetching: boolean;
}

export interface CommunityPost {
  id: number;
  postId: number;
  body: string;
  media: string[];
  categoryId: number;
  commentCount: number;
  comments: CommunityComment[];
  createdAt: string;
  likeCount: number;
  liked: boolean;
  author: CommunityUser;
}

export interface CommunityComment {
  postId: number;
  id: number;
  author: CommunityUser;
  body: string;
  media: string[];
  liked: boolean;
  likeCount: number;
  createdAt: string;
}

const defaultCommunityUserState: CommunityUser = {
  id: null,
  postsAndComments: [],
  daysOnVirta: null,
  displayName: null,
  isActive: false,
  profilePictureUrl: null,
  role: null,
  virtaUserId: null,
  tosAcceptedOn: null,
  isFetching: true,
};

export interface CommunityTopic {
  categoryId: number;
  name: string;
  isSmallGroup: boolean;
  position: number;
}

export interface CommunityTopics {
  [categoryId: number]: CommunityTopic;
}

export interface CommunityFeed {
  [categoryId: number]: {
    posts: CommunityPost[];
    nextPage: number;
  };
}
export interface ExpandedPost {
  id: number | null;
  post: CommunityPost | null;
}

export type CommunityMediaState = string[];

const defaultPostsState: CommunityPost[] = [];
const defaultMediaState: CommunityMediaState = [];
export const defaultExpandedPost: ExpandedPost = {
  id: null,
  post: null,
};
const defaultTopic: CommunityTopic = {
  categoryId: DEFAULT_TOPIC_CATEGORY_ID,
  name: DEFAULT_TOPIC_NAME,
  isSmallGroup: false,
  position: DEFAULT_TOPIC_POSITION,
};
// Redux doesn't work well when this object is in the defaultTopics array
const defaultTopics: CommunityTopics = {
  [DEFAULT_TOPIC_CATEGORY_ID]: defaultTopic,
};
const defaultFeeds: CommunityFeed = {};
const defaultSelectedTopicCategoryId: number = DEFAULT_TOPIC_CATEGORY_ID;

export interface EditPostOrCommentState {
  body: string;
  media: string[];
  topicId?: number;
  postId?: number;
  isComment?: boolean;
  id?: number;
  topicCategoryId?: number | null;
  error?: string;
  isSmallGroupPost: boolean;
}

const defaultEditPostOrCommentState: EditPostOrCommentState = {
  body: "",
  media: [],
  topicCategoryId: null,
  error: "",
  isSmallGroupPost: false,
};

export const communityUser = (
  state = defaultCommunityUserState,
  action: any
) => {
  switch (action.type) {
    case FETCHING_COMMUNITY_USER:
      return { ...state, isFetching: true };
    case UPDATING_COMMUNITY_USER:
      return { ...state, ...action.userUpdates, isFetching: true };
    case RECEIVE_COMMUNITY_USER:
      return { ...state, ...action.communityUser, isFetching: false };
    case UPDATE_COMMUNITY_PROFILE_PICTURE_SUCCESS:
    case POST_COMMUNITY_PROFILE_PICTURE_SUCCESS:
      return { ...state, profilePictureUrl: action.profilePictureUrl };
    case RECEIVE_COMMUNITY_USER_ACTIONS:
      return { ...state, postsAndComments: action.postsAndComments };
    default:
      return state;
  }
};

export const editPostOrComment = (
  state = defaultEditPostOrCommentState,
  action: any
): EditPostOrCommentState => {
  switch (action.type) {
    case UPDATE_COMMUNITY_POST_MEDIA_SUCCESS:
      const oldUrlIndex = state.media.findIndex(
        (url) => getUrlWithoutTimestamp(url) === action.oldUrl
      );
      return {
        ...state,
        media:
          oldUrlIndex === -1
            ? [...state.media, action.url]
            : [
                ...state.media.slice(0, oldUrlIndex),
                action.url,
                ...state.media.slice(oldUrlIndex + 1),
              ],
      };
    case UPDATE_EDIT_POST_OR_COMMENT_ERROR:
      return { ...state, error: action.error };
    case UPDATE_SELECTED_TOPIC_CATEGORY_ID:
      return {
        ...state,
        topicCategoryId: action.topicCategoryId,
        isSmallGroupPost: action.isSmallGroupPost,
      };
    case UPDATE_EDIT_POST_OR_COMMENT_BODY:
      return { ...state, body: action.body };
    case UPDATE_EDIT_POST_OR_COMMENT_MEDIA:
      return { ...state, media: action.media };
    case UPDATE_EDIT_POST_OR_COMMENT:
      return action.data;
    case DEFAULT_EDIT_POST_OR_COMMENT_STATE:
      return defaultEditPostOrCommentState;
    default:
      return state;
  }
};

export const currentNewPostMedia = (state = defaultMediaState, action: any) => {
  switch (action.type) {
    case UPDATE_COMMUNITY_POST_MEDIA_SUCCESS:
      const oldUrlIndex = state.findIndex(
        (url) => getUrlWithoutTimestamp(url) === action.oldUrl
      );
      return oldUrlIndex === -1
        ? [...state, action.url]
        : [
            ...state.slice(0, oldUrlIndex),
            action.url,
            ...state.slice(oldUrlIndex + 1),
          ];
    case POST_COMMUNITY_POST_MEDIA_SUCCESS:
      return [...state, action.url];
    case REMOVE_COMMUNITY_POST_MEDIA:
      return action.media;
    case REMOVE_ALL_COMMUNITY_POST_MEDIA:
      return defaultMediaState;
    default:
      return state;
  }
};

export const nextPage = (state = null, action: any): number | null => {
  switch (action.type) {
    case UPDATE_NEXT_PAGE:
      return action.nextPage;
    default:
      return state;
  }
};

export const isFetchingFeed = (state = false, action: any) => {
  switch (action.type) {
    case FETCHING_COMMUNITY_FEED:
      return true;
    case RECEIVE_COMMUNITY_FEED:
      return false;
    default:
      return state;
  }
};

export const isFetchingPost = (state = false, action: any) => {
  switch (action.type) {
    case FETCHING_COMMUNITY_POST:
      return true;
    case FETCHING_COMMUNITY_POST_SUCCESS:
      return false;
    case FETCHING_COMMUNITY_POST_FAILURE:
      return false;
    default:
      return state;
  }
};

export const isUploadingMedia = (state = false, action: any) => {
  switch (action.type) {
    case POST_COMMUNITY_PROFILE_PICTURE_REQUEST:
    case POST_COMMUNITY_POST_MEDIA_REQUEST:
    case UPDATE_COMMUNITY_POST_MEDIA_REQUEST:
    case UPDATE_COMMUNITY_PROFILE_PICTURE_REQUEST:
      return true;
    case UPDATE_COMMUNITY_POST_MEDIA_SUCCESS:
    case UPDATE_EDIT_POST_OR_COMMENT_MEDIA:
    case UPDATE_COMMUNITY_PROFILE_PICTURE_SUCCESS:
    case UPDATE_COMMUNITY_PROFILE_PICTURE_FAILURE:
    case POST_COMMUNITY_POST_MEDIA_SUCCESS:
    case POST_COMMUNITY_PROFILE_PICTURE_SUCCESS:
    case POST_COMMUNITY_PROFILE_PICTURE_FAILURE:
      return false;
    default:
      return state;
  }
};

export const posts = (state = defaultPostsState, action: any) => {
  switch (action.type) {
    case UPDATE_COMMUNITY_FEED:
    case RECEIVE_COMMUNITY_POST: {
      return action.posts;
    }
    case RECEIVE_NEWLY_PUBLISHED_POST: {
      return [action.newlyPublishedPost, ...state];
    }
    default:
      return state;
  }
};

export const expandedPost = (state = defaultExpandedPost, action: any) => {
  switch (action.type) {
    case UPDATE_EXPANDED_POST_POST:
      return { ...state, post: { ...action.data } };
    case UPDATE_EXPANDED_POST_ID:
      return { ...state, id: action.data };
    case CLEAR_EXPANDED_POST:
      return defaultExpandedPost;
    default:
      return state;
  }
};

export const topics = (state = {}, action: any) => {
  switch (action.type) {
    case GET_TOPICS_REQUEST:
      const topicsByCategoryId: CommunityTopics = {};
      action.topics.forEach((topic: CommunityTopic) => {
        topicsByCategoryId[topic.categoryId] = { ...topic };
      });
      return { ...defaultTopics, ...topicsByCategoryId };
    default:
      return state;
  }
};

export const feeds = (state = defaultFeeds, action: any) => {
  const post: CommunityPost = action.data;
  const fallbackFeeds = { posts: [] };
  const getAllPosts = (): CommunityPost[] => (state[0] || fallbackFeeds).posts;
  const getTopicPosts = (post: any): CommunityPost[] =>
    (state[post.categoryId || 0] || fallbackFeeds).posts;

  switch (action.type) {
    case GET_FEED_FOR_TOPIC:
      return { ...state, ...action.data };
    case DELETE_COMMUNITY_POST_FOR_TOPIC:
      return {
        ...state,
        [DEFAULT_TOPIC_CATEGORY_ID]: {
          ...state[DEFAULT_TOPIC_CATEGORY_ID],
          posts: getAllPosts().filter((p) => p.id !== post.id),
        },
        [post.categoryId]: {
          ...state[post.categoryId],
          posts: getTopicPosts(post).filter((p) => p.id !== post.id),
        },
      };
    case RECEIVE_NEW_POST_FOR_TOPIC:
      // @ts-ignore need to fix this type
      if (post.isSmallGroupPost) {
        return {
          ...state,
          [post.categoryId]: {
            ...state[post.categoryId],
            posts: [post, ...getTopicPosts(post)],
          },
        };
      }
      return {
        ...state,
        [DEFAULT_TOPIC_CATEGORY_ID]: {
          ...state[DEFAULT_TOPIC_CATEGORY_ID],
          posts: [post, ...getAllPosts()],
        },
        [post.categoryId]: {
          ...state[post.categoryId],
          posts: [post, ...getTopicPosts(post)],
        },
      };
    case RECEIVE_UPDATED_POST_FOR_TOPIC: {
      const withUpdatedPost = (posts: any[]) =>
        (posts || []).map((p) => {
          if (p.id === post.id) {
            return { ...p, ...post };
          }
          return p;
        });
      return {
        ...state,
        [DEFAULT_TOPIC_CATEGORY_ID]: {
          ...state[DEFAULT_TOPIC_CATEGORY_ID],
          posts: withUpdatedPost(
            (state[DEFAULT_TOPIC_CATEGORY_ID] || {}).posts
          ),
        },
        [post.categoryId]: {
          ...state[post.categoryId],
          posts: withUpdatedPost((state[post.categoryId] || {}).posts),
        },
      };
    }
    default:
      return state;
  }
};

export const selectedTopicCategoryId = (
  state = defaultSelectedTopicCategoryId,
  action: any
): number => {
  switch (action.type) {
    case UPDATE_TOPIC_ID:
      return action.data;
    default:
      return state;
  }
};

export interface State {
  communityUser: CommunityUser;
  isFetchingFeed: boolean;
  isFetchingPost: boolean;
  isUploadingMedia: boolean;
  posts: CommunityPost[];
  expandedPost: ExpandedPost;
  currentNewPostMedia: CommunityMediaState;
  nextPage: number | null;
  editPostOrComment: EditPostOrCommentState;
  topics: CommunityTopics;
  feeds: CommunityFeed;
  selectedTopicCategoryId: number;
  toastNotifications: ToastNotificationsReduxState;
}

export const community: (state: State, action: any) => State =
  combineReducers<State>({
    communityUser,
    isFetchingFeed,
    isUploadingMedia,
    posts,
    isFetchingPost,
    expandedPost,
    currentNewPostMedia,
    nextPage,
    editPostOrComment,
    topics,
    feeds,
    selectedTopicCategoryId,
    toastNotifications,
  });

export default community;

// selectors
export const getCommunity = (state: RootState) => state.community;
export const getCommunityPosts = (state: { community: State }) => {
  const communityState = state.community;
  if (communityState.posts && communityState.posts.length > 0) {
    // previous path
    return communityState.posts;
  } else if (
    communityState.feeds &&
    communityState.selectedTopicCategoryId !== null
  ) {
    // feature flag path path. FIXME: clean up
    return (
      (communityState.feeds[communityState.selectedTopicCategoryId] || {})
        .posts || []
    );
  } else {
    return [];
  }
};
export const getCommunityTopicsState = (state: RootState) =>
  state.community.topics;
export const getCommunityEditPostOrComment = (state: RootState) =>
  state.community.editPostOrComment;
export const getCommunityEditPostOrCommentMedia = (state: RootState) =>
  state.community.editPostOrComment.media;
export const getCommunityPostIsFetching = (state: RootState) =>
  state.community.isFetchingPost;
export const getCommunitySelectedTopicCategoryIdState = (state: RootState) =>
  state.community.selectedTopicCategoryId;
export const getCommunityExpandedPost = (state: RootState) =>
  state.community.expandedPost;
/* eslint-enable max-lines */
