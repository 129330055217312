import React from "react";

import { AjaxButton, Button } from "../../../common-components/Button";
import PAVideoModal from "../../../common-components/PAVideoModal";
import { logTaskClickEvent } from "../../../lib/analytics";
import store from "../../../store";
import { completeTask } from "../../actions.js";
import Card from "../Card";

interface Props {
  testId?: string;
  task: {
    id: number;
    title: string;
    header: string;
    video_poster_url: string;
    video_source_url: string;
    primary_cta_label: string;
    secondary_cta_label: string;
    type: string;
  };
}

interface State {
  modalIsOpen: boolean;
}

class VideoTaskCard extends React.Component<Props, State> {
  public state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  public toggleVideoModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  public render() {
    const {
      id,
      title,
      header,
      video_poster_url = "/static/imgs/default-video-poster.jpg",
      video_source_url,
      primary_cta_label,
      secondary_cta_label,
      type,
    } = this.props.task;

    const dispatchCompleteTask = (errorHandler?: any) =>
      store.dispatch(completeTask(id, errorHandler));

    const primaryButton = (
      <Button
        name={title}
        style="PrimaryBtnLight"
        onClick={() => {
          this.toggleVideoModal();
          logTaskClickEvent(
            {
              taskName: title,
              taskText: primary_cta_label,
              taskType: type,
            },
            {
              buttonCategory: "CTA",
              ctaUrl: video_source_url,
              phaseTaskCardType: type,
              phaseContentSequenceId: id,
            }
          );
        }}
      >
        {primary_cta_label}
      </Button>
    );

    const secondaryButton = (
      <AjaxButton
        name={title}
        ajaxAction={(togglePendingState) => {
          dispatchCompleteTask(togglePendingState);
          logTaskClickEvent(
            {
              taskName: title,
              taskText: secondary_cta_label,
              taskType: type,
            },
            {
              buttonCategory: "progression",
              ctaUrl: null,
              phaseTaskCardType: type,
              phaseContentSequenceId: id,
            }
          );
        }}
        style="SecondaryBtnLight"
      >
        {secondary_cta_label}
      </AjaxButton>
    );

    return (
      <div data-testid={this.props.testId}>
        <Card
          type="Video"
          name={title}
          header={header}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          backgroundImageSrc={video_poster_url}
        />
        <PAVideoModal
          closeFn={this.toggleVideoModal}
          isOpen={this.state.modalIsOpen}
          contentLabel={`Video Task: ${title}`}
          posterUrl={video_poster_url}
          videoUrl={video_source_url}
          videoTitle={title}
          id={id}
        />
      </div>
    );
  }
}

export default VideoTaskCard;
