import React from "react";

import { ButtonWithUrl } from "../../../common-components/Button";
import Icon from "../../../common-components/Icon/components";
import { logTaskClickEvent } from "../../../lib/analytics";
import { TaskCardTask } from "../../types";

import styles from "./styles.css";

interface Props {
  testId?: string;
  task: TaskCardTask;
  type: string;
}

const HEALTH_ASSESSMENT_CARD_CTA = "Enter";
function HealthAssessmentCard({ task, testId, type }: Props) {
  return (
    <div className={styles.biomarkerCard} data-testid={testId}>
      <div className={styles.iconCol}>
        <Icon name={`${type}Color`} />
        <div className={styles.title}>{task.title}</div>
      </div>
      <div className={styles.addBiomarkerButton}>
        <ButtonWithUrl
          name={task.title}
          clickThroughUrl={task?.primary_cta_url ?? ""}
          className={styles.enterButton}
          onClick={() => {
            logTaskClickEvent({
              taskName: task.title,
              taskText: HEALTH_ASSESSMENT_CARD_CTA,
              taskType: task.type,
            });
          }}
        >
          {HEALTH_ASSESSMENT_CARD_CTA}
        </ButtonWithUrl>
      </div>
    </div>
  );
}

export default HealthAssessmentCard;
