import * as charanFromLib from "@virtahealth/charan";

import { asyncConfigureCSRF } from "./utils/csrf_utils";

export const get = (url, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.get(url, updatedHeaders)
  );

export const getCached = (url, cacheName, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.getCached(url, cacheName, updatedHeaders)
  );

export const post = (url, data = {}, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.post(url, data, updatedHeaders)
  );

export const postForm = (url, data = {}, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.postForm(url, data, updatedHeaders)
  );

export const patch = (url, data = {}, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.patch(url, data, updatedHeaders)
  );

export const deleteRequest = (url, headers = {}) =>
  asyncConfigureCSRF(headers).then((updatedHeaders) =>
    charanFromLib.delete(url, updatedHeaders)
  );

const charan = { get, getCached, post, patch, delete: deleteRequest, postForm };

export default charan;
