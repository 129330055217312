import { RECEIVE_WEEKLY_SUMMARY } from "../constants/ActionTypes";

export const weeklySummary = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_WEEKLY_SUMMARY:
      return action.weeklySummary;
    default:
      return state;
  }
};
