import React from "react";
import { connect } from "react-redux";

import { Button } from "../../../common-components/Button";
import Icon from "../../../common-components/Icon/components";
import { logVideoEvent } from "../../../lib/analytics";
import { recordVideoCompletion } from "../../actions";
import { home } from "../../urls";

import styles from "./styles.css";

const FINISH_TASKS_TO_UNLOCK_BANNER =
  "You have one or more tasks to complete in order to unlock the next video. ";
const START_VIDEO_EVENT_NAME = "started";
const PAUSE_VIDEO_EVENT_NAME = "paused";
const SEEK_VIDEO_EVENT_NAME = "seeked";
const END_VIDEO_EVENT_NAME = "ended";
// const CHECK_BACK_LATER_BANNER =
//   'We appreciate your eagerness to learn! This next video will unlock soon. In the meantime, please consider rewatching past videos.'

export const VideoPlayer: React.FC<any> = ({
  id,
  title,
  videoUrl,
  posterUrl,
  isLocked,
}: any) => {
  const videoPlayer = isLocked ? (
    <LockedVideoPlayer posterUrl={posterUrl} />
  ) : (
    <UnlockedVideoPlayer
      id={id}
      videoUrl={videoUrl}
      videoTitle={title}
      posterUrl={posterUrl}
    />
  );

  return (
    <div>
      <h3 className={styles.videoTitle}>{title}</h3>
      {videoPlayer}
    </div>
  );
};

export class UnlockedVideoPlayer extends React.Component<any> {
  public video: any = null;

  constructor(props: any) {
    super(props);
    this.trackVideo = this.trackVideo.bind(this);
  }

  public render() {
    const { id, videoUrl, posterUrl, autoPlay = false } = this.props;
    const videoId = `video${id}`;
    let isPlaying = autoPlay;

    const togglePlay = (video: any) => {
      if (!isPlaying) {
        video.play();
      } else {
        video.pause();
      }
      isPlaying = !isPlaying;
    };

    return (
      <video
        ref={(elem) => {
          const { setRef } = this.props;
          setRef && setRef(elem);
          this.video = elem;
        }}
        className={styles.videoPlayer}
        onClick={(ref) => togglePlay(ref.target)}
        id={videoId}
        src={videoUrl}
        poster={posterUrl}
        data-video-id={id}
        data-name="video_player"
        controls={true}
        autoPlay={autoPlay}
        onPlay={() => {
          this.trackVideo(START_VIDEO_EVENT_NAME);
        }}
        onPause={() => {
          this.trackVideo(PAUSE_VIDEO_EVENT_NAME);
        }}
        onEnded={() => {
          this.trackVideo(END_VIDEO_EVENT_NAME);
        }}
        onSeeked={() => {
          this.trackVideo(SEEK_VIDEO_EVENT_NAME);
        }}
      />
    );
  }

  private trackVideo(action: string) {
    logVideoEvent({
      action,
      videoId: parseInt(this.props.id, 10),
      videoTitle: this.props.videoTitle,
      videoDuration: this.video.duration,
      videoEventTime: this.video.currentTime,
      currentUrl: window.location.href,
    });
  }
}

const LockedVideoPlayer: React.FC<any> = ({ posterUrl }: any) => (
  <div className={styles.disabledVideo}>
    <div className={styles.videoIcon}>
      <Icon name="Padlock" style="light" />
    </div>
    <img src={posterUrl} alt="image" />
  </div>
);

const LockedBannerMessage: React.FC<any> = () => {
  const bannerTxt = FINISH_TASKS_TO_UNLOCK_BANNER;

  return (
    <a href={home} target="_self" className={styles.bannerTaskRemaining}>
      {bannerTxt}
      <span className={styles.underline}>See My Tasks</span>
    </a>
  );
};

interface StateProps {
  id: number;
  title: string;
  posterUrl: string;
  videoUrl: string;
  isLocked: string;
  showTaskCta: boolean;
  blockedByTasks: boolean;
}

interface DispatchProps {
  onCompletingVideo: (id: number) => void;
}

type Props = StateProps & DispatchProps;

export const HeroContainer: React.FC<Props> = ({
  id,
  title,
  posterUrl,
  videoUrl,
  isLocked,
  showTaskCta,
  blockedByTasks,
  onCompletingVideo,
}: Props) => (
  <div>
    {isLocked && <LockedBannerMessage blockedByTasks={blockedByTasks} />}
    <div className={styles.container}>
      <div className="video_module">
        <div className={styles.headerText}>Watch Now</div>
        <VideoPlayer
          id={id}
          title={title}
          posterUrl={posterUrl}
          videoUrl={videoUrl}
          isLocked={isLocked}
        />
        {showTaskCta && !isLocked && (
          <Button
            id="video_mod_btn"
            onClick={() => onCompletingVideo(id)}
            style="PrimaryBtn"
          >
            Ok, I watched this
          </Button>
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ latestAvailableVideo, blockedByTasks }: any) => ({
  id: latestAvailableVideo.id,
  title: latestAvailableVideo.title,
  posterUrl: latestAvailableVideo.posterUrl,
  videoUrl: latestAvailableVideo.videoUrl,
  isLocked: latestAvailableVideo.isLocked,
  showTaskCta: latestAvailableVideo.showTaskCta,
  blockedByTasks,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCompletingVideo: (id: number) => dispatch(recordVideoCompletion(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeroContainer);
