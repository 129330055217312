import { UPDATE_BIOMARKER_TASKS, UPDATE_TASKS } from "../constants/ActionTypes";
import { fetchGoalProgress } from "../goal_progress/actions";
import { fetchMiniCards } from "../history/actions";
import { historyUrl } from "../history/urls";
import api from "../task_cards/api";

export const completeTask = (taskId, errorHandler) => (dispatch) =>
  // Once a task is completed we want to refresh the mini cards.
  api.recordTaskCompleted(taskId, errorHandler).then((tasks) => {
    dispatch(fetchMiniCards(historyUrl));
    dispatch(fetchGoalProgress());
    return dispatch({ type: UPDATE_TASKS, tasks });
  });

export const initTasks = (tasks) => ({ type: UPDATE_TASKS, tasks });

export const getBiomarkerTasks = () => (dispatch) =>
  api
    .fetchTasks()
    .then((res) =>
      dispatch({ type: UPDATE_BIOMARKER_TASKS, biomarkerTasks: res.tasks })
    );
