import { UPDATE_BIOMARKER_TASKS, UPDATE_TASKS } from "../constants/ActionTypes";

export const tasks = (state = [], action) => {
  switch (action.type) {
    case UPDATE_TASKS:
      return action.tasks;
    default:
      return state;
  }
};

export const biomarkerTasks = (state = [], action) => {
  switch (action.type) {
    case UPDATE_BIOMARKER_TASKS:
      return action.biomarkerTasks;
    default:
      return state;
  }
};
