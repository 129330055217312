import * as Sentry from "@sentry/browser";
import { Dispatch } from "redux";

import {
  RECEIVE_ALL_VIDEOS,
  RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS,
  RECEIVE_LATEST_AVAILABLE_VIDEO,
} from "../constants/ActionTypes";

import api from "./api";
import { LatestAvailableVideoInfo } from "./types";

export const fetchAllVideos = () => (dispatch: Dispatch) =>
  api
    .retrieveAllVideos()
    .then((allVideos: object) => {
      dispatch({ type: RECEIVE_ALL_VIDEOS, allVideos });
    })
    .catch((error: Error) => {
      Sentry.captureException(error);
    });

export const fetchLatestAvailaleVideo = () => (dispatch: Dispatch) =>
  api
    .retrieveLatestVideo()
    .then((latestAvailableVideoInfo: LatestAvailableVideoInfo) => {
      dispatch({
        type: RECEIVE_LATEST_AVAILABLE_VIDEO,
        latestAvailableVideo: latestAvailableVideoInfo.video,
      });
      dispatch({
        type: RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS,
        blockedByTasks: latestAvailableVideoInfo.blockedByTasks,
      });
    })
    .catch((error: Error) => {
      Sentry.captureException(error);
    });

export const recordVideoCompletion = (id: number) => (dispatch: Dispatch) =>
  api
    .postVideoCompletedAction(id)
    .then((latestAvailableVideoInfo: LatestAvailableVideoInfo) => {
      dispatch({
        type: RECEIVE_LATEST_AVAILABLE_VIDEO,
        latestAvailableVideo: latestAvailableVideoInfo.video,
      });
      dispatch({
        type: RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS,
        blockedByTasks: latestAvailableVideoInfo.blockedByTasks,
      });
    })
    .catch((error: Error) => {
      Sentry.captureException(error);
    });

export const playVideoInHeroPlayer =
  (
    id: number,
    title: string,
    posterUrl: string,
    videoUrl: string,
    isLocked: boolean,
    showTaskCta: boolean
  ) =>
  (dispatch: Dispatch) => {
    const videoDetails = {
      id,
      title,
      posterUrl,
      videoUrl,
      isLocked,
      showTaskCta,
    };
    dispatch({
      type: RECEIVE_LATEST_AVAILABLE_VIDEO,
      latestAvailableVideo: videoDetails,
    });
    window.scrollTo(0, 0);
  };
