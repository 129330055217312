/*
 * Helper for plan definition stuff
 */
import { PlanDefinitionName } from "../types/planDefinition";

export const getCurrentPlanDefinitionName = (): PlanDefinitionName => {
  return window.virta.user.current_plan_definition as PlanDefinitionName;
};

export const isOnPlan = (planDefinitionName?: PlanDefinitionName): boolean =>
  !!planDefinitionName;

export const isOnReversal = (
  planDefinitionName?: PlanDefinitionName
): boolean =>
  !planDefinitionName || planDefinitionName === PlanDefinitionName.Reversal;

export const isOnDiabetesManagement = (
  planDefinitionName: PlanDefinitionName
): boolean =>
  !planDefinitionName ||
  planDefinitionName === PlanDefinitionName.DiabetesManagement;

export const isOnDiabetesManagementNonClinical = (
  planDefinitionName: PlanDefinitionName
): boolean =>
  !planDefinitionName ||
  planDefinitionName === PlanDefinitionName.DiabetesManagementNonClinical;

export const planDefinitionNameToLabel = (
  planDefinitionName?: PlanDefinitionName
): string => {
  switch (planDefinitionName) {
    case PlanDefinitionName.DiabetesManagement:
      return "Diabetes Management Clinical";
    case PlanDefinitionName.DiabetesManagementNonClinical:
      return "Diabetes Management";
    case PlanDefinitionName.Reversal:
      return "Reversal";
    default:
      return "No Plan";
  }
};
