import React from "react";
import ReactDOM from "react-dom";

import Icon from "../../../common-components/Icon/components";

import styles from "./styles.css";

const ReactCSSTransitionGroup = require("react-addons-css-transition-group");

class CardScrollView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCard: 1,
      scrollPosition: 0,
    };

    this.scroll = this.scroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", (event) => {
      switch (event.code) {
        case "ArrowLeft":
          event.preventDefault();
          this.scroll("left");
          return false;
        case "ArrowRight":
          event.preventDefault();
          this.scroll("right");
          return false;
        default:
          return false;
      }
    });
  }

  scroll(direction) {
    const cards = ReactDOM.findDOMNode(cardContainer).childNodes; // eslint-disable-line
    const firstCard = cards[0];
    const cardWidth = firstCard.offsetWidth;
    const cardMargin = parseInt(
      window
        .getComputedStyle(firstCard, null)
        .getPropertyValue("margin-right")
        .replace(/[^-\d.]/g, ""),
      10
    );
    const scrollDistance = cardWidth + cardMargin;

    if (this.state.currentCard > 1 && direction === "left") {
      this.setState((previousState) => ({
        currentCard: previousState.currentCard - 1,
        scrollPosition: previousState.scrollPosition + scrollDistance,
      }));
    } else if (
      this.state.currentCard < this.props.children.length &&
      direction === "right"
    ) {
      this.setState((previousState) => ({
        currentCard: previousState.currentCard + 1,
        scrollPosition: previousState.scrollPosition - scrollDistance,
      }));
    }
  }

  render() {
    const cardContainerStyle = {
      transform: `translateX(${this.state.scrollPosition}px)`,
      opacity: 1,
    };

    const leftScrollIndicator = (
      <div
        role="button"
        tabIndex="0"
        className={styles.scrollLeftIndicator}
        onClick={() => this.scroll("left")}
      >
        <Icon name="DisclosureArrowLeft" style="Color" />
      </div>
    );
    const rightScrollIndicator = (
      <div
        role="button"
        tabIndex="0"
        className={styles.scrollRightIndicator}
        onClick={() => this.scroll("right")}
      >
        <Icon name="DisclosureArrowRight" style="Color" />
      </div>
    );
    return (
      <div className={styles.CardScrollView} data-testid="card-scroll-view">
        {this.props.children.length > 1 && leftScrollIndicator}
        {this.props.children.length > 1 && rightScrollIndicator}

        <ReactCSSTransitionGroup
          component="div"
          className={styles.cardContainer}
          style={cardContainerStyle}
          transitionEnterTimeout={1600}
          transitionLeaveTimeout={800}
          transitionName="AnimatedCard"
        >
          {this.props.children}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

export default CardScrollView;
