import { Event } from "@sentry/browser";
import { flow } from "lodash";

/**
 * Filter functions in this file should be built so that they
 * may be composed together in a beforeSend
 */

export function beforeSend(event: Event): Event | null {
  // compose filter functions from "left" to "right"
  return flow(filterAxeCoreErrors)(event);
}

/**
 * Matches either of the following multiline error strings:
 * Fix any of the following
 * Fix all of the following
 *
 * https://regex101.com/r/woqe8E/1
 */
const AXE_CORE_ERROR = /^Fix (any|all) of the following/m;
export function filterAxeCoreErrors(event: Event | null): Event | null {
  if (!event) {
    return null;
  }

  return AXE_CORE_ERROR.test(event.message ?? "") ? null : event;
}
