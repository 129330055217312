import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { Button } from "../../../common-components/Button";
import Icon from "../../../common-components/Icon/components";
import PAModal, {
  PAModalBody,
  PAModalFooter,
  PAModalHeader,
} from "../../../common-components/PAModal/";
import TableView from "../../../common-components/TableView/";
import Cell from "../../../common-components/TableView/components/Cell";
import CellTitle from "../../../common-components/TableView/components/CellTitle";
import Wrapper from "../../../common-components/Wrapper";
import {
  getCurrentPlanDefinitionName,
  isOnReversal,
} from "../../../helpers/planDefinition";
import {
  ClickableElement,
  getCommonPropertiesFromWindow,
  logClickEvent,
} from "../../../lib/analytics";
import { togglePhaseModal } from "../../actions";
import { GOAL_COMPLETION_STATE_DISPLAY_LABELS } from "../../constants";
import { GoalProgress as GoalProgressType } from "../../types";
import ProgressBar from "../ProgressBar";

import styles from "./styles.css";

interface Props {
  title: string;
  objective: string;
  completed: number;
  completionState: string;
  startDate: string;
  endDate: string;
  toggleModal: () => void;
  modalState: boolean;
  summary: string;
  phaseDescription: string;
  hideDuration: boolean;
  goalCompletedMessage?: string | null;
}

const onClickOpenModal = (toggleFunction: () => void) => {
  toggleFunction();
  logClickEvent(
    ClickableElement.GoalSummaryHelp,
    getCommonPropertiesFromWindow()
  );
};

export const GoalProgress: React.FC<Props> = ({
  title,
  objective,
  completed,
  completionState,
  startDate,
  endDate,
  toggleModal,
  modalState,
  summary,
  phaseDescription,
  goalCompletedMessage,
  hideDuration,
}) => {
  const planDefinition = getCurrentPlanDefinitionName();
  if (
    completed === undefined ||
    completed === null ||
    !isOnReversal(planDefinition)
  ) {
    return null;
  }

  const GoalTitle = () => (
    <div className={styles.title}>
      <div>{title}</div>
      <GoalDetailsButton />
    </div>
  );

  const GoalObjective = () => (
    <div className={styles.objective}>{objective}</div>
  );

  const getDaysLeftLabel = (date: string) => {
    const end = moment(date, "YYYY-MM-DD").subtract(1, "days").format("MMM Do");
    const tomorrow = moment(new Date()).add(1, "days");

    const daysToEnd = Math.ceil(
      moment(date, "YYYY-MM-DD").diff(tomorrow, "hours") / 24
    );

    if (daysToEnd === 0) {
      return "Complete by today, 11:59pm";
    } else if (daysToEnd > 0) {
      return `Complete by ${end}, 11:59pm`;
    } else if (daysToEnd < 0) {
      return "";
    }
  };

  const DaysLeft = () => (
    <div className={styles.daysLeft}>{getDaysLeftLabel(endDate)}</div>
  );

  const CompletedIcon = () =>
    completionState === "passed" ? (
      <Icon name="CheckBadgeColor" />
    ) : (
      <Icon name="LogoMarkColor" />
    );

  const GoalDetailsButton = () => (
    <div role="button" tabIndex={0} className={styles.helpIcon}>
      <Icon name="HelpColor" />
    </div>
  );

  const GoalCompletedOverlay = () => (
    <div className={styles.overlay}>
      <Wrapper>
        <div className={styles.overlayContainer}>
          <div className={styles.overlayLeftCol}>
            <CompletedIcon />
          </div>
          <div className={styles.overlayRightCol}>
            <div className={styles.goalTitle}>{title}</div>
            <div className={styles.goalCompletedMessage}>
              {goalCompletedMessage ||
                GOAL_COMPLETION_STATE_DISPLAY_LABELS[completionState]}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );

  const disableScroll = () => {
    const overlay = document.querySelector(".ReactModal__Overlay");
    if (overlay != null) {
      overlay.addEventListener("touchmove", (e: Event) => {
        e.stopPropagation();
      });
    }
  };

  const formatDate = (date: string, negativeOffset?: number) => {
    if (negativeOffset && negativeOffset > 0) {
      return moment(date, "YYYY-MM-DD")
        .subtract(negativeOffset, "days")
        .calendar()
        .split(" at")[0];
    }

    return moment(date, "YYYY-MM-DD").calendar().split(" at")[0];
  };

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = `${formatDate(endDate, 1)}, 11:59pm`;

  const GoalDetailsModal = () => (
    <PAModal
      isOpen={modalState}
      contentLabel="Goal Details"
      onAfterOpen={disableScroll}
      requestCloseFn={toggleModal}
    >
      <PAModalHeader>Goal Details</PAModalHeader>
      <PAModalBody>
        <TableView>
          <Cell style={styles.modalCell}>
            <CellTitle>Title</CellTitle>
            {title}
          </Cell>
          {!hideDuration
            ? [
                // TODO:SUB-6 Change to React fragment when v16 is supported
                <Cell key="started" style={styles.modalCell}>
                  <CellTitle>Started</CellTitle>
                  {formattedStartDate}
                </Cell>,
                <Cell key="ends" style={styles.modalCell}>
                  <CellTitle>Ends</CellTitle>
                  {formattedEndDate}
                </Cell>,
              ]
            : null}
          <Cell style={styles.modalCell}>
            <CellTitle>Purpose</CellTitle>
            {summary}
          </Cell>
          <Cell style={styles.modalCell}>
            <CellTitle>What You&apos;ll Do</CellTitle>
            {phaseDescription}
          </Cell>
        </TableView>
      </PAModalBody>
      <PAModalFooter>
        <Button style="PrimaryBtn" onClick={toggleModal}>
          Continue
        </Button>
      </PAModalFooter>
    </PAModal>
  );

  const GoalProgressContent = () => (
    <div
      className={styles.header}
      onClick={() => onClickOpenModal(toggleModal)}
    >
      {completionState && <GoalCompletedOverlay />}
      <div className={styles.bg} />
      <div className={styles.container}>
        <Wrapper>
          <GoalTitle />
          <GoalObjective />
          {!hideDuration ? <DaysLeft /> : null}
          <ProgressBar currentProgress={completed} />
        </Wrapper>
      </div>
    </div>
  );

  return (
    <div>
      <GoalDetailsModal />
      <GoalProgressContent />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleModal: () => {
    dispatch(togglePhaseModal());
  },
});

interface StateProps {
  goalProgress: GoalProgressType;
  phaseModalState: boolean;
}

const mapStateToProps = ({ goalProgress, phaseModalState }: StateProps) => {
  const {
    title,
    objective,
    completedPercent,
    completionState,
    summary,
    phaseDescription,
    startDate,
    endDate,
    goalCompletedMessage,
    hideDuration,
  } = goalProgress;

  return {
    title,
    objective,
    completed: completedPercent,
    completionState,
    summary,
    phaseDescription,
    startDate,
    endDate,
    modalState: phaseModalState,
    goalCompletedMessage,
    hideDuration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalProgress);
