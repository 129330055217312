export const todayRoutes = {
  index: {
    pathTemplate: "/",
  },
  actionDetails: {
    pathTemplate: "/actions/:id/",
    getPath: (actionId: string) => `/actions/${actionId}/`,
  },
  requestStarterKit: {
    pathTemplate: "/actions/:patientActionId/request-starter-kit",
    getPath: (actionId: string) => `/actions/${actionId}/request-starter-kit`,
  },
  screeners: {
    pathTemplate: "/actions/:patientActionId/screeners/:screenerId",
    getPath: (actionId: string, fhirQuestionnaireId: string) =>
      `/actions/${actionId}/screeners/${fhirQuestionnaireId}`,
  },
  skills: {
    pathTemplate: "/skills",
  },
  discover: {
    pathTemplate: "/discover",
  },
  contentWithWordpressId: {
    pathTemplate: "/discover/content",
  },
  content: {
    pathTemplate: "/discover/content/:identifier",
    getPath: (identifier: string) => `/discover/content/${identifier}`,
  },
  food: {
    pathTemplate: "/discover/food",
  },
  recipe: {
    pathTemplate: "/discover/recipe",
  },
  mySupplyShipments: {
    pathTemplate: "/my-supply-shipments",
  },
};
