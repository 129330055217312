const _GOAL_COMPLETION_SUCCESS_MSG =
  "Great work hitting this goal! Your next goal will be ready soon.";
const _GOAL_COMPLETION_FAILED_MSG =
  "Looks like you missed this goal. Why not get a fresh start? Your next goal will be ready soon.";

const _GOAL_COMPLETION_FAILED_STATE = "failed";
const _GOAL_COMPLETION_SUCCESS_STATE = "passed";

export const GOAL_COMPLETION_STATE_DISPLAY_LABELS: { [key: string]: string } = {
  [_GOAL_COMPLETION_FAILED_STATE]: _GOAL_COMPLETION_FAILED_MSG,
  [_GOAL_COMPLETION_SUCCESS_STATE]: _GOAL_COMPLETION_SUCCESS_MSG,
};
