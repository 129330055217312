import charan from "../charan_csrf_wrapper";

export const recordTaskCompleted = (taskId, errorHandler) => {
  const taskUrl = `/api/v1/phase_content/${taskId}`;
  return window
    .fetch(taskUrl, {
      credentials: "same-origin",
      method: "POST",
      body: JSON.stringify({
        confirmed: taskId,
        csrf_token: window.virta.csrf_token,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
    .then((response) => response.json())
    .then((data) => data.tasks)
    .catch(() => {
      if (errorHandler) {
        errorHandler();
      } else {
        alert("There was a problem completing this task. Please try again.");
      }
    });
};

export const fetchTasks = () => {
  const tasksEndpoint = "/api/v2/tasks";
  return charan.get(tasksEndpoint);
};

const api = { recordTaskCompleted, fetchTasks };
export default api;
