import { map } from "lodash";

import charan from "../charan_csrf_wrapper";

import {
  AllVideos,
  LatestAvailableVideo,
  LatestAvailableVideoInfo,
  Video,
  VideoResponse,
} from "./types";
import {
  allVideosUrl,
  latestAvailableVideo,
  videoCompletedAction,
} from "./urls";

interface AllVideosResponse {
  all_videos: AllVideos;
}

const mappedVideo = (video: Video): VideoResponse => ({
  id: video.phase_content_sequence_id,
  title: video.title,
  posterUrl: video.poster_url,
  videoUrl: video.video_url,
  isLocked: video.locked,
  showTaskCta: !video.is_last_video_of_phase,
});

const mappedLatestVideo = (
  video: LatestAvailableVideo
): LatestAvailableVideoInfo => ({
  video: mappedVideo(video.latest_available_video),
  blockedByTasks: video.video_blocked_by_tasks,
});

const decomposeJSON = (json: AllVideosResponse) =>
  map(json.all_videos, mappedVideo);

export const retrieveAllVideos = () =>
  charan
    .get(allVideosUrl)
    .then((json: AllVideosResponse) => decomposeJSON(json));

export const retrieveLatestVideo = () =>
  charan
    .get(latestAvailableVideo)
    .then((json: LatestAvailableVideo) => mappedLatestVideo(json));

export const postVideoCompletedAction = (id: number) =>
  charan
    .post(videoCompletedAction, { phase_content_sequence_id: id })
    .then((json: LatestAvailableVideo) => mappedLatestVideo(json));

const api = {
  retrieveAllVideos,
  retrieveLatestVideo,
  postVideoCompletedAction,
};

export default api;
