import { isEmpty, map, toPairs } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Button } from "../../../common-components/Button";
import SectionTitle from "../../../common-components/SectionTitle";
import Wrapper from "../../../common-components/Wrapper";
import { fetchMiniCards } from "../../actions";
import MiniCardList from "../MiniCardList";

import styles from "./styles.css";

const _miniCardSection = (miniCardGroup) => {
  const [date, miniCardList] = toPairs(miniCardGroup)[0];
  return (
    <div key={date}>
      <div className={styles.date}>
        {moment(date).format("dddd, MMM D, YYYY")}
      </div>
      <MiniCardList miniCardList={miniCardList} />
    </div>
  );
};

export const History = ({ groupedMiniCards, onFetchMiniCards, nextUrl }) =>
  !isEmpty(groupedMiniCards) ? (
    <Wrapper>
      <SectionTitle>My Activity</SectionTitle>
      {map(groupedMiniCards, _miniCardSection)}
      {nextUrl && (
        <Button onClick={onFetchMiniCards} style="SecondaryBtn">
          See More
        </Button>
      )}
    </Wrapper>
  ) : null;

History.propTypes = {
  groupedMiniCards: PropTypes.array.isRequired,
  nextUrl: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchMiniCards: () => dispatch(fetchMiniCards()),
});

const mapStateToProps = ({ groupedMiniCards, nextUrl }) => ({
  groupedMiniCards,
  nextUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
