import * as Sentry from "@sentry/browser";
import React from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";

import { Heading2 } from "@virtahealth/components";
import { Spinner } from "@virtahealth/design-components";
import { StarterKitScreen } from "@virtahealth/experiences";
import { FormValues } from "@virtahealth/experiences/src/StarterKitScreen";
// eslint-disable-next-line import/no-unresolved
import { GoogleHealthCare } from "@virtahealth/experiences/src/StarterKitScreen/types";

import { patch as createPatientActionCompletion } from "../action_list/api";
import { PatientActionCompletionJSON } from "../action_list/types";
import Wrapper from "../common-components/Wrapper";
import gqlClient from "../gqlClient";
import { BrazeEventName, logBrazeEvent } from "../lib/engagement";
import {
  CREATE_FHIR_QUESTIONNAIRE_RESPONSE,
  FETCH_FHIR_QUESTIONNAIRE_DATA,
} from "../queries/fhirQuestionnaire";
import { RootState } from "../types/index";
import { UserState } from "../user/reducers";

import styles from "./styles.css";

const PATIENT_ACTION_COMPLETIONS_ENDPOINT = (patientActionId: string) =>
  `/api/v1/patients/actions/${patientActionId}/completions`;
interface FormDataProps {
  initialAnswers?: GoogleHealthCare.InitialAnswer[];
  questionnaire: GoogleHealthCare.Questionnaire.FhirQuestionnaire;
}

interface Params {
  patientActionId: string;
}

const StarterKit: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { patientActionId },
  },
}) => {
  const user = useSelector<RootState, UserState>((state) => state.user);
  const [formData, setFormData] = React.useState<FormDataProps | null>(null);

  React.useEffect(() => {
    const fetchFormData = async () => {
      const client = await gqlClient();
      const response = await client?.query({
        fetchPolicy: "network-only",
        query: FETCH_FHIR_QUESTIONNAIRE_DATA,
        variables: {
          virtaId: user?.virtaId,
          name: "request-starter-kit",
        },
      });

      const { fhirJson, initialAnswers } = response?.data?.questionnaire ?? {};
      const questionnaire = JSON.parse(
        fhirJson
      ) as GoogleHealthCare.Questionnaire.FhirQuestionnaire;

      setFormData({
        questionnaire,
        initialAnswers,
      });
    };
    fetchFormData();
  }, [user?.virtaId]);

  const handleSubmit = async (values: FormValues) => {
    const requestData = { fhirJson: JSON.stringify(values) };

    const client = await gqlClient();
    return client
      ?.mutate({
        mutation: CREATE_FHIR_QUESTIONNAIRE_RESPONSE,
        variables: { requestData },
      })
      .then((data) => {
        const completeActionRequest: PatientActionCompletionJSON = {
          entity_type: "checkmark",
          entity_value: "true",
          completed_on_utc: new Date().toUTCString(),
          user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        createPatientActionCompletion(
          PATIENT_ACTION_COMPLETIONS_ENDPOINT(patientActionId),
          completeActionRequest
        ).catch(() => {
          createPatientActionCompletion(
            PATIENT_ACTION_COMPLETIONS_ENDPOINT(patientActionId),
            completeActionRequest
          ).catch((error: Error) => {
            // retry action completion 2 times then log to sentry
            Sentry.captureException(error);
          });
        });
        return data;
      })
      .then(() => {
        logBrazeEvent(BrazeEventName.StarterKitOrdered, {});
      })
      .catch((e) => {
        Sentry.captureException(e.message);
        throw e.message;
      });
  };

  return (
    <Wrapper className={styles.container} shouldHaveVerticalMargins={false}>
      <div className={styles.header}>
        <Heading2>Order Your Starter Kit</Heading2>
      </div>
      <div className={styles.content}>
        {!formData ? (
          <Spinner />
        ) : (
          <StarterKitScreen
            virtaId={user.virtaId}
            questionnaire={formData.questionnaire}
            initialAnswers={formData.initialAnswers}
            planDefinitionName={user.planDefinition}
            onSubmit={handleSubmit}
            onSuccessClose={() => {
              window.location.replace("/");
            }}
            openLearnButtonLink={() => {
              window.open(
                "https://virtahealth.zendesk.com/hc/en-us/sections/360008467814-Starter-Kit"
              );
            }}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default StarterKit;
