/* eslint-disable max-lines */
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  SearchInput,
  VirtaContextComponents,
  trackClickedAssistantBarClear,
} from "@virtahealth/components";
import { intlFormatMessage, messages } from "@virtahealth/utils";

import AddMenu from "../../../add_menu";
import { getCurrentPlanDefinitionName } from "../../../helpers/planDefinition";
import { RootState } from "../../../types";
import { PlanDefinitionName } from "../../../types/planDefinition";
import {
  assistant_card_feedback,
  assistant_chat_messages,
  assistant_community_comments,
  assistant_web_enabled,
} from "../../../utils/LaunchDarklyFlags";
import { setAssistantOpen } from "../../actions";
import { selectIsAssistantOpen } from "../../selectors";
import { VirtaAssistantSearchBar } from "../VirtaAssistant/VirtaAssistantSearchBar";

const analyticsConfig = {
  today: {
    querySelector: ".navbar-button-today",
    eventName: "Clicked Today Nav Bar Button",
    href: "/",
  },
  history: {
    querySelector: ".navbar-button-history",
    eventName: "Clicked History Nav Bar Button",
    href: "/my_history",
  },
  chat: {
    querySelector: ".navbar-button-chat",
    eventName: "Clicked Chat Nav Bar Button",
    href: "/chat",
  },
  discover: {
    querySelector: ".navbar-button-discover",
    eventName: "Clicked Discover Nav Bar Button",
    href: "/discover",
  },
  community: {
    querySelector: ".navbar-button-community",
    eventName: "Clicked Community Nav Bar Button",
    href: "/community",
  },
  search: {
    querySelector: ".navbar-button-search",
    eventName: "Clicked Search Nav Bar Button",
    href: "/discover",
  },
  more: {
    querySelector: ".navbar-button-more",
    eventName: "Clicked More Menu Nav Bar Button",
    href: "/more",
  },
};

const NavBar: React.FC = () => {
  const planDefinition = getCurrentPlanDefinitionName();
  const [assistantEnabled, setAssistantEnabled] =
    React.useState<boolean>(false);
  const [assistantCardFeedbackEnabled, setAssistantCardFeedbackEnabled] =
    React.useState<boolean>(false);
  const [
    assistantCommunityCommentsEnabled,
    setAssistantCommunityCommentsEnabled,
  ] = React.useState<boolean>(false);
  const [assistantChatMessagesEnabled, setAssistantChatMessagesEnabled] =
    React.useState<boolean>(false);
  const isAssistantOpen = useSelector((state: RootState) =>
    selectIsAssistantOpen(state)
  );
  const dispatch = useDispatch();
  const screenPathName = window.location.pathname;
  const screenName =
    screenPathName === "/" ? "today" : screenPathName.substr(1);

  const logNavbarItemClicked = (navbarItemConfig: {
    querySelector: string;
    eventName: string;
    href: string;
  }) => {
    const eventProperties = {
      screen_name: screenName,
      screen_path: screenPathName,
      duration: -1, // TODO: https://jira.virta.us/browse/TM-1957
      destination_path: navbarItemConfig.href,
    };
    if (window.virta && window.virta.amplitude) {
      window.virta.amplitude.logEvent(
        navbarItemConfig.eventName,
        eventProperties
      );
    }
  };

  const setPlaceholderMessage = () => {
    return window.location.href.includes("/community")
      ? messages.searchCommunityPosts
      : window.location.href.includes("/discover")
        ? messages.searchArticlesAndRecipes
        : messages.searchVirta;
  };

  const handleClick =
    (
      analyticsKey:
        | "today"
        | "history"
        | "chat"
        | "discover"
        | "community"
        | "search"
        | "more"
    ) =>
    () => {
      const item = analyticsConfig[analyticsKey];
      logNavbarItemClicked(item);
      window.location.href = item.href;
    };
  const context = React.useContext(VirtaContextComponents);
  const { gqlClient, analyticsClient } = context;

  const removeSearchQuery = () => {
    return new Promise((resolve) => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("search")) {
        queryParams.delete("search");
        const remainingQueryParams = queryParams.toString();
        window.location.replace(
          `${location.pathname}${
            remainingQueryParams ? `?${remainingQueryParams}` : ""
          }`
        );
      }
      resolve(true);
    });
  };

  const hideVirtaAssistant = async () => {
    // if the user came to the page with a search query, remove it from the URL
    await removeSearchQuery();
    dispatch(setAssistantOpen(false));
    if (analyticsClient) {
      trackClickedAssistantBarClear({
        analyticsClient,
        activeTab: screenName,
      });
    }
  };

  React.useEffect(() => {
    const loadFlags = async () => {
      const [
        assistantWebEnabled,
        cardFeedbackEnabled,
        communityCommentsEnabled,
        chatMessagesEnabled,
      ] = await Promise.all([
        context.launchDarkly?.getBooleanFlag(assistant_web_enabled, false),
        context.launchDarkly?.getBooleanFlag(assistant_card_feedback, false),
        context.launchDarkly?.getBooleanFlag(
          assistant_community_comments,
          false
        ),
        context.launchDarkly?.getBooleanFlag(assistant_chat_messages, false),
      ]);
      setAssistantEnabled(assistantWebEnabled || false);
      setAssistantCardFeedbackEnabled(cardFeedbackEnabled || false);
      setAssistantCommunityCommentsEnabled(communityCommentsEnabled || false);
      setAssistantChatMessagesEnabled(chatMessagesEnabled || false);
    };
    loadFlags();
  }, []);

  return (
    <nav
      className={`navbar hide-in-webview ${isAssistantOpen ? "va-shown" : ""}`}
      role="navigation"
    >
      <div className="Grid">
        <div className="Cell flex f-nowrap f-space-between">
          <div className="navbar-button--add">
            <a className="navbar-add-droplet" data-action="show_menu">
              <div className="icon-droplet-add">
                <svg className="icon icon-plus">
                  <use xlinkHref="#icon-plus" />
                </svg>
              </div>
            </a>
            <AddMenu />
          </div>
          <div className="navbar-left flex">
            <a className="navbar-logo hide-on-small-and-down" href="/">
              <svg>
                <use xlinkHref="#virta-logo" />
              </svg>
            </a>
            <a
              className={`navbar-button navbar-button-today navbar-button--selected ${
                isAssistantOpen ? "va-shown" : ""
              }`}
              href="/"
            >
              <svg className="icon icon--navbar icon-nav-today">
                <use xlinkHref="#icon-nav-today" />
              </svg>
              <span>{intlFormatMessage(messages.today)}</span>
            </a>
            <a
              className={`navbar-button navbar-button-history ${
                isAssistantOpen ? "va-shown" : ""
              }`}
              onClick={handleClick("history")}
            >
              <svg className="icon icon--navbar icon-nav-history">
                <use xlinkHref="#icon-nav-history" />
              </svg>
              <span>{intlFormatMessage(messages.history)}</span>
            </a>
            <a
              className={`navbar-button navbar-button-chat ${
                isAssistantOpen ? "va-shown" : ""
              }`}
              onClick={handleClick("chat")}
            >
              <svg className="icon icon--navbar icon-nav-coaching">
                <use xlinkHref="#icon-nav-coaching" />
              </svg>
              <span>{intlFormatMessage(messages.coaching)}</span>
            </a>
            <a
              className={`navbar-button navbar-button-discover ${
                isAssistantOpen ? "va-shown" : ""
              }`}
              onClick={handleClick("discover")}
            >
              <svg className="icon icon--navbar icon-nav-discover">
                <use xlinkHref="#icon-nav-discover" />
              </svg>
              <span>{intlFormatMessage(messages.discover)}</span>
            </a>

            {(!planDefinition ||
              planDefinition === PlanDefinitionName.Reversal) && (
              <a
                className={`navbar-button navbar-button-community ${
                  isAssistantOpen ? "va-shown" : ""
                }`}
                onClick={handleClick("community")}
              >
                <svg className="icon icon--navbar icon-nav-community">
                  <use xlinkHref="#icon-nav-community" />
                </svg>
                <span>{intlFormatMessage(messages.community)}</span>
              </a>
            )}
          </div>
          <div
            className={`navbar-right flex ${
              assistantEnabled ? "va-enabled" : ""
            } ${isAssistantOpen ? "va-shown" : ""}`}
          >
            {assistantEnabled ? (
              isAssistantOpen ? (
                <>
                  <div className="va-left"></div>
                  <div className="va-wrap">
                    <a
                      className="navbar-button navbar-button-search va-back-button"
                      onClick={() => hideVirtaAssistant()}
                    >
                      <svg className="icon icon--navbar icon-left-caret">
                        <use xlinkHref="#icon-left-caret" />
                      </svg>
                    </a>
                    <div className="va-input-wrap">
                      <VirtaAssistantSearchBar
                        analyticsClient={analyticsClient!}
                        gqlClient={gqlClient!}
                        parentComponentName={screenName}
                        cardFeedbackEnabled={assistantCardFeedbackEnabled}
                        communityCommentsEnabled={
                          assistantCommunityCommentsEnabled
                        }
                        chatMessagesEnabled={assistantChatMessagesEnabled}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="navbar-va-search va-input">
                  <SearchInput
                    value=""
                    onChangeText={() => {}}
                    onFocus={() => dispatch(setAssistantOpen(true))}
                    placeholderMessage={setPlaceholderMessage()}
                    variant="minimal"
                  />
                </div>
              )
            ) : (
              <>
                <svg className="navbar-right-logo">
                  <use xlinkHref="#virta-logo" />
                </svg>
                <a
                  className="navbar-button navbar-button-search"
                  onClick={handleClick("search")}
                >
                  <svg className="icon icon--navbar icon-nav-search">
                    <use xlinkHref="#icon-nav-search" />
                  </svg>
                </a>
              </>
            )}

            <a
              className={`navbar-button navbar-button-more ${
                isAssistantOpen ? "va-shown" : ""
              }`}
              onClick={handleClick("more")}
              data-action="show_more"
            >
              <svg className="icon icon--navbar icon-nav-more">
                <use xlinkHref="#icon-nav-more" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
/* eslint-enable max-lines */
