import { UserProperties } from "../lib/analytics";
import { RootState } from "../types";

// selectors

export const selectUserId = (state: RootState) => state.user.id;
export const selectUserPropertiesForAnalytics = (
  state: RootState
): UserProperties => ({
  adaptation_date: state.user.adaptationDate || undefined,
  experiment_names: [] as string[], // TODO: TM-1623 Add actual field
  participant_subtype: state.user.participantSubtype,
  participant_type: state.user.participantType,
  product: "pa",
  registration_date: state.user.registrationDate,
  registration_days: state.user.daysOnVirta,
  user_id: selectUserId(state),
  virta_id: state.user.virtaId,
  permanent_coach_id: state.user.coachId,
  plan_definition: state.user.planDefinition,
  preferred_language: state.userProfile.preferredLanguage,
});
