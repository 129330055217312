import { intlLocale } from "@virtahealth/utils";

// if the user profile & biometric response both have languages defined, use our preferred language from the response
export const translateResponse = (json: any) => {
  let preferredLanguage = intlLocale();
  if (preferredLanguage && json) {
    if (preferredLanguage) {
      if (!preferredLanguage.includes("-")) {
        preferredLanguage = preferredLanguage + "-us";
      }
      preferredLanguage = preferredLanguage.replace("-", "_");
      if (json[preferredLanguage]) {
        return json[preferredLanguage];
      }
    }
  }
  return json;
};
