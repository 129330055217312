import { formatRelative } from "date-fns";
import { sortBy, uniqBy } from "lodash";

import {
  ADD_ASSISTANT_LOADING_CHAT_MESSAGE,
  RECEIVE_POSSIBLY_NEW_CHAT_MESSAGES,
  SET_ASSISTANT_CHAT_MESSAGE_ID,
} from "../constants/ActionTypes";
import { toTitleCase } from "../utils/api_utils";

const defaultState = {
  messages: [],
  reset: true,
};

export const chatMessages = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_POSSIBLY_NEW_CHAT_MESSAGES: {
      let messages = [];
      if (action.chatMessages.clear) {
        messages = action.chatMessages.messages;
      } else {
        const deduped = uniqBy(
          action.chatMessages.messages.concat(state.messages.splice(0)),
          (message) => {
            const source = message.source ?? "patient";
            return source + message.id;
          }
        );
        messages = sortBy(deduped, ["sentOnRaw"]).reverse();
        messages = messages.filter(
          (message) => !message.isLoadingAssistantSearch
        );
      }
      let newState = {
        ...state,
        messages,
        virtaAssistantChatMessageId: null,
        reset: false,
      };
      // when loading previous messages, we want to keep the nextUrl unchanged, and vice versa
      if (action.chatMessages.direction !== "next") {
        newState.previousUrl =
          state.previousUrl === null ? null : action.chatMessages.previousUrl;
        // nextPageToken is only used for previous direction with applicant messages
        newState["nextPageToken"] = action.chatMessages.nextPageToken;
      }
      if (action.chatMessages.direction !== "previous") {
        newState.nextUrl =
          state.previousUrl === null ? null : action.chatMessages.nextUrl;
      }
      return newState;
    }
    case ADD_ASSISTANT_LOADING_CHAT_MESSAGE: {
      let messages = [
        {
          id: action.messageId + 1,
          sentOnLabel: toTitleCase(formatRelative(Date.now(), new Date())),
          sentOnRaw: new Date(),
          content: "",
          isToUser: true,
          isFromChatbot: true,
          isLoadingAssistantSearch: true,
          coachName: "",
        },
        ...state.messages,
      ];
      let newState = {
        ...state,
        messages,
      };
      return newState;
    }
    case SET_ASSISTANT_CHAT_MESSAGE_ID: {
      return {
        ...state,
        virtaAssistantChatMessageId: action.messageId,
      };
    }
    default: {
      return state;
    }
  }
};
