/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */

// the rules that should be used by all accessibility checks
const REQUIRED_WCAG_RULES = [
  "wcag2a",
  "wcag2aa",
  "wcag21a",
  "wcag21aa",
  /**
   * looking at these rules, it's possible they could give false positives
   * for component-level (like Storybook) tools but in practice, I haven't
   * seen any issues
   *
   * if these rules do start failing in places they shouldn't, we may need
   * to split this in to two configs for the different use cases
   */
  "best-practice",
];

export function setupAxeCore() {
  if (__DEV__) {
    // only show axe warnings & errors in development mode
    const axe = require("@axe-core/react");
    const React = require("react");
    const ReactDOM = require("react-dom");
    axe(React, ReactDOM, 1000, { runOnly: REQUIRED_WCAG_RULES });
  }
}
/* eslint-enable global-require */
/* eslint-enable import/no-extraneous-dependencies */
