const _BLOOD_PRESSURE_ICON_CLASS = "BloodPressure";
const _DASHED_CHECKMARK_ICON_CLASS = "DashedCheckmark";
const _DIETARY_CHANGES_ICON_CLASS = "DietaryChanges";
const _FOOD_ENVIRONMENT_ICON_CLASS = "FoodEnvironment";
const _FOOD_PIC_ICON_CLASS = "FoodPic";
const _GLUCOSE_ICON_CLASS = "Glucose";
const _KETONES_ICON_CLASS = "Ketones";
const _PHYSICAL_ACTIVITY_ICON_CLASS = "PhysicalActivity";
const _SYMPTOMS_ICON_CLASS = "Symptoms";
const _VIDEO_ICON_CLASS = "Video";
const _WEIGHT_ICON_CLASS = "Weight";

const __DIETARY_CHANGED_ACTION_TYPE_ID = "start_dietary_changes";
const _ACTION_TYPE_ID = "action";
const _BLOOD_PRESSURE_TYPE_ID = "blood_pressure";
const _COMMUNICATE_ACTION_TYPE_ID = "communicate";
const _COMMUNICATE_PLAN_ACTION_TYPE_ID = "communicate_plan";
const _SCHEDULE_ONBOARDING_ACTION_TYPE_ID = "schedule_onboarding";
const _COMPLETE_PHASE_CONTENT_ACTION_TYPE_ID = "complete_phase_content";
const _EXTERNAL_LINK_ACTION_TYPE_ID = "external_link";
const _FOOD_DESCRIPTION_TYPE_ID = "food_description";
const _FOOD_ENVIRONMENT_TYPE_ID = "food_environment_action";
const _GLUCOSE_TYPE_ID = "glucose";
const _KETONES_TYPE_ID = "ketone";
const _MEASURE_ACTION_TYPE_ID = "measure";
const _MEASURE_KETONES_ACTION_TYPE_ID = "measure_ketones";
const _MEASURE_GLUCOSE_ACTION_TYPE_ID = "measure_glucose";
const _PHYSICAL_ACTIVITY_ID = "physical_activity";
const _SYMPTOMS_TYPE_ID = "symptoms";
const _VIDEO_TYPE_ID = "video";
const _WAIT_ACTION_TYPE_ID = "wait";
const _WEIGHT_TYPE_ID = "weight";

export const ICON_CLASS_FOR_MINI_CARD_TYPE = {
  [__DIETARY_CHANGED_ACTION_TYPE_ID]: _DIETARY_CHANGES_ICON_CLASS,
  [_ACTION_TYPE_ID]: _DIETARY_CHANGES_ICON_CLASS,
  [_BLOOD_PRESSURE_TYPE_ID]: _BLOOD_PRESSURE_ICON_CLASS,
  [_COMMUNICATE_ACTION_TYPE_ID]: _FOOD_PIC_ICON_CLASS,
  [_COMMUNICATE_PLAN_ACTION_TYPE_ID]: _FOOD_PIC_ICON_CLASS,
  [_SCHEDULE_ONBOARDING_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_COMPLETE_PHASE_CONTENT_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_EXTERNAL_LINK_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_FOOD_DESCRIPTION_TYPE_ID]: _FOOD_PIC_ICON_CLASS,
  [_FOOD_ENVIRONMENT_TYPE_ID]: _FOOD_ENVIRONMENT_ICON_CLASS,
  [_GLUCOSE_TYPE_ID]: _GLUCOSE_ICON_CLASS,
  [_KETONES_TYPE_ID]: _KETONES_ICON_CLASS,
  [_MEASURE_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_MEASURE_KETONES_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_MEASURE_GLUCOSE_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_PHYSICAL_ACTIVITY_ID]: _PHYSICAL_ACTIVITY_ICON_CLASS,
  [_SYMPTOMS_TYPE_ID]: _SYMPTOMS_ICON_CLASS,
  [_VIDEO_TYPE_ID]: _VIDEO_ICON_CLASS,
  [_WAIT_ACTION_TYPE_ID]: _DASHED_CHECKMARK_ICON_CLASS,
  [_WEIGHT_TYPE_ID]: _WEIGHT_ICON_CLASS,
};
