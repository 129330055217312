import { gql } from "@virtahealth/utils";

export const FETCH_FHIR_QUESTIONNAIRE_DATA = gql`
  query FetchFhirQuestionnaire($name: String!, $virtaId: String!) {
    questionnaire(name: $name, virtaId: $virtaId) {
      fhirJson
      initialAnswers {
        linkId
        answer
      }
    }
  }
`;

export const CREATE_FHIR_QUESTIONNAIRE_RESPONSE = gql`
  mutation CreateFhirQuestionnaireResponse(
    $requestData: CreateQuestionnaireResponseInput!
  ) {
    createQuestionnaireResponse(requestData: $requestData) {
      id
    }
  }
`;
