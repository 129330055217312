import {
  AssistantStorageJSONKeys,
  AssistantStorageKeys,
} from "@virtahealth/experiences";
import { JSONValue, VirtaStore } from "@virtahealth/utils";

// PA-specific implementation of a VirtaStore using localStorage
// The implementation includes login and logout callback to be called by the app code
type ClientStorePA = VirtaStore<
  AssistantStorageKeys,
  AssistantStorageJSONKeys
> & {
  logout: () => void;
  login: () => void;
};

// Max length of entries
const MAX = 10 * 1024; // 10KB

// A very simple hash function used to hide the virtaId
const simpleHash = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

// Check that the size is below the limit
const check = (value: string): string => {
  if (value.length >= MAX) {
    throw Error("Storage limit exceeded");
  }
  return value;
};

// Helper functions to set and get from localStorage
const set = (hash: string, key: string, value: string): void => {
  const fullKey = `${hash}-${key}`;
  localStorage.setItem(fullKey, check(value));
};

const get = (hash: string, key: string): string | null => {
  const fullKey = `${hash}-${key}`;
  return localStorage.getItem(fullKey);
};

const drop = (hash: string, key: string): void => {
  const fullKey = `${hash}-${key}`;
  localStorage.removeItem(fullKey);
};

// Generating the client
export const clientStorePA = (virtaId: string): ClientStorePA => {
  const hash = simpleHash(virtaId);

  return {
    set: (key: AssistantStorageKeys, value: string) => {
      try {
        set(hash, key, value);
        return Promise.resolve({ result: "success", value: undefined });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    get: (key: AssistantStorageKeys) => {
      try {
        const value = get(hash, key);
        return Promise.resolve({ result: "success", value });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    remove: (key: AssistantStorageKeys) => {
      try {
        drop(hash, key);
        return Promise.resolve({ result: "success", value: undefined });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    setJSONObject: (key: AssistantStorageJSONKeys, value: JSONValue) => {
      try {
        set(hash, key, JSON.stringify(value));
        return Promise.resolve({ result: "success", value: undefined });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    getJSONObject: (key: AssistantStorageJSONKeys) => {
      try {
        const jas = get(hash, key);
        const value = jas && JSON.parse(jas);
        return Promise.resolve({ result: "success", value });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    removeJSONObject: (key: AssistantStorageJSONKeys) => {
      try {
        drop(hash, key);
        return Promise.resolve({ result: "success", value: undefined });
      } catch (e) {
        return Promise.resolve({
          result: "failure",
          error: (e as Error).message,
        });
      }
    },

    logout: () => {
      localStorage.clear();
    },

    login: () => {
      // Pass
    },
  };
};
