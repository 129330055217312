import classNames from "classnames/bind";
import React from "react";

import styles from "./styles.css";

const cx = classNames.bind(styles);

interface Props {
  children: any;
  gutters?: boolean;
  shouldHaveVerticalMargins?: boolean;
  style?: object;
  className?: string;
  testId?: string;
}

const Wrapper: React.FC<Props> = ({
  children,
  gutters = true,
  shouldHaveVerticalMargins = false,
  style: inlineStyles,
  className,
  testId,
}: Props): React.ReactElement<any> => (
  <div
    className={cx(
      styles.wrapper,
      { gutters },
      className,
      shouldHaveVerticalMargins
        ? styles.verticalMargins
        : styles.noVerticalMargins
    )}
    style={inlineStyles}
    data-testid={testId}
  >
    {children}
  </div>
);

export default Wrapper;
