import charan from "../charan_csrf_wrapper";

import { PatientActionCompletionJSON, PatientActionRequest } from "./types";

export const get = (endpoint: string) => {
  return charan.get(endpoint);
};

export const patch = (
  endpoint: string,
  payload: PatientActionCompletionJSON
) => {
  return charan.patch(endpoint, payload);
};

export const put = (endpoint: string, payload: PatientActionRequest) => {
  return window
    .fetch(endpoint, {
      credentials: "same-origin",
      method: "PUT",
      body: JSON.stringify({
        ...payload,
        csrf_token: window.virta.csrf_token,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
    .then((response) => response.json());
};
