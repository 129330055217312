import React from "react";

import { AjaxButton, Button } from "../../../common-components/Button";
import { logTaskClickEvent } from "../../../lib/analytics";
import store from "../../../store";
import { completeTask } from "../../../task_cards/actions";
import Card from "../Card";
import NativeMobileVideoPlayer from "../NativeMobileVideoPlayer";

function _playVideoOnNativeMobilePlayer(event) {
  event.preventDefault();
  const $video = document.querySelector("[data-role=video_task_card_player]");
  if ($video !== null) {
    $video.play();
  }
}

const VideoTaskCardNativeMobilePlayer = ({ task }) => {
  const {
    id,
    title,
    header,
    video_poster_url = "/static/imgs/default-video-poster.jpg",
    video_source_url,
    primary_cta_label,
    secondary_cta_label,
    type,
  } = task;

  const primaryButton = (
    <Button
      name={title}
      style="PrimaryBtnLight"
      onClick={() => {
        logTaskClickEvent(
          {
            taskName: title,
            taskText: primary_cta_label,
            taskType: type,
          },
          {
            buttonCategory: "CTA",
            ctaUrl: video_source_url,
            phaseTaskCardType: type,
            phaseContentSequenceId: id,
          }
        );
      }}
    >
      {primary_cta_label}
    </Button>
  );

  const dispatchCompleteTask = (errorHandler) =>
    store.dispatch(completeTask(id, errorHandler));

  const secondaryButton = (
    <AjaxButton
      name={title}
      ajaxAction={(togglePendingState) => {
        dispatchCompleteTask(togglePendingState);
        logTaskClickEvent(
          {
            taskName: title,
            taskText: secondary_cta_label,
            taskType: type,
          },
          {
            buttonCategory: "progression",
            ctaUrl: null,
            phaseTaskCardType: type,
            phaseContentSequenceId: id,
          }
        );
      }}
      style="SecondaryBtnLight"
    >
      {secondary_cta_label}
    </AjaxButton>
  );
  return (
    <div className="video_module" onClick={_playVideoOnNativeMobilePlayer}>
      <Card
        type="Video"
        name={title}
        header={header}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        backgroundImageSrc={video_poster_url}
      />
      <NativeMobileVideoPlayer task={task} />
    </div>
  );
};

export default VideoTaskCardNativeMobilePlayer;
