import React from "react";

import { biomarker_achievement_service } from "../../../utils/LaunchDarklyFlags";
import { VirtaLaunchDarklyClient } from "../../../utils/ld_client";
import { BADGE_IMAGES, BADGE_IMAGE_PATH } from "../../constants";

interface Props {
  achievement: string | null;
  size?: number;
  alt?: string;
}

const AchievementBadge: React.FC<Props> = ({
  achievement,
  size = 300,
  alt,
}: Props) => {
  const [useAchievements, setUseAchievements] = React.useState(false);
  React.useEffect(() => {
    // This is never called so maybe just delete it
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const asyncFun = async () => {
      setUseAchievements(
        await VirtaLaunchDarklyClient?.getBooleanFlag(
          biomarker_achievement_service,
          false
        )
      );
      asyncFun();
    };
  }, []);
  return (
    <img
      src={BADGE_IMAGE_PATH + BADGE_IMAGES[achievement || "placeholder"]}
      style={useAchievements ? { height: 200, width: 200 } : { height: size }}
      alt={alt || "Achievement badge"}
    />
  );
};

export default AchievementBadge;
