import * as Sentry from "@sentry/browser";
import * as amplitude from "amplitude-js";
import { differenceInDays } from "date-fns";

import { AddBiomarkerType } from "../add_menu/components/AddMenuItem/constants";
import { BiomarkerActionType } from "../tdf_biomarkers/types";
import { A1cEstimationStatus } from "../weekly_summary/types";

const analyticsDefaultConfig: amplitude.Config = {
  includeReferrer: true,
  includeUtm: true,
  trackingOptions: { ip_address: false },
};

export const identifyUser = (userProperties: UserProperties) => {
  const { adaptation_date, adaptation_days } = userProperties;
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .setUserId(`${userProperties.virta_id}`);
    window.virta.amplitude.getInstance().setUserProperties({
      ...userProperties,
      adaptation_days:
        !adaptation_days && adaptation_date
          ? differenceInDays(new Date(), new Date(adaptation_date))
          : adaptation_days,
    });
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

// Note: if the shape of identifyAnalyticsTracking changes,
// make sure to update the call to it in www/templates/_base.html
export const initializeAnalyticsTracking = (
  apiKey: string,
  userId?: string,
  config: amplitude.Config = {}
) => {
  amplitude.getInstance().init(apiKey, userId, {
    ...analyticsDefaultConfig,
    ...config,
  });
  window.virta = window.virta || {};
  window.virta.amplitude = amplitude;
};

export const logEventDropletBiomarkerClick = (
  destination: string,
  addBiomarkerType: AddBiomarkerType
) => {
  const eventName = BIOMARKER_TO_CLICK_EVENT_MAP[addBiomarkerType];
  const firstPathInURL = window.location.pathname.substring(1).split("/")[0];
  logClickEvent(eventName, {
    screen_name: firstPathInURL,
    screen_path: getScreenPathFromWindow(),
    duration: -1,
    destination_path: destination.split("?")[0], // We don't want the query parameters
  });
};

export const logEventBiomarkerActionAddClick = (
  destination: string,
  biomarkerActionType: BiomarkerActionType
) => {
  const eventName = BIOMARKER_ACTION_TO_CLICK_EVENT_MAP[biomarkerActionType];
  logClickEvent(eventName, {
    screen_name: ScreenName.Today,
    screen_path: getScreenPathFromWindow(),
    duration: -1,
    destination_path: `/${destination.split("?")[0]}`, // We don't want the query parameters
  });
};

export const logEventBiomarkerSubmission = (
  status: BiomarkerSubmissionStatus,
  biomarkerActionType: BiomarkerActionType,
  timestamp?: string,
  additionalProperties?: object
) => {
  const { eventName, screenName } =
    BIOMARKER_ACTION_TO_SUBMIT_PROPERTIES_MAP[biomarkerActionType];
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${Interaction.Submitted} ${eventName}`, {
        screen_name: screenName,
        screen_path: getScreenPathFromWindow(),
        duration: -1,
        status,
        ...(timestamp && { entry_timestamp: timestamp }),
        ...additionalProperties,
      });
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logMenuItemClick = (destination: string, buttonLabel: string) => {
  // @ts-ignore
  const eventName = MORE_MENU_ITEM_LABEL_TO_CLICK_EVENT_MAP[buttonLabel];
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${Interaction.Click} ${eventName}`, {
        screen_name: ScreenName.More,
        screen_path: getScreenPathFromWindow(),
        duration: -1,
        destination_path: destination,
      });
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logEventCheckedOffActionItem = (
  event: ActionDetailEventProperties
) =>
  // todo: enrich event with computed duration info?
  logCheckedOffEvent(CheckableElement.ActionItem, event);

export const logEventClickedActionDetails = (
  event: ActionDetailEventProperties
) => {
  // todo: enrich event with computed duration info?
  logClickEvent(ClickableElement.ActionDetails, event);
};

export const logEventClickActionRequestChange = (
  event: ActionRequestChangeEventProperties
) => logClickEvent(ClickableElement.ActionFeedback, event);

export const logEventClickedRemoveActionButton = (
  event: ActionDetailEventProperties
) => logClickEvent(ClickableElement.RemoveActionButton, event);

export const logEventClickedRemoveActionConfirmation = (
  event: ActionDetailEventProperties
) => logClickEvent(ClickableElement.RemoveActionConfirmation, event);

export const logEventClickedActionDetailsCTAButton = (
  event: ActionDetailEventProperties
) => logClickEvent(ClickableElement.ActionLink, event);

export const logTaskClickEvent = (
  taskCard?: TaskCardProperties,
  phaseCard?: PhaseCardProperties
) => {
  if (taskCard) {
    logClickEvent(ClickableElement.TaskCard, {
      ...getCommonPropertiesFromWindow(),
      ...taskCard,
    });
  }
  if (phaseCard) {
    logClickEvent(ClickableElement.PhaseCard, {
      ...getCommonPropertiesFromWindow(),
      ...phaseCard,
    });
  }
};

export const logEventLogOut = (event: CommonProperties) => {
  if (window.virta.amplitude) {
    window.virta.amplitude.getInstance().logEvent("Logged out", event);
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logEventViewedScreener = () => {
  logViewedEvent(ViewableElement.BehavioralHealthScreener, {
    screen_name: "Behavioral Health Screener",
    screen_path: "/actions/screener",
    duration: -1,
  });
};

export const logVideoEvent = (eventProperties: VideoEventProperties) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${eventProperties.action} Video`, eventProperties);
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logClickEvent = (
  clickedElement: AnalyticsElement,
  eventProperties: ClickEventProperties
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${Interaction.Click} ${clickedElement}`, eventProperties);
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logCommunityEvent = (
  communityElement: AnalyticsElement,
  eventProperties: CommunityEventProperties
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(
        `${Interaction.Community} ${communityElement}`,
        eventProperties
      );
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logViewedEvent = (
  viewedElement: AnalyticsElement,
  eventProperties: ViewedEventProperties
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${Interaction.Viewed} ${viewedElement}`, eventProperties);
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logMountedEvent = (
  mountedEvent: AnalyticsElement,
  eventProperties: MountedEventProperties
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${Interaction.Mounted} ${mountedEvent}`, eventProperties);
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export const logCheckedOffEvent = (
  checkedOffElement: AnalyticsElement,
  eventProperties: CheckedOffEventProperties
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(
        `${Interaction.CheckedOff} ${checkedOffElement}`,
        eventProperties
      );
  } else {
    Sentry.captureException(new Error(`window.virta.amplitude not found`));
  }
};

export enum ScreenName {
  ActionDetail = "action_detail",
  Today = "today",
  Unknown = "unknown",
  More = "more",
  WeeklySummary = "weekly-summary",
  Community = "community",
  EducationalVideos = "educational_videos",
}

enum Interaction {
  Click = "Clicked",
  CheckedOff = "Checked Off",
  Submitted = "Submitted",
  Viewed = "Viewed",
  Mounted = "Mounted",
  Community = "Community",
}

export enum BiomarkerSubmissionStatus {
  Success = "success",
  Fail = "fail",
}

type AnalyticsElement =
  | ClickableElement
  | CheckableElement
  | ViewableElement
  | MountableElement
  | CommunityElement;

export enum CommunityElement {
  PostCreated = "Post Created",
  PostUpdated = "Post Updated",
  PostDeleted = "Post Deleted",
  CommentCreated = "Comment Created",
  CommentUpdated = "Comment Updated",
  CommentDeleted = "Comment Deleted",
  Like = "Like",
  Unlike = "Unlike",
  PostViewed = "Post Viewed",
  FeedViewed = "Feed Viewed",
}

export enum ClickableElement {
  ActionDetails = "Action Details",
  ActionDetailsBackButton = "Action Details Back Button",
  ActionFeedback = "Action Feedback",
  ActionListInfo = "Action List Info",
  ActionLink = "Action Link",
  ActionListItem = "Action List Item",
  RemoveActionButton = "Remove Action Button",
  RemoveActionConfirmation = "Remove Action Confirmation",
  eA1cMoreLoggingButton = "eA1c More Logging Button",
  AddDropletKetoneButton = "Add Droplet Ketone Button",
  TodayKetoneAddButton = "Today Ketone Add Button",
  AddDropletGlucoseButton = "Add Droplet Glucose Button",
  TodayGlucoseAddButton = "Today Glucose Add Button",
  AddDropletWeightButton = "Add Droplet Weight Button",
  TodayWeightAddButton = "Today Weight Add Button",
  AddDropletBloodPressureButton = "Add Droplet Blood Pressure Button",
  TodayBloodPressureAddButton = "Today Blood Pressure Add Button",
  AddDropletDailyLogButton = "Add Droplet Daily Log Button",
  TodayDailyLogAddButton = "Today Daily Log Add Button",
  TodayAddAdditionalBiomarkersButton = "Today Add Additional Biomarkers Button",
  MyProfile = "My Profile Button",
  Settings = "Settings Button",
  PaymentInformation = "Payment Information",
  MyWeeklySummary = "Weekly Summary Button",
  MyCarePlan = "Care Plan Button",
  LabOrderForms = "Lab Order Forms Button",
  EducationalVideos = "Educational Videos Button",
  VirtaCheatSheet = "Virta Cheat Sheet Button",
  FoodGuide = "Food Guide Button",
  Skills = "Skills",
  ResourceCenter = "Resource Center Button",
  SupportCenter = "Support Center Button",
  RequestSupplies = "Request Supplies Button",
  RequestCoachChange = "Request Coach Change Button",
  Feedback = "Feedback Button",
  HrDepartment = "❤️ Your HR Department Button",
  Discover = "Discover",
  Button = "Button",
  CommunityTopicNavBar = "Community Topic Nav Bar",
  CommunityCommentButtons = "Community Comment Buttons",
  A1cDescription = "A1c Description Link",
  A1cAccuracy = "A1c Accuracy Link",
  WeeklySummaryNotification = "Weekly Summary Notification",
  WeightGuide = "Weight Guide Link",
  KetoneGuide = "Ketone Guide Link",
  FastingGlucoseGuide = "Fasting Glucose Guide Link",
  GoalSummaryHelp = "Goal Summary Help Icon",
  BiomarkerTask = "Biomarker Task",
  TaskCard = "Task Card",
  PhaseCard = "Phase Card",
  WeightEstimation = "Weight Estimation Description",
  VirtaStore = "Virta Store",
  InsightDismissButton = "Insight Dismiss Button",
  InsightLikeButton = "Insight Like Button",
  InsightDislikeButton = "Insight Dislike Button",
  InsightFeedbackButton = "Insight Feedback Button",
  InsightCtaButton = "Insight CTA Button",
}

export enum ViewableElement {
  A1cEstimation = "A1cEstimation",
  BehavioralHealthScreener = "Behavioral Health Screener",
  ActionList = "Action List",
  ActionDetails = "Action Details",
  WeightEstimation = "Weight Estimation Description",
  AchievmentModal = "Achievment Modal",
  InsightCard = "Insight Card",
}

export enum MountableElement {
  ActionList = "Action List",
}

enum SubmittableElement {
  Ketone = "Ketone",
  Glucose = "Glucose",
  Weight = "Weight",
  BloodPressure = "Blood Pressure",
  DailyLog = "Daily Log",
}

enum BiomarkerScreenName {
  Ketones = "ketones",
  Glucose = "glucose",
  Weight = "weight",
  BloodPressure = "blood_pressure",
  DailyLog = "daily_log",
}

const BIOMARKER_TO_CLICK_EVENT_MAP = {
  Symptoms: ClickableElement.AddDropletDailyLogButton,
  BloodPressure: ClickableElement.AddDropletBloodPressureButton,
  Weight: ClickableElement.AddDropletWeightButton,
  Ketones: ClickableElement.AddDropletKetoneButton,
  Glucose: ClickableElement.AddDropletGlucoseButton,
};

const BIOMARKER_ACTION_TO_CLICK_EVENT_MAP = {
  ketone: ClickableElement.TodayKetoneAddButton,
  "blood pressure": ClickableElement.TodayBloodPressureAddButton,
  weight: ClickableElement.TodayWeightAddButton,
  symptom: ClickableElement.TodayDailyLogAddButton,
  glucose: ClickableElement.TodayGlucoseAddButton,
};

const BIOMARKER_ACTION_TO_SUBMIT_PROPERTIES_MAP = {
  ketone: {
    eventName: SubmittableElement.Ketone,
    screenName: BiomarkerScreenName.Ketones,
  },
  "blood pressure": {
    eventName: SubmittableElement.BloodPressure,
    screenName: BiomarkerScreenName.BloodPressure,
  },
  weight: {
    eventName: SubmittableElement.Weight,
    screenName: BiomarkerScreenName.Weight,
  },
  symptom: {
    eventName: SubmittableElement.DailyLog,
    screenName: BiomarkerScreenName.DailyLog,
  },
  glucose: {
    eventName: SubmittableElement.Glucose,
    screenName: BiomarkerScreenName.Glucose,
  },
};

const MORE_MENU_ITEM_LABEL_TO_CLICK_EVENT_MAP = {
  "My Profile": ClickableElement.MyProfile,
  Settings: ClickableElement.Settings,
  "Payment Information": ClickableElement.PaymentInformation,
  "Weekly Summary": ClickableElement.MyWeeklySummary,
  "My Care Plan": ClickableElement.MyCarePlan,
  "Lab Order Forms": ClickableElement.LabOrderForms,
  "Educational Videos": ClickableElement.EducationalVideos,
  "Virta Cheat Sheet": ClickableElement.VirtaCheatSheet,
  "Food Guide": ClickableElement.FoodGuide,
  Skills: ClickableElement.Skills,
  "Resource Center": ClickableElement.ResourceCenter,
  "Support Center": ClickableElement.SupportCenter,
  "Request Supplies": ClickableElement.RequestSupplies,
  "Request Coach Change": ClickableElement.RequestCoachChange,
  Feedback: ClickableElement.Feedback,
  "❤ Your HR Department": ClickableElement.HrDepartment,
  Discover: ClickableElement.Discover,
  "Mi perfil": ClickableElement.MyProfile,
  Ajustes: ClickableElement.Settings,
  "Resumen semanal": ClickableElement.MyWeeklySummary,
  "Mi plan de atención": ClickableElement.MyCarePlan,
  "Videos educativos": ClickableElement.EducationalVideos,
  "Hoja de referencias de Virta": ClickableElement.VirtaCheatSheet,
  "Guía de comidas": ClickableElement.FoodGuide,
  Habilidades: ClickableElement.Skills,
  "Centro de recursos": ClickableElement.ResourceCenter,
  "Centro de apoyo": ClickableElement.SupportCenter,
  "Solicitar suministros": ClickableElement.RequestSupplies,
  "Solicitar cambio de asesor": ClickableElement.RequestCoachChange,
  Retroalimentación: ClickableElement.Feedback,
  "❤ Su departamento de RR. HH.": ClickableElement.HrDepartment,
  Descubrir: ClickableElement.Discover,
  "Virta Store": ClickableElement.VirtaStore,
};

export enum CheckableElement {
  ActionItem = "Action Item",
}

interface CheckedOffProperties extends CommonProperties {
  value: boolean;
}

export interface ActionDetailProperties {
  action_completion?: boolean;
  action_days_elapsed?: number;
  action_target_freq?: number;
  action_template_id?: number;
  patient_action_id?: string;
  populated_action_title?: string;
  destination_path?: string;
  user_id?: number;
  actionValue?: string;
}

interface ActionListProperties {
  action_list_length?: number;
}

interface BiomarkerEventProperties {
  title?: string;
  link?: string;
}

interface TaskCardProperties {
  taskName?: string;
  taskText?: string;
  taskType?: string;
}

interface PhaseCardProperties {
  buttonCategory?: string;
  ctaUrl?: string | null;
  phaseTaskCardType?: string;
  phaseContentSequenceId?: number;
}

interface VideoEventProperties {
  action: string;
  videoId: number;
  videoTitle: string;
  videoDuration: any;
  videoEventTime: any;
  currentUrl: string;
}

interface AchievementEventProperties {
  icon?: any;
}

interface CommunityProperties {
  url?: string;
  post_id?: number;
  topic_id?: number;
  comment_id?: number;
  category_id?: number;
  virta_id?: string;
  user_id?: number;
}

export type ActionDetailEventProperties = ActionDetailProperties &
  CommonProperties;

export interface ActionRequestChangeProperties extends ActionDetailProperties {
  destination_path: string;
}

export type ActionRequestChangeEventProperties = ActionRequestChangeProperties &
  CommonProperties;

type CommunityEventProperties = CommonProperties & CommunityProperties;

type ClickEventProperties = CommonProperties &
  ActionDetailEventProperties &
  BiomarkerEventProperties &
  TaskCardProperties &
  PhaseCardProperties &
  InsightProperties;

type CheckedOffEventProperties =
  | CheckedOffProperties
  | ActionDetailEventProperties;

type ViewedEventProperties = CommonProperties &
  A1cEstimationViewedEventProperties &
  ActionListProperties &
  ActionDetailEventProperties &
  AchievementEventProperties &
  InsightProperties;

type MountedEventProperties = CommonProperties;

interface A1cEstimationViewedEventProperties extends CommonProperties {
  estimationStatus?: A1cEstimationStatus;
}

interface CommonProperties {
  screen_name: string;
  screen_path: string;
  duration: number;
  destination_path?: string;
  name?: string | null;
}

// Note: if the shape of UserProperties changes, make sure to update the call to
// identifyUser in www/templates/_base.html
export interface UserProperties {
  adaptation_date?: string;
  adaptation_days?: number;
  experiment_names: string[];
  participant_type: string;
  participant_subtype?: string | null;
  product: string;
  registration_date: string;
  registration_days: number;
  user_id: number;
  virta_id: string;
  permanent_coach_id: number;
  plan_definition: string;
  preferred_language: string;
}

export interface InsightProperties {
  title?: string;
  details?: string;
  action_content_id?: string;
  category?: string;
  cta_link?: string;
  patient_action_id?: string;
}

export const getCommonPropertiesFromWindow = (): CommonProperties => ({
  duration: -1, // TODO: TM-1623 Add screen duration tracking
  screen_name: getScreenNameFromWindow(),
  screen_path: getScreenPathFromWindow(),
});

export const getScreenPathFromWindow = () => {
  const currentUrl = new URL(window.location.href);
  return `${currentUrl.origin}${currentUrl.pathname}`;
};

export const getScreenNameFromWindow = (): string => {
  const path = window.location.pathname;
  const hash = window.location.hash;
  switch (true) {
    // match Action Details screen if the pathname is "/actions/" followed by at least one number/letter, but no additional /
    case /^\/actions\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b$/.test(
      path
    ):
      return ScreenName.ActionDetail;
    // match Today screen if the pathname is exactly "/"
    case /^\/$/.test(path):
      return ScreenName.Today;
    // match Community screen if the pathname is "/community/"
    case /^\/community\/$/.test(path):
      // check if we're in a sub-section of community by seeing if the hash has anything after its /
      return hash.length > 2 && hash.split("/").length > 1
        ? `${ScreenName.Community}_${hash.split("/")[1]}`
        : ScreenName.Community;
    // match Educational Videos screen if the pathname is "/et"
    case /^\/et$/.test(path):
      return ScreenName.EducationalVideos;
    // match the Profile Subscreen if the pathname is "/profile" with a hash
    case Boolean(/^\/profile$/.test(path) && hash?.length):
      return hash.slice(1);
    // by default, return the path with all / replaced with _
    default:
      return path.slice(1).split("/").join("_");
  }
};
