import React from "react";

import styles from "./styles.css";

interface Props {
  children: any;
}

const CellTitle: React.FC<Props> = ({
  children,
}: Props): React.ReactElement<any> => (
  <div className={styles.cellTitle}>{children}</div>
);

export default CellTitle;
