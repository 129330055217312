import {
  camelCase,
  isArray,
  isObject,
  mapKeys,
  mapValues,
  snakeCase,
} from "lodash";

export const camelToSnake = (camelCasedWord: string): string => {
  return snakeCase(camelCasedWord);
};

export const snakeToCamel = (snakeCasedWord: string): string => {
  return camelCase(snakeCasedWord);
};

export const toTitleCase = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
};

export const mapKeysDeep = (
  obj: any,
  cb: (value: any, key: any) => any
): any => {
  if (!isObject(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((innerVal) => mapKeysDeep(innerVal, cb));
  }
  return mapValues(mapKeys(obj, cb), (val) => {
    return isObject(val) ? mapKeysDeep(val, cb) : val;
  });
};

export const formatBody = <T>(
  body: T | Body | FormData | object,
  transformer: (k: string) => string = camelToSnake
) => {
  if (body instanceof FormData) {
    return body;
  }
  const formattedBody = mapKeysDeep(body, (value: any, key: any) =>
    transformer(key)
  );

  return JSON.stringify(formattedBody || {});
};

export const formatResponse = (
  responseBody: Body | Body[],
  transformer: (k: string) => string = snakeToCamel
) => {
  const formattedResponseBody = mapKeysDeep(
    responseBody,
    (value: any, key: any) => transformer(key)
  );
  return formattedResponseBody || {};
};

type Body = Record<string, unknown>;
