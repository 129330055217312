import React from "react";

import Icon from "../../../../common-components/Icon/components";

import styles from "./styles.css";

export type AccessoryType = "disclosureArrow" | "edit" | "respond" | "none";

interface Props {
  kind: AccessoryType;
  onClick?: () => void;
}

const Accessory: React.FC<Props> = ({
  kind,
  onClick,
}: Props): React.ReactElement<any> => {
  switch (kind) {
    case "disclosureArrow":
      return (
        <Icon
          additionalStyles={styles.disclosureArrow}
          name="DisclosureArrowRightColor"
          size="1.4rem"
        />
      );
    case "edit":
      return (
        <div className={styles.text} onClick={onClick}>
          Edit
        </div>
      );
    case "respond":
      return (
        <div className={styles.text} onClick={onClick}>
          Respond
        </div>
      );
    case "none":
      return <div />;
    default:
      return <div />;
  }
};

export default Accessory;
