import { Dispatch, Middleware, MiddlewareAPI } from "redux";

import { TOAST_NOTIFICATION_TRIGGERED } from "../../../constants/ActionTypes";

import { dismissToastNotification } from "./actions";
import { Action } from "./types";

export const notificationTimeoutScheduler: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
    if (action.type !== TOAST_NOTIFICATION_TRIGGERED) {
      return next(action);
    }

    setTimeout(
      () => store.dispatch(dismissToastNotification(action.payload.result)),
      action.meta.timeoutLength
    );

    next(action);
  };
