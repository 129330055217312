import {
  RECEIVE_ALL_VIDEOS,
  RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS,
  RECEIVE_LATEST_AVAILABLE_VIDEO,
} from "../constants/ActionTypes";

interface VideoActions {
  type: string;
  allVideos?: object;
  latestAvailableVideo?: object;
  blockedByTasks?: boolean;
}

export const allVideos = (state = [], action: VideoActions) => {
  switch (action.type) {
    case RECEIVE_ALL_VIDEOS:
      return action.allVideos;
    default:
      return state;
  }
};

export const latestAvailableVideo = (state = {}, action: VideoActions) => {
  switch (action.type) {
    case RECEIVE_LATEST_AVAILABLE_VIDEO:
      return action.latestAvailableVideo;
    default:
      return state;
  }
};

export const blockedByTasks = (state = false, action: VideoActions) => {
  switch (action.type) {
    case RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS:
      return action.blockedByTasks;
    default:
      return state;
  }
};
