import { get } from "lodash";

import charan from "../charan_csrf_wrapper";

import { GoalProgressJSON } from "./types";
import { goalUrl } from "./urls";

const mappedGoalProgress = (goalProgress: GoalProgressJSON) => ({
  completedPercent: get(goalProgress, "completed_percent"),
  objective: get(goalProgress, "objective"),
  title: get(goalProgress, "title"),
  completionState: get(goalProgress, "completion_state"),
  phaseDescription: get(goalProgress, "description"),
  summary: get(goalProgress, "summary"),
  endDate: get(goalProgress, "end_date"),
  startDate: get(goalProgress, "start_date"),
  goalViewed: get(goalProgress, "goal_viewed"),
  goalCompletedMessage: get(goalProgress, "goal_completed_message"),
  hideDuration: get(goalProgress, "hide_duration"),
});

export const retrieveGoalProgress = () =>
  charan
    .get(goalUrl)
    .then((goalProgress: GoalProgressJSON) => mappedGoalProgress(goalProgress));

export const patchPhaseModalSeen = () =>
  charan.patch(goalUrl).then((response: any) => response);

const api = {
  retrieveGoalProgress,
  patchPhaseModalSeen,
};

export default api;
