// This is Braze (appboy is their old name)
import * as appboy from "appboy-web-sdk";

const SESSION_TIMEOUT_IN_SECONDS = 1200;

export enum BrazeEventName {
  A1cEstimationViewed = "a1c_estimation_viewed",
  BiomarkerGlucoseLogged = "biomarker_glucose_logged",
  StarterKitOrdered = "starter_kit_ordered",
  RequestSuppliesOrdered = "request_supplies_ordered",
}

// Note: if the shape of initEngagementPlatform changes,
// make sure to update the call to it in www/templates/_base.html
export const initEngagementPlatform = (
  apiKey: string,
  baseUrl: string,
  enableLogging: boolean
) => {
  appboy.initialize(apiKey, {
    baseUrl,
    enableLogging,
    sessionTimeoutInSeconds: SESSION_TIMEOUT_IN_SECONDS,
    enableHtmlInAppMessages: true,
  });
  appboy.display.automaticallyShowNewInAppMessages();
  appboy.openSession();
  window.virta = window.virta || {};
  window.virta.appboy = appboy;
};

export const identifyEngagemntUser = (virtaId: string) => {
  appboy.changeUser(virtaId);
};

interface BrazeMetadata {
  [key: string]: any;
}

export const logBrazeEvent = (event: string, meta: BrazeMetadata) => {
  if (window.virta.appboy) {
    window.virta.appboy.logCustomEvent(event, meta);
  }
};
