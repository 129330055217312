import { PhoneNumberUtil } from "google-libphonenumber";
import gql from "graphql-tag";

import charan from "../charan_csrf_wrapper";
import gqlClient from "../gqlClient";

import { userProfilePhotoUrl, userProfileUrl } from "./urls";

// conditional spread syntax lets us pass as few properties as we'd like and
// receieve an object only containing those corresponding properties
const mappedUserProfile = (userProfile) => {
  const removeCountryCode = (phoneToTrim) =>
    phoneToTrim?.length > 10 ? phoneToTrim.substr(-10, 10) : phoneToTrim;
  const phone = removeCountryCode(userProfile?.primaryMobilePhone);
  const emergencyContactPhone = removeCountryCode(
    userProfile?.emergencyContact?.primaryMobilePhone
  );

  return {
    ...(userProfile.id && { id: userProfile.id }),
    ...(userProfile.name && { fullName: userProfile.name }),
    ...(phone && { phone }),
    ...(userProfile.photoUrl && { photoUrl: userProfile.photoUrl }),
    ...((userProfile.personalGoal || userProfile.goal) && {
      userGoal: userProfile.personalGoal || userProfile.goal,
    }),
    ...(userProfile.days_on_virta && {
      daysOnVirta: userProfile.days_on_virta,
    }),
    ...((userProfile.nickname || userProfile.nickname === "") && {
      nickname: userProfile.nickname,
    }),
    ...(userProfile.primaryEmail && { email: userProfile.primaryEmail }),
    ...(userProfile.emergencyContact && {
      emergencyContact: {
        id: userProfile.emergencyContact.id,
        name: userProfile.emergencyContact.fullName,
        phone: emergencyContactPhone,
        relationship: userProfile.emergencyContact.relationship,
      },
    }),
    ...(userProfile.timezone && { timezone: userProfile.timezone }),
    ...(userProfile.preferredLanguage && {
      preferredLanguage: userProfile.preferredLanguage,
    }),
    ...(userProfile.versionId && { versionId: userProfile.versionId }),
    ...(userProfile.billableCondition && {
      billableCondition: userProfile.billableCondition,
    }),
  };
};

export const retrieveUserProfile = async (clearCache = false) => {
  // eslint-disable-next-line camelcase
  const virtaId = window.virta?.user?.virta_id;
  const fetchUserProfileErrorMessage = "Couldn't retrieve profile information";
  const client = await gqlClient();
  try {
    // retrieve the old version of the profile for id, photoUrl, and daysOnVirta
    const jsonProfile = await charan.get(userProfileUrl);

    // If our gql client gets into a bad state, we can clear its cache to force refetching the user profile
    if (clearCache) {
      await client.resetStore();
    }

    const result = await client.query({
      query: gql`
        query RetrieveUserProfile {
          patientUser(virtaId: "${virtaId}") {
            virtaId
            name
            nickname
            personalGoal
            primaryMobilePhone
            primaryEmail
            emergencyContact {
                relationship
                primaryMobilePhone
                fullName
                id
            }
            timezone
            preferredLanguage
            versionId
            billableCondition
          }
        }
      `,
    });
    if (result.data.patientUser) {
      return mappedUserProfile({ ...jsonProfile, ...result.data.patientUser });
    }

    throw new Error(fetchUserProfileErrorMessage);
  } catch (error) {
    const err = error.error || error.message || fetchUserProfileErrorMessage;
    // eslint-disable-next-line no-alert
    console.log(err);
    return {};
  }
};

export const uploadPhotoRequest = (file, csrfToken) => {
  // TODO use charan https://www.pivotaltracker.com/story/show/142810313
  const data = new window.FormData();
  data.append("photo", file);
  data.append("csrf_token", csrfToken);
  return window.fetch(userProfilePhotoUrl, {
    // eslint-disable-line
    credentials: "same-origin",
    mode: "no-cors",
    method: "POST",
    body: data,
  });
};

export const retrievePhotoRequest = (url) =>
  window.fetch(url, {
    // eslint-disable-line
    credentials: "same-origin",
    mode: "no-cors",
    method: "GET",
  });

const genericGqlMutation = async (
  propertyName,
  displayName,
  newValue,
  versionId
) => {
  // eslint-disable-next-line camelcase
  const saveError = `Failed to save ${displayName}`;
  const client = await gqlClient();
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation UpdatePatientProfileProperty{
          updatePatientProfile(versionId: "${versionId}", ${propertyName}: "${newValue}") {
            patientUser{
              virtaId
              ${propertyName}
              versionId
            }
          }
        }
      `,
    });
    if (result.data.updatePatientProfile.patientUser) {
      return mappedUserProfile(result.data.updatePatientProfile.patientUser);
    }

    throw new Error(saveError);
  } catch (error) {
    const err = error.error || error.message || saveError;
    throw new Error(err);
  }
};

export const saveUserGoal = async (newGoal, versionId) => {
  const newGoalTrimmed = newGoal.trim();
  return genericGqlMutation("personalGoal", "goal", newGoalTrimmed, versionId);
};

export const savePhone = async (newPhoneNumber, versionId) => {
  if (!validatePhone(newPhoneNumber)) {
    throw new Error("Phone number entered is not valid");
  }
  const newPhoneNumberClean = newPhoneNumber.replace(/-/g, "");
  return genericGqlMutation(
    "primaryMobilePhone",
    "phone number",
    newPhoneNumberClean,
    versionId
  );
};

export const saveNickname = async (nickname, versionId) =>
  genericGqlMutation("nickname", "preferred name", nickname, versionId);

export const saveTimezone = async (timezone, versionId) =>
  genericGqlMutation("timezone", "timezone", timezone, versionId);

export const saveLanguage = async (language, versionId) =>
  genericGqlMutation(
    "preferredLanguage",
    "primary language",
    language,
    versionId
  );

export const saveEmergencyContact = async (emergencyContact, user) => {
  if (!validatePhone(emergencyContact.phone)) {
    throw new Error("Phone number entered is not valid");
  }
  const hasContact = user.emergencyContact && user.emergencyContact.id;
  const idParam = hasContact ? `, id: ${String(user.emergencyContact.id)}` : "";
  const newPhoneNumber = emergencyContact.phone.replace(/-/g, "");
  const saveError = "Failed to save emergency contact";
  const client = await gqlClient();
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation SaveEmergencyContact{
          upsertEmergencyContact(relationship: "${emergencyContact.relationship}", primaryMobilePhone: "${newPhoneNumber}", fullName: "${emergencyContact.name}"${idParam}) {
            emergencyContact {
                id
                relationship
                primaryMobilePhone
                fullName
            }
          }
        }
      `,
      update(cache, { data }) {
        // this mutation doesn't have access to the virtaId so we update manually
        // if the mutation is updated on the server-side to return a virtaId, this update can be deleted
        cache.writeQuery({
          query: gql`
            query RetrieveEmergencyContact {
              patientUser {
                emergencyContact
              }
            }
          `,
          data: {
            patientUser: {
              emergencyContact: data.upsertEmergencyContact.emergencyContact,
            },
          },
        });
      },
    });
    if (result.data.upsertEmergencyContact) {
      return mappedUserProfile(result.data.upsertEmergencyContact);
    }

    throw new Error(saveError);
  } catch (error) {
    const err = error.error || error.message || saveError;
    throw new Error(err);
  }
};

export function validatePhone(phone) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  let phoneNumber;

  try {
    // Change when we go international
    phoneNumber = phoneUtil.parseAndKeepRawInput(phone, "US");
  } catch (NumberParseException) {
    return false;
  }

  return phoneUtil.isPossibleNumber(phoneNumber);
}

const api = {
  retrieveUserProfile,
  uploadPhotoRequest,
  retrievePhotoRequest,
  saveUserGoal,
  savePhone,
  saveNickname,
  saveTimezone,
  saveEmergencyContact,
  saveLanguage,
};

export default api;
