import {
  SET_ASSISTANT_OPEN,
  SET_ASSISTANT_SEARCH_TERM,
} from "../constants/ActionTypes";

export const setAssistantOpen = (isOpen: boolean) => (dispatch: any) => {
  dispatch({ type: SET_ASSISTANT_OPEN, isOpen: isOpen });
};

export const setAssistantSearchTerm =
  (searchTerm: string) => (dispatch: any) => {
    dispatch({ type: SET_ASSISTANT_SEARCH_TERM, searchTerm: searchTerm });
  };

const actions = { setAssistantOpen, setAssistantSearchTerm };

export default actions;
