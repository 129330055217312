import { Reducer } from "redux";

import {
  RECEIVE_GOAL_PROGRESS,
  SET_PHASE_MODAL_START_STATE,
  TOGGLE_PHASE_MODAL,
} from "../constants/ActionTypes";

import api from "./api";
import { GoalProgress } from "./types";

interface ReceiveGoalProgress {
  type: typeof RECEIVE_GOAL_PROGRESS;
  goalProgress: GoalProgress;
}

type GoalProgressActions = ReceiveGoalProgress;

export const goalProgress: Reducer<object, GoalProgressActions> = (
  state = {},
  action
) => {
  switch (action.type) {
    case RECEIVE_GOAL_PROGRESS:
      return action.goalProgress;
    default:
      return state;
  }
};

interface SetModalStartState {
  type: typeof SET_PHASE_MODAL_START_STATE;
  phaseModalState: boolean;
}

interface TogglePhaseModal {
  type: typeof TOGGLE_PHASE_MODAL;
}

type PhaseModalStateActions = SetModalStartState | TogglePhaseModal;

export const phaseModalState: Reducer<boolean, PhaseModalStateActions> = (
  state = false,
  action
) => {
  switch (action.type) {
    case SET_PHASE_MODAL_START_STATE:
      return action.phaseModalState;
    case TOGGLE_PHASE_MODAL:
      if (state) {
        api.patchPhaseModalSeen();
      }
      return !state;
    default:
      return state;
  }
};
