import React from "react";

import styles from "./styles.css";

interface Props {
  children: React.ReactNode;
}

/**
 * @deprecated Please use HeadingText from '@virtahealth/design-components'
 */
const SectionTitle: React.FC<Props> = ({ children }: Props) => (
  <div className={styles.sectionTitle}>{children}</div>
);

export default SectionTitle;
