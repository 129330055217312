import { find } from "lodash";

import {
  Achievement,
  BiomarkerFeedback,
  Reflection,
  StaticResponse,
} from "../biomarker_feedback_types";
import {
  GET_KETONES_FEEDBACK_FAILURE,
  GET_KETONES_FEEDBACK_REQUEST,
  GET_KETONES_FEEDBACK_SUCCESS,
  SUBMIT_KETONES_FAILURE,
  SUBMIT_KETONES_REQUEST,
  SUBMIT_KETONES_SUCCESS,
} from "../constants/ActionTypes";

export interface KetonesFormState {
  reflection: Reflection | null;
  achievement: Achievement | null;
  staticResponse: StaticResponse | null;
  serverError?: string;
  isFetching: boolean;
}

const initialState = {
  reflection: null,
  achievement: null,
  staticResponse: null,
  isFetching: false,
};

export const ketonesForm = (
  state = initialState,
  action: any
): KetonesFormState => {
  switch (action.type) {
    case GET_KETONES_FEEDBACK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_KETONES_FEEDBACK_SUCCESS:
      return {
        ...state,
        reflection: parseReflectionFeedback(action.feedback),
        achievement: parseAchievementFeedback(action.feedback),
        staticResponse: parseStaticResponseFeedback(action.feedback),
        isFetching: false,
      };
    case GET_KETONES_FEEDBACK_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case SUBMIT_KETONES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SUBMIT_KETONES_SUCCESS:
      return {
        ...state,
        achievement: parseAchievementFeedback(action.feedback),
        staticResponse: parseStaticResponseFeedback(action.feedback),
        isFetching: false,
      };
    case SUBMIT_KETONES_FAILURE:
      return {
        ...state,
        serverError: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

function parseReflectionFeedback(feedback: BiomarkerFeedback[]): any {
  return find(feedback, (f) => f.type === "reflection");
}

function parseAchievementFeedback(feedback: BiomarkerFeedback[]): any {
  return find(feedback, (f) => f.type === "achievement");
}

function parseStaticResponseFeedback(feedback: BiomarkerFeedback[]): any {
  return find(feedback, (f) => f.type === "static_response");
}
