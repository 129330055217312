import PropTypes from "prop-types";
import React from "react";

import { AjaxButton, ButtonWithUrl } from "../../../common-components/Button";
import { logTaskClickEvent } from "../../../lib/analytics";
import store from "../../../store";
import { completeTask } from "../../../task_cards/actions";
import Card from "../Card";

const CommunicateTaskCard = ({ task }) => {
  const {
    primary_cta_url,
    primary_cta_label,
    secondary_cta_label,
    id,
    header,
    title,
    type,
  } = task;

  const primaryButton = (
    <ButtonWithUrl
      name={title}
      clickThroughUrl={primary_cta_url}
      style="PrimaryBtnLight"
      onClick={() => {
        logTaskClickEvent(
          {
            taskName: title,
            taskText: primary_cta_label,
            taskType: type,
          },
          {
            buttonCategory: "CTA",
            ctaUrl: primary_cta_url,
            phaseTaskCardType: type,
            phaseContentSequenceId: id,
          }
        );
      }}
    >
      {primary_cta_label}
    </ButtonWithUrl>
  );
  const dispatchCompleteTask = (errorHandler) =>
    store.dispatch(completeTask(id, errorHandler));
  const secondaryButton = (
    <AjaxButton
      name={title}
      ajaxAction={(togglePendingState) => {
        dispatchCompleteTask(togglePendingState);
        logTaskClickEvent(
          {
            taskName: title,
            taskText: secondary_cta_label,
            taskType: type,
          },
          {
            buttonCategory: "progression",
            ctaUrl: null,
            phaseTaskCardType: type,
            phaseContentSequenceId: id,
          }
        );
      }}
      style="SecondaryBtnLight"
    >
      {secondary_cta_label}
    </AjaxButton>
  );
  return (
    <Card
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      type="FoodPic"
      header={header}
      name={title}
    />
  );
};

CommunicateTaskCard.propTypes = {
  task: PropTypes.object.isRequired,
};

export default CommunicateTaskCard;
