export const MAP_NAME_TO_LABEL = {
  "notification:community:like": "Likes on my posts and comments",
  "notification:community:comment": "Comments on my post",
  "notification:community:activity": "Weekly update on new activity",
  "notification:community:peer_group_post": "New post in my peer group",
  "action-list:reminders": "Reminders to complete my action list",
};

export const SUBMISSION_STATUS = {
  SUCCESS: "Saved.",
  FAILURE: "Failed to save.",
  REQUEST: "Saving...",
};
