import PropTypes from "prop-types";
import React from "react";

import Card from "../Card";

const ZeroButtonActionTaskCard = ({ task }) => (
  <Card
    testId="ZeroButtonActionTaskCard"
    type={task.icon}
    header={task.header}
    name={task.title}
  />
);

ZeroButtonActionTaskCard.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ZeroButtonActionTaskCard;
