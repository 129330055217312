import React from "react";

import {
  intlFormatMessage,
  messages as intlMessages,
} from "@virtahealth/utils";

import { Reflection, StaticResponse } from "../../biomarker_feedback_types";
import { Button } from "../../common-components/Button";
import PAModal, {
  PAModalBody,
  PAModalFooter,
} from "../../common-components/PAModal/";
import submitReflection from "../actions";
import formatContent from "../formatting";

const REFLECTION_MODAL_WIDTH_MAX = 500;

interface AnswerOption {
  label: string;
}

interface Props {
  isOpen: boolean;
  reflection: Reflection;
  responseModalCallback: (response: Reflection | StaticResponse) => void;
  closeFn: () => void;
}

const ReflectionModal = ({
  isOpen,
  reflection,
  responseModalCallback,
  closeFn,
}: Props) => {
  const { biomarker, biomarker_context, content, key } = reflection;
  const { question, answer_options } = content[0];

  const renderAnswerOptions = (
    options: AnswerOption[]
  ): Array<React.ReactElement<any>> =>
    options.map((option) => {
      const answers: any = {};
      answers[question] = option.label.toLowerCase();

      return (
        <Button
          key={option.label}
          style={"SecondaryBtn"}
          onClick={() =>
            submitReflection(
              {
                title: " ",
                theme: key,
                biomarker,
                biomarker_context,
                answers,
              },
              responseModalCallback
            )
          }
        >
          {option.label}
        </Button>
      );
    });

  return (
    <PAModal
      contentLabel={intlFormatMessage(
        intlMessages.reflectionAccessibilityMessages
      )}
      isOpen={isOpen}
      isDismissable={false}
      style={{ content: { maxWidth: REFLECTION_MODAL_WIDTH_MAX } }}
      requestCloseFn={closeFn}
    >
      <PAModalBody style={{ fontWeight: 600 }}>
        {formatContent(question)}
      </PAModalBody>
      <PAModalFooter>{renderAnswerOptions(answer_options)}</PAModalFooter>
    </PAModal>
  );
};

export default ReflectionModal;
