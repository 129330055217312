import { isEqual, omit } from "lodash";
import { combineReducers } from "redux";

import {
  TOAST_NOTIFICATION_DISMISSED,
  TOAST_NOTIFICATION_TRIGGERED,
} from "../../../constants/ActionTypes";

import { Action, ToastNotification } from "./types";

export interface ToastNotificationsByIdMap {
  [key: string]: ToastNotification;
}

export const byIdInitialState: ToastNotificationsByIdMap = {};
export const byId = (state = byIdInitialState, action: Action) => {
  switch (action.type) {
    case TOAST_NOTIFICATION_TRIGGERED:
      return { ...state, ...action.payload.entities.toastNotifications };
    case TOAST_NOTIFICATION_DISMISSED:
      return omit(state, action.notificationId);
    default:
      return state;
  }
};

export const allIdsInitialState: Array<ToastNotification["notificationId"]> =
  [];
export const allIds = (state = allIdsInitialState, action: Action) => {
  switch (action.type) {
    case TOAST_NOTIFICATION_TRIGGERED:
      return [...state, action.payload.result];
    case TOAST_NOTIFICATION_DISMISSED:
      return state.filter((value) => !isEqual(action.notificationId, value));
    default:
      return state;
  }
};

const reducer = combineReducers({
  byId,
  allIds,
});

export type ToastNotificationsReduxState = ReturnType<typeof reducer>;
export default reducer;
