import React from "react";

/**
 * @deprecated Please use ErrorText from '@virtahealth/design-components'
 */
const ErrorMessage = (props: { message: string }) => (
  <span className="invalid">{props.message}</span>
);

export default ErrorMessage;
