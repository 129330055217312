import React from "react";

import Icon from "../../../common-components/Icon/components";
import { logEventDropletBiomarkerClick } from "../../../lib/analytics";

import { AddBiomarkerType } from "./constants";

interface Props {
  type: AddBiomarkerType;
  url: string;
  label: string;
  action?: string;
}

const AddMenuItem: React.FC<Props> = ({
  type,
  url,
  label,
  action,
}: Props): React.ReactElement<any> => (
  <li>
    <a
      className="add-menu-item"
      href={url}
      data-action={action}
      onClick={() => {
        logEventDropletBiomarkerClick(url, type);
      }}
    >
      <div className={`add-menu-icon-container bg-gradient-${type}`}>
        <Icon name={type} />
      </div>
      <span>{label}</span>
    </a>
  </li>
);

export default AddMenuItem;
