import { ApolloClient } from "@apollo/client";
import React from "react";
import { Provider, connect } from "react-redux";

import { AlgoliaClient, VirtaContext } from "@virtahealth/components";
import { base } from "@virtahealth/styles";

import gqlClient from "../gqlClient";
import { UserProperties, identifyUser } from "../lib/analytics";
import { fetchUserProfile } from "../profile/actions";
import { UserProfile } from "../profile/reducers";
import store from "../store";
import { RootState } from "../types";
import { selectUserPropertiesForAnalytics } from "../user/selectors";
import { AnalyticsClient } from "../utils/analytics_client";
import { VirtaLaunchDarklyClient } from "../utils/ld_client";
import { PAClient } from "../utils/pa_client";

interface DispatchProps {
  fetchProfile: () => void;
}

interface StateProps {
  userProfile: UserProfile;
  analyticsUserProperties: UserProperties;
}

interface DefaultProps {
  children: React.ReactNode;
}

export type Props = StateProps & DispatchProps & DefaultProps;

const VirtaContextWrapperComponent: React.FC<Props> = ({
  userProfile,
  fetchProfile,
  analyticsUserProperties,
  children,
}: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [gqlClientActual, setGqlClientActual] =
    React.useState<ApolloClient<any>>();

  // setup launch darkly and fetch user language/etc
  React.useEffect(() => {
    const asyncFun = async () => {
      await VirtaLaunchDarklyClient.setup(
        window.env?.appEnvironment,
        window.virta?.user?.virta_id
      );

      await VirtaLaunchDarklyClient.identify(window.virta?.user?.virta_id, {
        care_plan: analyticsUserProperties?.plan_definition,
        participant_subtype: analyticsUserProperties?.participant_subtype,
        mobile_app_version: "pa-web",
      });

      fetchProfile();
    };
    asyncFun();
  }, []);

  // setup gql
  React.useEffect(() => {
    const asyncEffect = async () => {
      const initializedGqlClient = await gqlClient();
      if (initializedGqlClient) {
        setGqlClientActual(initializedGqlClient);
      }
    };
    asyncEffect();
  }, []);

  // determine language before rendering
  React.useEffect(() => {
    if (userProfile.preferredLanguage && loading) {
      identifyUser(analyticsUserProperties);
      setLoading(false);
    }
  }, [userProfile.preferredLanguage]);

  // after 3 seconds, force screen to render with default language
  React.useEffect(() => {
    setTimeout(() => {
      if (loading) {
        identifyUser(analyticsUserProperties);
        setLoading(false);
      }
    }, 3000);
  }, []);

  return loading ? null : (
    <VirtaContext
      theme={base}
      client={new PAClient()}
      analyticsClient={new AnalyticsClient()}
      algoliaClient={new AlgoliaClient(window.env?.appEnvironment)}
      gqlClient={gqlClientActual}
      launchDarkly={VirtaLaunchDarklyClient}
      locale={userProfile.preferredLanguage}
      virtaId={window.virta?.user?.virta_id}
    >
      {children}
    </VirtaContext>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchProfile: () => {
    dispatch(fetchUserProfile());
  },
});

const mapStateToProps = (state: RootState) => ({
  userProfile: state.userProfile,
  analyticsUserProperties: selectUserPropertiesForAnalytics(state),
});

const VirtaContextWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtaContextWrapperComponent);

export default function VirtaContextWrapperFunction({
  children,
}: DefaultProps) {
  return (
    <Provider store={store}>
      <VirtaContextWrapper>{children}</VirtaContextWrapper>
    </Provider>
  );
}
