import React from "react";

import styles from "./styles.css";

interface Props {
  testId?: string;
  header: string;
  inlineStyles?: object;
  name: string;
  primaryButton: React.ReactNode;
  secondaryButton: React.ReactNode;
  type: string;
  backgroundImageSrc: string;
}

const Card: React.FC<Props> = ({
  testId,
  header,
  inlineStyles,
  name,
  type,
  primaryButton,
  secondaryButton,
  backgroundImageSrc,
}: Props) => (
  <div data-testid={testId} className={styles[type]} style={inlineStyles}>
    <div className={styles.cardContent}>
      <div className={styles.cardType}>{header}</div>
      <div className={styles.cardName}>{name}</div>
      <div className={styles.btnContainer}>
        {primaryButton}
        {secondaryButton}
      </div>
    </div>
    {backgroundImageSrc && (
      <div
        className={styles.cardBackgroundImage}
        style={{ backgroundImage: `url(${backgroundImageSrc})` }}
      />
    )}
  </div>
);

export default Card;
