import { assign, concat, keys, reduce, sortBy, toPairs } from "lodash";
import moment from "moment";

import {
  RECEIVE_MINI_CARDS,
  RECEIVE_NEXT_HISTORY_URL,
} from "../constants/ActionTypes";

import { historyUrl } from "./urls";

const reducer = (memo, miniCardsPerDate) => {
  const [date, miniCards] = toPairs(miniCardsPerDate)[0];
  return assign({}, memo, { [date]: miniCards });
};

const toMap = (arr) => reduce(arr, reducer, {});
const toArrayOfMaps = (dict) =>
  reduce(dict, (acc, val, key) => concat(acc, { [key]: val }), []);

const mergeAndSort = (prev, current) => {
  const merged = assign({}, toMap(prev), toMap(current));
  const newMinicards = toArrayOfMaps(merged);
  return sortBy(newMinicards, (card) => -1 * moment(keys(card)[0]));
};

export const groupedMiniCards = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_MINI_CARDS:
      return mergeAndSort(state, action.groupedMiniCards);
    default:
      return state;
  }
};

export const nextUrl = (state = historyUrl, action) => {
  switch (action.type) {
    case RECEIVE_NEXT_HISTORY_URL:
      return action.nextUrl;
    default:
      return state;
  }
};
