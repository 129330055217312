import PropTypes from "prop-types";
import React from "react";

import BasicActionTaskCard from "../BasicActionTaskCard";
import CommunicateTaskCard from "../CommunicateTaskCard";
import OneButtonActionTaskCard from "../OneButtonActionTaskCard";
import TwoButtonActionTaskCard from "../TwoButtonActionTaskCard";
import ZeroButtonActionTaskCard from "../ZeroButtonActionTaskCard";

const ActionTaskCard = function ({ task }) {
  switch (task.category) {
    case "communicate":
    case "communicate_plan":
    case "measure":
    case "measure_ketones":
    case "measure_glucose":
      return <TwoButtonActionTaskCard task={task} />;
    case "schedule_onboarding":
    case "external_link":
      return <TwoButtonActionTaskCard task={task} target="_blank" />;
    case "start_dietary_changes":
    case "complete_phase_content":
    case "physical_activity":
      return <OneButtonActionTaskCard task={task} />;
    case "wait":
      return <ZeroButtonActionTaskCard task={task} />;

    // TODO: Adam Ryan 2/8/17: Can remove after we remove VAT
    case "food_description_action":
      return <CommunicateTaskCard task={task} />;
    default:
      return <BasicActionTaskCard task={task} />;
  }
};

ActionTaskCard.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ActionTaskCard;
