import { reject } from "lodash";
import React from "react";
import { connect } from "react-redux";

import { GoalProgress } from "../../../goal_progress/types";
import {
  getCurrentPlanDefinitionName,
  isOnReversal,
} from "../../../helpers/planDefinition";
import { METRIC_TASK_TYPES_LIST } from "../../constants";
import { BiomarkerTask, TaskCardTask } from "../../types";
import CardFromTask from "../CardFromTask";
import CardScrollView from "../CardScrollView";

interface OwnProps {
  tasks: TaskCardTask[];
  goalProgress: GoalProgress;
}

interface StateProps {
  tasks: TaskCardTask[];
  biomarkerTasks: BiomarkerTask[];
  goalProgress: GoalProgress;
}

export const TaskCardsComponent: React.FC<OwnProps> = ({
  tasks,
  goalProgress,
}: OwnProps) => {
  const planDefinition = getCurrentPlanDefinitionName();
  const isRepeatingCategory = (task: TaskCardTask) =>
    METRIC_TASK_TYPES_LIST.indexOf(task.type) !== -1;
  const goalTasks = reject(tasks, isRepeatingCategory);

  const noTasks = goalTasks.length === 0;
  const goalComplete = goalProgress.completionState !== null;

  return (
    <div data-testid="task-cards">
      {noTasks || goalComplete || !isOnReversal(planDefinition) ? null : (
        <CardScrollView>
          {goalTasks.map((task) => (
            <CardFromTask task={task} key={task.id} />
          ))}
        </CardScrollView>
      )}
    </div>
  );
};

const mapStateToProps = ({ tasks, goalProgress }: StateProps) => ({
  tasks,
  goalProgress,
});
export default connect(mapStateToProps)(TaskCardsComponent);
