import { normalize, schema } from "normalizr";

import { ToastNotification } from "./types";

export const toastNotification = new schema.Entity<ToastNotification>(
  "toastNotifications",
  {},
  {
    idAttribute: "notificationId",
  }
);

export const toastNotifications = [toastNotification];

export const normalizeToastNotification = (notification: ToastNotification) =>
  normalize<ToastNotification>(notification, toastNotification);

export const normalizeToastNotifications = (
  notifications: ToastNotification[]
) =>
  normalize<ToastNotification>(
    notifications,
    new schema.Array(toastNotification)
  );
