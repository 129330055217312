import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { ICON_CLASS_FOR_MINI_CARD_TYPE } from "../../constants";
import MiniCard from "../MiniCard";

const _miniCardComponent = (miniCardInfo, index) => (
  <MiniCard
    key={index}
    iconClass={ICON_CLASS_FOR_MINI_CARD_TYPE[miniCardInfo.type]}
    name={miniCardInfo.name}
    time={miniCardInfo.time}
    value={miniCardInfo.value}
    verbForActivity={miniCardInfo.verbForActivity}
    unit={miniCardInfo.unit}
  />
);

const MiniCardList = ({ miniCardList }) => (
  <div data-testid="MiniCardList">{map(miniCardList, _miniCardComponent)}</div>
);

MiniCardList.propTypes = {
  miniCardList: PropTypes.array.isRequired,
};

export default MiniCardList;
