import cx from "classnames";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { DateTimePicker } from "react-widgets";
import momentLocalizer from "react-widgets-moment";

import { intlFormatMessage, intlLocale, messages } from "@virtahealth/utils";

import { isMobile } from "../../virta_user_agent";

type mobileOnChange = (value: React.SyntheticEvent<HTMLInputElement>) => void;
type desktopOnChange = (
  date?: Date | undefined,
  dateStr?: string | undefined
) => void;

interface Props {
  onChange: mobileOnChange & desktopOnChange;
  onSelect?: () => void;
  additionalStyle?: string | null;
}

const VirtaDatePicker: React.FC<Props> = ({
  onChange,
  onSelect,
  additionalStyle,
}: Props) => {
  moment.locale(intlLocale());
  momentLocalizer();

  // Mobile-friendly date-time picker
  if (isMobile(window.navigator.userAgent)) {
    return (
      <div className={cx("datepicker-container", additionalStyle)}>
        <input
          data-testid="virta-date-picker"
          className="w100p ta-center"
          type="datetime-local"
          defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
          max={moment().format("YYYY-MM-DDTHH:mm")}
          step={15}
          onChange={onChange}
          required={true}
        />
      </div>
    );
  }

  // Desktop-friendly date-time picker
  return (
    <DateTimePicker
      data-testid="virta-date-picker"
      format="MMM D, YYYY &mdash; h:mm a"
      editFormat="M/D/YY h:mm a"
      finalView="year"
      defaultValue={new Date()}
      max={new Date()}
      step={15}
      duration={100}
      onChange={onChange}
      onSelect={onSelect}
      time={true}
      messages={{
        dateButton: intlFormatMessage(messages.selectDate),
        timeButton: intlFormatMessage(messages.selectTime),
      }}
    />
  );
};

export default VirtaDatePicker;
