import {
  RECEIVE_USER_PROFILE,
  RECEIVE_USER_PROFILE_FRESH,
  SETTLED_USER_PROFILE,
  UPDATING_USER_PROFILE,
} from "../constants/ActionTypes";

export interface UserProfile {
  id: number;
  fullName: string;
  phone: string;
  photoUrl: string;
  userGoal: string;
  daysOnVirta: string;
  nickname: string;
  email: string;
  emergencyContact: {
    relationship: string;
    phone: string;
    name: string;
    id: number;
  };
  timezone: string;
  preferredLanguage: string;
  versionId: string;
  billableCondition: string;
  updating: boolean;
}

export const userProfile = (state = {}, action: any) => {
  switch (action.type) {
    case RECEIVE_USER_PROFILE:
      return {
        ...state,
        ...action.userProfile,
      };
    case RECEIVE_USER_PROFILE_FRESH:
      return action.userProfile;
    case UPDATING_USER_PROFILE:
      return {
        ...state,
        updating: true,
      };
    case SETTLED_USER_PROFILE:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
};
