import { NormalizedCacheObject } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { debounce } from "lodash";
import React from "react";
import { Linking, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";

import { VirtaAnalyticsClient, withVirta } from "@virtahealth/components";
import { Box, Stack } from "@virtahealth/design-layout";
import {
  VirtaAssistantContent,
  VirtaAssistantSearchInput,
} from "@virtahealth/experiences";
import { GraphQLClient, messages } from "@virtahealth/utils";

import { clientStorePA } from "../../../clientStore";
import { RootState } from "../../../types";
import { selectAssistantSearchTerm } from "../../selectors";

const ENABLE_AUTO_SEARCH = false;
const MAX_WIDTH = 650;

export type ContentType =
  | "article"
  | "recipe"
  | "thirdPartyRecipe"
  | "podcast"
  | "video"
  | "restaurant"
  | "grocery"
  | "food";

interface InnerProps {
  analyticsClient: VirtaAnalyticsClient;
  gqlClient: GraphQLClient<NormalizedCacheObject>;
  parentComponentName?: string;
  virtaId?: string;
  cardFeedbackEnabled: boolean;
  communityCommentsEnabled: boolean;
  chatMessagesEnabled: boolean;
}

export const InnerVirtaAssistantSearchBar: React.FC<InnerProps> = ({
  analyticsClient,
  gqlClient,
  parentComponentName,
  virtaId,
  cardFeedbackEnabled,
  communityCommentsEnabled,
  chatMessagesEnabled,
}) => {
  const [showResults, setShowResults] = React.useState<boolean>(true);
  const [inputVal, setInputVal] = React.useState<string>("");
  const [debouncedInputVal, setDebouncedInputVal] = React.useState<string>("");
  const initialSearchTerm = useSelector((state: RootState) =>
    selectAssistantSearchTerm(state)
  );

  React.useEffect(() => {
    if (initialSearchTerm) {
      setInputVal(initialSearchTerm);
      setDebouncedInputVal(initialSearchTerm);
    }
  }, []);

  const handleOpenContent = (
    contentId: string,
    mode?: string,
    title?: string
  ) => {
    if (mode && ["food", "grocery", "restaurant"].includes(mode)) {
      let url = `/discover/food?foodItemId=${contentId}`;
      if (title?.length) {
        url += `&foodItemName=${encodeURIComponent(title)}`;
      }
      Linking.openURL(url);
    } else if (mode === "thirdPartyRecipe") {
      let url = `/discover/recipe?recipeItemId=${contentId}`;
      if (title?.length) {
        url += `&recipeItemName=${encodeURIComponent(title)}`;
      }
      Linking.openURL(url);
    } else if (mode === "communityPost") {
      if (contentId.includes("#")) {
        const split = contentId.split("#");
        if (communityCommentsEnabled) {
          Linking.openURL(
            `/community/#/post/${split[0]}?newTab=true&commentId=${split[1]}`
          );
        } else {
          Linking.openURL(`/community/#/post/${split[0]}?newTab=true`);
        }
      } else {
        Linking.openURL(`/community/#/post/${contentId}?newTab=true`);
      }
    } else if (mode === "chatMessage") {
      Linking.openURL(`/chat?messageId=${contentId}`);
    } else {
      Linking.openURL(`/discover/content/${contentId}`);
    }
  };
  const { width: deviceWidth } = useWindowDimensions();

  const debounceRequest = debounce((value) => {
    setDebouncedInputVal(value);
  }, 2000);
  const requestCallback = React.useCallback(
    (search) => debounceRequest(search),
    []
  );

  const handleInputSearchChange = (search: string) => {
    if (ENABLE_AUTO_SEARCH) {
      debounceRequest.cancel();
      setInputVal(search);
      requestCallback(search);
      setShowResults(true);
      if (search.trim() === "") {
        setDebouncedInputVal("");
      } else {
        requestCallback(search);
      }
    } else {
      if (search.trim() === "") {
        setDebouncedInputVal("");
      }
      setInputVal(search);
    }
  };

  const handleSubmit = () => {
    if (!ENABLE_AUTO_SEARCH) {
      setDebouncedInputVal(inputVal);
    }
  };

  const handleContentSearchClick = (search: string) => {
    setDebouncedInputVal(search);
    setInputVal(search);
  };

  return (
    <>
      <Box minWidth={Math.min(deviceWidth - 100, MAX_WIDTH)}>
        <div className="va-input">
          <VirtaAssistantSearchInput
            value={inputVal}
            onSearchChange={handleInputSearchChange}
            onSubmit={handleSubmit}
            placeholderMessage={messages.virtaAssistantSearchPlaceholder}
            autoFocus={true}
            isCompact={false}
            isSearching={false}
            parentComponentName={parentComponentName}
            variant="minimal"
          />
        </div>
      </Box>
      {showResults && (
        <Stack
          backgroundColor="white"
          position="absolute"
          top={60}
          left={0}
          right={0}
          paddingVertical={2}
          height="96vh"
          align="center"
          overflow="scroll"
        >
          <Box maxWidth={MAX_WIDTH}>
            <div className="va-results">
              <VirtaAssistantContent
                searchTerm={debouncedInputVal}
                handleOpenContent={handleOpenContent}
                handleSearch={handleContentSearchClick}
                analyticsClient={analyticsClient}
                gqlClient={gqlClient}
                parentComponentName={parentComponentName}
                virtaId={virtaId}
                virtaClientStore={clientStorePA(virtaId!)}
                captureException={(error: Error) =>
                  Sentry.captureException(error)
                }
                cardFeedbackEnabled={cardFeedbackEnabled}
                chatMessagesEnabled={chatMessagesEnabled}
              />
            </div>
          </Box>
        </Stack>
      )}
    </>
  );
};

export const VirtaAssistantSearchBar = withVirta<InnerProps>(
  InnerVirtaAssistantSearchBar
);
