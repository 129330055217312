import classNames from "classnames/bind";
import React from "react";

import styles from "./styles.css";

const cx = classNames.bind(styles);

interface Props {
  name:
    | "BloodPressure"
    | "BloodPressureColor"
    | "CheckBadge"
    | "CheckBadgeColor"
    | "Clock"
    | "ClockColor"
    | "Close"
    | "CloseColor"
    | "Communicate"
    | "CommunicateColor"
    | "DashedCheckmark"
    | "DashedCheckmarkColor"
    | "DietaryChanges"
    | "DietaryChangesColor"
    | "DisclosureArrowLeft"
    | "DisclosureArrowLeftColor"
    | "DisclosureArrowRight"
    | "DisclosureArrowRightColor"
    | "EllipsisHorizontal"
    | "EllipsisHorizontalColor"
    | "FoodEnvironment"
    | "FoodEnvironmentColor"
    | "FoodPic"
    | "FoodPicColor"
    | "Glucose"
    | "GlucoseColor"
    | "Heart"
    | "HeartColor"
    | "HeartDisabled"
    | "Help"
    | "HelpColor"
    | "KetonesColor"
    | "Ketone-Measure"
    | "LogoMark"
    | "LogoMarkColor"
    | "Padlock"
    | "PadlockColor"
    | "Play"
    | "PlayColor"
    | "Results"
    | "ResultsColor"
    | "RotateLeft"
    | "RotateRight"
    | "SpeechBubble"
    | "SpeechBubbleColor"
    | "Symptoms"
    | "SymptomsColor"
    | "Video"
    | "VideoColor"
    | "Wait"
    | "Weight"
    | "WeightColor"
    | string; // TODO: Remove this line and fix typings.
  style?: "light" | "dark" | "Color";
  additionalStyles?: string;
  size?: string | number;
}

/**
 * @deprecated Please use Icon from '@virtahealth/design-icons
 */
const Icon: React.FC<Props> = ({
  size,
  style,
  name,
  additionalStyles,
}: Props) => {
  const iconName = !style || style === "light" ? name : `${name}Color`;

  return (
    <i
      className={cx(styles[iconName], additionalStyles)}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};

export default Icon;
