import {
  RECEIVE_USER_PROFILE,
  RECEIVE_USER_PROFILE_FRESH,
  SETTLED_USER_PROFILE,
  UPDATING_USER_PROFILE,
} from "../constants/ActionTypes";

import api from "./api";

const isGqlOutOfSyncError = (err) =>
  err ===
  "There was a problem with the request: StatusCode.FAILED_PRECONDITION";
const cleanGqlOutofSyncError =
  "We ran into a problem saving your profile changes. Please try again or exit and reload the page.";

export const fetchUserProfile =
  (clearCache = false) =>
  (dispatch) => {
    dispatch({ type: UPDATING_USER_PROFILE });
    api
      .retrieveUserProfile(clearCache)
      .then((userProfile) => {
        dispatch({ type: RECEIVE_USER_PROFILE_FRESH, userProfile });
      })
      .finally(() => {
        dispatch({ type: SETTLED_USER_PROFILE });
      });
  };

export const uploadPhoto = (file, csrfToken) => async (dispatch) =>
  api.uploadPhotoRequest(file, csrfToken).then((response) => {
    if (response.status === 201) {
      response.json().then((json) =>
        api.retrievePhotoRequest(json.url).then(() =>
          dispatch({
            type: RECEIVE_USER_PROFILE,
            userProfile: { photoUrl: json["url"] },
          })
        )
      );
    }
  });

const gqlEditWrapper = (promise, handleError, dispatch) => {
  let retrying = false;
  dispatch({ type: UPDATING_USER_PROFILE });
  promise
    .then((userProfile) =>
      dispatch({ type: RECEIVE_USER_PROFILE, userProfile })
    )
    .catch((err) => {
      let errorMessage = err.message;
      // if our profile's versionId is out of sync, show a specific error to the user
      // telling them to try again while we re-sync it
      if (isGqlOutOfSyncError(errorMessage)) {
        errorMessage = cleanGqlOutofSyncError;
        dispatch(fetchUserProfile(true));
        retrying = true;
      }
      handleError(errorMessage);
    })
    .finally(() => {
      if (!retrying) {
        dispatch({ type: SETTLED_USER_PROFILE });
      }
    });
};

export const editPhone = (phone, versionId, handleError) => (dispatch) => {
  gqlEditWrapper(api.savePhone(phone, versionId), handleError, dispatch);
};

export const editUserGoal =
  (userGoal, versionId, handleError) => (dispatch) => {
    gqlEditWrapper(
      api.saveUserGoal(userGoal, versionId),
      handleError,
      dispatch
    );
  };

export const editNickname =
  (nickname, versionId, handleError) => (dispatch) => {
    gqlEditWrapper(
      api.saveNickname(nickname, versionId),
      handleError,
      dispatch
    );
  };

export const editTimezone =
  (timezone, versionId, handleError) => (dispatch) => {
    gqlEditWrapper(
      api.saveTimezone(timezone, versionId),
      handleError,
      dispatch
    );
  };

export const editLanguage =
  (language, versionId, handleError) => (dispatch) => {
    gqlEditWrapper(
      api.saveLanguage(language, versionId),
      handleError,
      dispatch
    );
  };

export const editEmergencyContact =
  (emergencyContact, user, handleError) => (dispatch) => {
    gqlEditWrapper(
      api.saveEmergencyContact(emergencyContact, user),
      handleError,
      dispatch
    );
  };
