import MobileDetect from "mobile-detect";

/**  isMobile returns null if not a mobile device.
 *  if the device is mobile, it returns the name of the device as a string.
 */
export const isMobile = (
  windowUserAgentData: string
): string | undefined | null => new MobileDetect(windowUserAgentData).mobile();

export const isIOs = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).is("iOs");
export const isIPhone = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).is("iPhone");
export const isIPad = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).is("iPad");
export const isAndroid = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).is("AndroidOS");
export const isChromeiOS = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).match("CriOS");
export const isSafari = (windowUserAgentData: string): boolean =>
  new MobileDetect(windowUserAgentData).is("Safari");
export const isIOsWebview = (windowUserAgentData: string): boolean =>
  isIOs(windowUserAgentData) && !isSafari(windowUserAgentData);

const userAgent = {
  isMobile,
  isIOs,
  isIPhone,
  isIPad,
  isChromeiOS,
  isIOsWebview,
};

export default userAgent;
