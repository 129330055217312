import * as Sentry from "@sentry/browser";

import {
  ClickEventProperties,
  ViewEventProperties,
  VirtaAnalyticsClient,
} from "@virtahealth/components";

function logEvent(
  interaction: "Clicked" | "Viewed",
  event: string,
  properties: ClickEventProperties | ViewEventProperties
) {
  if (window.virta.amplitude) {
    window.virta.amplitude
      .getInstance()
      .logEvent(`${interaction} ${event}`, properties);
  } else {
    Sentry.captureException(
      new Error("logEvent: window.virta.amplitude not found")
    );
  }
}

export const logGenericEvent = (
  event: string,
  properties?: Record<string, unknown>
) => {
  if (window.virta.amplitude) {
    window.virta.amplitude.getInstance().logEvent(event, properties);
  } else {
    Sentry.captureException(
      new Error("logGenericEvent: window.virta.amplitude not found"),
      {
        user: { id: window.virta?.user?.virta_id },
      }
    );
  }
};

export interface AmplitudeUserProps {
  deployment?: string;
  diagnosis?: string;
  is_test_user?: boolean;
  gender?: string;
  age_group?: string;
  preferred_language?: string;
}

export const identifyUser = (virta_id: string) => {
  if (window.virta.amplitude) {
    window.virta.amplitude.getInstance().setUserId(virta_id);
  } else {
    Sentry.captureException(
      new Error("identifyUser: window.virta.amplitude not found")
    );
  }
};

export const identifyUserProperties = (properties: AmplitudeUserProps) => {
  if (window.virta.amplitude) {
    window.virta.amplitude.getInstance().setUserProperties(properties);
  } else {
    Sentry.captureException(
      new Error("identifyUserProperties: window.virta.amplitude not found")
    );
  }
};

export class AnalyticsClient implements VirtaAnalyticsClient {
  public logClickEvent(event: string, properties: ClickEventProperties) {
    logEvent("Clicked", event, properties);
  }
  public logViewEvent(event: string, properties: ViewEventProperties) {
    logEvent("Viewed", event, properties);
  }
  public logCustomEvent(event: string, properties?: Record<string, unknown>) {
    logGenericEvent(event, properties);
  }
}
