import React from "react";
import ReactDOM from "react-dom";

import { setupAxeCore } from "../accessibilitySetup";
import VirtaContextWrapper from "../contexts/VirtaContextWrapper";
import NavBar from "../navbar/components/NavBar";

export default NavBar;

const renderNavBar = (): void => {
  // mobile needs a hook to hide the navbar in webviews
  if (window.location.search.includes("usingMobileApp")) {
    return;
  }

  const navBarContainer = document.querySelector("[data-role=navBarContainer]");
  if (navBarContainer) {
    ReactDOM.render(
      <VirtaContextWrapper>
        <NavBar />
      </VirtaContextWrapper>,
      navBarContainer
    );

    setupAxeCore();
  }
};

// @ts-ignore no types for renderNavBar
window.virta = { ...window.virta, renderNavBar };
