import {
  GET_CARE_PLAN_FAILURE,
  GET_CARE_PLAN_REQUEST,
  GET_CARE_PLAN_SUCCESS,
} from "../constants/ActionTypes";

import { CarePlanJSON } from "./reducers";

export function getCarePlanRequest() {
  return {
    type: GET_CARE_PLAN_REQUEST,
  };
}

export function getCarePlanSuccess(data: CarePlanJSON) {
  return {
    type: GET_CARE_PLAN_SUCCESS,
    data,
  };
}

export function getCarePlanFailure(error: any) {
  return {
    type: GET_CARE_PLAN_FAILURE,
    error,
  };
}
