import React from "react";

import { VirtaLaunchDarklyClient } from "../utils/ld_client";

export const initializeFeatureFlagger = (virtaId?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    const setupFeatureFlagger = async () => {
      await VirtaLaunchDarklyClient.setup(window.env?.appEnvironment, virtaId);
    };
    if (!VirtaLaunchDarklyClient.initialized) {
      setupFeatureFlagger();
    }
  }, []);
};
