import * as Sentry from "@sentry/browser";
import { Dispatch } from "redux";

import {
  RECEIVE_GOAL_PROGRESS,
  SET_PHASE_MODAL_START_STATE,
  TOGGLE_PHASE_MODAL,
} from "../constants/ActionTypes";

import api from "./api";
import { GoalProgress } from "./types";

export const fetchGoalProgress = () => (dispatch: Dispatch) => {
  return api
    .retrieveGoalProgress()
    .then((goalProgress: GoalProgress) => {
      dispatch({
        type: RECEIVE_GOAL_PROGRESS,
        goalProgress,
      });
      dispatch({
        type: SET_PHASE_MODAL_START_STATE,
        phaseModalState: !goalProgress.goalViewed,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const togglePhaseModal = () => {
  return { type: TOGGLE_PHASE_MODAL };
};

const actions = { fetchGoalProgress };

export default actions;
