import React from "react";

import {
  ClickableElement,
  getCommonPropertiesFromWindow,
  logClickEvent,
} from "../../lib/analytics";

import styles from "./styles.css";

const WeeklySummaryBanner = () => (
  <a
    data-testid="weekly-summary-banner"
    href="/weekly-summary"
    className={styles.weeklySummaryBanner}
    onClick={() => {
      logClickEvent(
        ClickableElement.WeeklySummaryNotification,
        getCommonPropertiesFromWindow()
      );
    }}
  >
    <span className="bold fs1">Your Weekly Summary is ready!</span>
    <span className={styles.weeklySummaryDescription}>
      See all your progress for glucose, ketones and weight {"▶"}
    </span>
  </a>
);

export default WeeklySummaryBanner;
