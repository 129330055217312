import React from "react";

import Icon from "../../../common-components/Icon/components/index";

import styles from "./styles.css";

const MiniCard = ({
  iconClass = "",
  verbForActivity,
  name,
  time,
  value,
  unit,
}: Props) => (
  <div data-testid="MiniCard" className={styles.MiniCard}>
    <div className={styles.leftCol}>
      <Icon name={iconClass} style="dark" />
    </div>
    <div className={styles.rightCol}>
      <div className={styles.type}>{verbForActivity}</div>
      <span className={styles.name}>{name}</span>
      <span className={styles.inputValue}>{value}</span>
      <span className={styles.inputUnit}>{unit}</span>
    </div>
    <div className={styles.timestamp}>{time}</div>
  </div>
);

interface Props {
  iconClass?: string;
  verbForActivity: string;
  name: string;
  time: string;
  value: string;
  unit: string;
}

export default MiniCard;
