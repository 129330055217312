import React from "react";

import { Button } from "@virtahealth/design-components";
import { intlFormatMessage, messages } from "@virtahealth/utils";

import PAModal, {
  PAModalBody,
  PAModalFooter,
} from "../../../common-components/PAModal/";
import AchievementBadge from "../AchievementBadge/";

import styles from "./styles.css";

interface Props {
  isOpen: boolean;
  achievementKey: string;
  achievementContent: string;
  closeFn: () => void;
  achievementTitle?: string;
}

const UniversalAchievementModal: React.FC<Props> = ({
  isOpen,
  achievementKey,
  achievementContent,
  closeFn,
  achievementTitle,
}: Props) => (
  <PAModal
    testId="universal-achievement-modal"
    isOpen={isOpen}
    contentLabel={intlFormatMessage(messages.achievementsAccessibilityMessages)}
    requestCloseFn={closeFn}
  >
    <PAModalBody>
      <div className={styles.achievementModalBody}>
        <AchievementBadge key={achievementKey} achievement={achievementKey} />
        {achievementTitle && (
          <h2
            data-testid="achievement-title"
            className={styles.achievementTitle}
          >
            {achievementTitle}
          </h2>
        )}
        <p
          data-testid="achievement-content"
          className={styles.achievementContent}
        >
          {achievementContent}
        </p>
      </div>
    </PAModalBody>
    <PAModalFooter>
      <Button
        text={messages.gotIt}
        onPress={closeFn}
        testID="achievement_close"
      />
    </PAModalFooter>
  </PAModal>
);

export default UniversalAchievementModal;
