import charan from "../charan_csrf_wrapper";

import { reflectionUrl } from "./urls";

export const postReflection = (reflectionData) =>
  charan.post(reflectionUrl, reflectionData);

const api = { postReflection };

export default api;
