import { gql } from "@virtahealth/utils";

export const FETCH_REQUEST_SUPPLIES_DATA = gql`
  query FetchRequestSupplies($virtaId: String!) {
    requestSuppliesForm(virtaId: $virtaId) {
      virtaId
      versionId
      nativeSupplyRequestFormEnabled
      firstName
      lastName
      participantType
      participantSubtype
      adaptedOn
      releaseStatus {
        isInRelease
        isUserActive
        status
        releaseDateUtc
      }
      address {
        street1
        street2
        city
        state
        zip
      }
      devices {
        id
        model
        status
        type
      }
      questionnaireId
      currentPlanDefinitionName
    }
  }
`;

export const CREATE_SUPPLIES_REQUEST = gql`
  mutation CreateSuppliesRequest($requestData: CreateSuppliesRequestInput!) {
    createSuppliesRequest(requestData: $requestData) {
      supplyRequestId
    }
  }
`;
