export const RECEIVE_USER_PROFILE = "Receive updates to user profile";
export const RECEIVE_USER_PROFILE_FRESH =
  "Recieve new user profile and cleanup old one";
export const UPDATING_USER_PROFILE = "Updating user profile";
export const SETTLED_USER_PROFILE = "User profile updating complete";
export const RECEIVE_NEXT_HISTORY_URL = "Receive next history url";
export const RECEIVE_MINI_CARDS = "Receive mini cards";
export const RECEIVE_GOAL_PROGRESS = "Receive goal progress";
export const UPDATE_TASKS = "Update tasks";
export const UPDATE_BIOMARKER_TASKS = "Update biomarker tasks";
export const RECEIVE_ALL_VIDEOS = "Get all videos for educational tools";
export const RECEIVE_LATEST_AVAILABLE_VIDEO =
  "Receive latest video for partcipant";
export const RECEIVE_IS_VIDEO_BLOCKED_BY_TASKS =
  "Receive flag to see if video is locked by task";
export const UPDATE_PROFILE_PHOTO_URL = "Update profile photo url";
export const TOGGLE_PHASE_MODAL = "Toggle phase modal";
export const SET_PHASE_MODAL_START_STATE = "Set state of phase modal";
export const RECEIVE_ASSISTANT_ELIGIBLE_MESSAGE =
  "Receive Assistant searchable chat messages";
export const RECEIVE_POSSIBLY_NEW_CHAT_MESSAGES =
  "Receive possibly new chat messages to display";
export const ADD_ASSISTANT_LOADING_CHAT_MESSAGE =
  "Add assistant loading chat message";
export const RECEIVE_WEEKLY_SUMMARY = "Receive weekly report";
export const RECEIVE_USER_GOAL = "Receive user goal";
export const RECEIVE_GRAPH_DATA = "Receive graph data";
export const TOGGLE_GRAPH_TIMEFRAME = "Toggle graph timeframe";
export const SET_ASSISTANT_CHAT_MESSAGE_ID = "Set assistant chat message id";

// Community
export const RECEIVE_COMMUNITY_USER = "Receive community user";
export const RECEIVE_COMMUNITY_USER_ACTIONS = "Receive community user actions";
export const RECEIVE_COMMUNITY_POST = "Receive community post";
export const FETCHING_COMMUNITY_POST = "FETCHING_COMMUNITY_POST";
export const FETCHING_COMMUNITY_POST_SUCCESS = "FETCH_COMMUNITY_POST_SUCCESS";
export const FETCHING_COMMUNITY_POST_FAILURE = "FETCH_COMMUNITY_POST_FAILURE";
export const FETCHING_COMMUNITY_FEED =
  "Waiting for Community Feed API Response";
export const RECEIVE_COMMUNITY_FEED = "Receive community feed";
export const UPDATE_COMMUNITY_FEED = "Update community feed";
export const RECEIVE_NEWLY_PUBLISHED_POST = "Receive newly published post";
export const FETCHING_COMMUNITY_USER = "Waiting for Community User API Request";
export const UPDATING_COMMUNITY_USER =
  "Waiting for Updated Community User API Request";
export const POST_COMMUNITY_POST_MEDIA_REQUEST =
  "Uploading community post media";
export const POST_COMMUNITY_POST_MEDIA_SUCCESS = "Receive uploaded file url";
export const UPDATE_COMMUNITY_POST_MEDIA_REQUEST =
  "Updating community post media";
export const UPDATE_COMMUNITY_POST_MEDIA_SUCCESS =
  "Updated community post media";
export const REMOVE_COMMUNITY_POST_MEDIA = "Remove uploaded file url";
export const REMOVE_ALL_COMMUNITY_POST_MEDIA = "Remove all uploaded media";
export const UPDATE_EXPANDED_POST_ID = "UPDATE_EXPANDED_POST_ID";
export const UPDATE_EXPANDED_POST_POST = "UPDATE_EXPANDED_POST_POST";
export const CLEAR_EXPANDED_POST = "CLEAR_EXPANDED_POST";
export const RECEIVE_COMMUNITY_POST_LIKED_BY = "Get users who liked the post";
export const POST_COMMUNITY_PROFILE_PICTURE_SUCCESS =
  "Uploading profile picture success";
export const POST_COMMUNITY_PROFILE_PICTURE_REQUEST =
  "Uploading profile picture";
export const POST_COMMUNITY_PROFILE_PICTURE_FAILURE =
  "Uploading profile picture failure";
export const UPDATE_COMMUNITY_PROFILE_PICTURE_REQUEST =
  "UPDATE_COMMUNITY_PROFILE_PICTURE_REQUEST";
export const UPDATE_COMMUNITY_PROFILE_PICTURE_SUCCESS =
  "UPDATE_COMMUNITY_PROFILE_PICTURE_SUCCESS";
export const UPDATE_COMMUNITY_PROFILE_PICTURE_FAILURE =
  "UPDATE_COMMUNITY_PROFILE_PICTURE_FAILURE";
export const UPDATE_EDIT_POST_OR_COMMENT_BODY =
  "Update edit post or comment body";
export const UPDATE_EDIT_POST_OR_COMMENT_MEDIA =
  "Update edit post or comment media";
export const DEFAULT_EDIT_POST_OR_COMMENT_STATE =
  "Default edit post or comment state";
export const UPDATE_EDIT_POST_OR_COMMENT =
  "Update edit post or comment or comment";
export const GET_TOPICS_REQUEST = "GET_TOPICS_REQUEST";
export const GET_FEED_FOR_TOPIC = "GET_FEED_FOR_TOPIC";
export const UPDATE_NEXT_PAGE = "UPDATE_NEXT_PAGE";
export const UPDATE_TOPIC_ID = "UPDATE_TOPIC_ID";
export const RECEIVE_NEW_POST_FOR_TOPIC = "RECEIVE_NEW_POST_FOR_TOPIC";
export const RECEIVE_UPDATED_POST_FOR_TOPIC = "RECEIVE_UPDATED_POST_FOR_TOPIC";
export const UPDATE_SELECTED_TOPIC_CATEGORY_ID =
  "UPDATE_SELECTED_TOPIC_CATEGORY_ID";
export const UPDATE_EDIT_POST_OR_COMMENT_ERROR =
  "UPDATE_EDIT_POST_OR_COMMENT_ERROR";
export const DELETE_COMMUNITY_POST_FOR_TOPIC =
  "DELETE_COMMUNITY_POST_FOR_TOPIC";

// Community toast notifications
export const TOAST_NOTIFICATION_DISMISSED = "TOAST_NOTIFICATION_DISMISSED";
export const TOAST_NOTIFICATION_TRIGGERED = "TOAST_NOTIFICATION_TRIGGERED";

// Ketones Form
export const GET_KETONES_FEEDBACK_REQUEST = "GET_KETONES_FEEDBACK_REQUEST";
export const GET_KETONES_FEEDBACK_SUCCESS = "GET_KETONES_FEEDBACK_SUCCESS";
export const GET_KETONES_FEEDBACK_FAILURE = "GET_KETONES_FEEDBACK_FAILURE";
export const SUBMIT_KETONES_REQUEST = "SUBMIT_KETONES_REQUEST";
export const SUBMIT_KETONES_SUCCESS = "SUBMIT_KETONES_SUCCESS";
export const SUBMIT_KETONES_FAILURE = "SUBMIT_KETONES_FAILURE";

// User Settings
export const GET_USER_SETTINGS_REQUEST = "GET_USER_SETTINGS_REQUEST";
export const GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS";
export const GET_USER_SETTINGS_FAILURE = "GET_USER_SETTINGS_FAILURE";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const SUBMIT_NEW_SETTINGS_REQUEST = "SUBMIT_NEW_SETTINGS_REQUEST";
export const SUBMIT_NEW_SETTINGS_SUCCESS = "SUBMIT_NEW_SETTINGS_SUCCESS";
export const SUBMIT_NEW_SETTINGS_FAILURE = "SUBMIT_NEW_SETTINGS_FAILURE";
export const UPDATE_NEW_SETTINGS = "UPDATE_NEW_SETTINGS";
export const GET_USER_ACTION_LIST_SETTINGS_REQUEST =
  "GET_USER_ACTION_LIST_SETTINGS_REQUEST";
export const GET_USER_ACTION_LIST_SETTINGS_SUCCESS =
  "GET_USER_ACTION_LIST_SETTINGS_SUCCESS";
export const GET_USER_ACTION_LIST_SETTINGS_FAILURE =
  "GET_USER_ACTION_LIST_SETTINGS_FAILURE";

// Care Plan
export const GET_CARE_PLAN_REQUEST = "GET_CARE_PLAN_REQUEST";
export const GET_CARE_PLAN_SUCCESS = "GET_CARE_PLAN_SUCCESS";
export const GET_CARE_PLAN_FAILURE = "GET_CARE_PLAN_FAILURE";

// Current User
export const SET_USER = "SET_USER";

// Action List / TDF
export const LOAD_PATIENT_ACTIONS_SUCCESS = "LOAD_PATIENT_ACTIONS_SUCCESS";
export const LOAD_PATIENT_ACTION_SUCCESS = "LOAD_PATIENT_ACTION_SUCCESS";
export const COMPLETE_PATIENT_ACTION_SUCCESS =
  "COMPLETE_PATIENT_ACTION_SUCCESS";
export const REMOVE_PATIENT_ACTION_SUCCESS = "REMOVE_PATIENT_ACTION_SUCCESS";

// Payment Info
export const EDIT_CREDIT_CARD_INFO = "EDIT_CREDIT_CARD_INFO";
export const INITIALIZE_PAYMENT_INFO = "INITIALIZE_PAYMENT_INFO";
export const CREDIT_CARD_INFO_SUBMITTED = "CREDIT_CARD_INFO_SUBMITTED";

// NavBar
export const SET_ASSISTANT_OPEN = "SET_ASSISTANT_OPEN";
export const SET_ASSISTANT_SEARCH_TERM = "SET_ASSISTANT_SEARCH_TERM";
