import classNames from "classnames/bind";
import React from "react";

import Accessory, { AccessoryType } from "../Accessory";

import styles from "./styles.css";

const cx = classNames.bind(styles);

interface Props {
  action?: string;
  link?: string;
  label?: string;
  children?: any;
  accessory?: AccessoryType;
  accessoryAction?: () => void;
  onClick?: () => void;
  target?: string | undefined;
  style?: string;
}

const Cell: React.FC<Props> = ({
  action,
  link,
  label,
  accessory,
  accessoryAction,
  onClick,
  children,
  target,
  style: inlineStyles,
}: Props): React.ReactElement<any> => {
  const cellClasses = cx(
    {
      cellLink: !!link,
      cellLabel: !!label,
      cell: !link && !label,
    },
    inlineStyles
  );
  /**
   * When provided a link, children are typically not rendered,
   * only the label, except for edge-cases (like the Log Out button on
   * Profile which has unique text styling). A disclosure arrow accessory is
   * added by default unless you specify accessory='none'.
   */
  if (link) {
    return (
      <a
        href={link}
        data-action={action || null}
        target={target || undefined}
        className={cellClasses}
        onClick={onClick}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {label && <div className={styles.cellLinkLabel}>{label}</div>}
        {children}
        {accessory === "none" ? null : <Accessory kind="disclosureArrow" />}
      </a>
    );
  }
  // Cell with a label positioned above the content and optional accessory
  // See 'My Profile' Name and Phone fields for example usage
  if (label) {
    return (
      <div className={styles.cellWithLabel}>
        <div className={styles.cellWithLabelContent}>
          <div className={cellClasses}>{label}</div>
          {children}
        </div>
        {accessory && <Accessory kind={accessory} onClick={accessoryAction} />}
      </div>
    );
  }
  // Cell with no label or accessory; appropriate for arbitrary content
  return <div className={cellClasses}>{children}</div>;
};

export default Cell;
