import React from "react";

import { intlFormatMessage, messages } from "@virtahealth/utils";

import {
  getCurrentPlanDefinitionName,
  isOnReversal,
} from "../../../helpers/planDefinition";
import urls from "../../urls";
import AddMenuItem from "../AddMenuItem";
import { AddBiomarkerType } from "../AddMenuItem/constants";

const AddMenu = (): React.ReactElement<any> => {
  const planDefinition = getCurrentPlanDefinitionName();

  return (
    <ul className="navbar-menu navbar-menu--add" style={{ display: "none" }}>
      {isOnReversal(planDefinition) && (
        <AddMenuItem
          type={AddBiomarkerType.Symptoms}
          url={urls.dailyLog}
          label={intlFormatMessage(messages.dailyLog)}
        />
      )}
      <AddMenuItem
        action="show_blood_pressure_form"
        type={AddBiomarkerType.BloodPressure}
        url={urls.bloodPressure}
        label={intlFormatMessage(messages.bloodPressure)}
      />
      <AddMenuItem
        action="show_weight_form"
        type={AddBiomarkerType.Weight}
        url={urls.weight}
        label={intlFormatMessage(messages.weight)}
      />
      {isOnReversal(planDefinition) && (
        <AddMenuItem
          action="show_ketone_form"
          type={AddBiomarkerType.Ketones}
          url={urls.ketones}
          label={intlFormatMessage(messages.ketones)}
        />
      )}
      <AddMenuItem
        action="show_blood_glucose_form"
        type={AddBiomarkerType.Glucose}
        url={urls.glucose}
        label={intlFormatMessage(messages.glucose)}
      />
    </ul>
  );
};

export default AddMenu;
