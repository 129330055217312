export const FEEDBACK_URL =
  "https://virtahealth.zendesk.com/hc/en-us/requests/new?ticket_form_id=360004794273";
export const TECH_SUPPORT_URL = "https://virtahealth.zendesk.com/hc/en-us";
export const REQUEST_COACH_CHANGE_URL =
  "https://virtahealth.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001039294";
export const SUPPLIES_SUPPORT_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSeEUYrV3Fk9SKvVQSupUqTSVrkP0YoJaS0MLeYiTl15HnolAA/viewform?usp=pp_url";
export const SUPPLIES_SUPPORT_QUERY_EMAIL_KEY = "entry.1124883925";
export const SUPPLIES_SUPPORT_QUERY_USER_ID_KEY = "entry.328833739";
export const VIRTA_STORE_URL = "https://virtahealth.axomo.com/";

export const ENTERPRISE_PARTICIPANT_TYPE = "Enterprise";

export const APP_PATHS = {
  profile: "/profile",
  settings: "/settings",
  paymentInfo: "/payment-info",
  carePlan: "/care-plan",
  weeklySummary: "/weekly-summary",
  resourceCenter: "/resources",
  educationVideos: "/et",
  foodGuide: "/food-guide",
  hrLove: "/hr-love",
  requestSupplies: "/request-supplies",
  cheatSheet: "/discover/content/b98fb3f6-f994-41db-8b15-45de52667c12",
  mySupplyShipments: "/my-supply-shipments",
  contactSupport: "/contact_support",
  logout: "/user/logout",
  labWork: "/lab-work",
};
