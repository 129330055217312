import * as LDClient from "launchdarkly-js-client-sdk";

import { LaunchDarklyClient } from "@virtahealth/components";

const WEB_KEYS: Record<string, string> = {
  prod: "6018519598f3650a574fadc6",
  stage: "60185196c7879209e528a500",
  dev: "601851964c76cc0a5d59b5b0",
};

class VirtaLDClient implements LaunchDarklyClient {
  client: LDClient.LDClient | undefined = undefined;
  configuring: Promise<null> | null = null;
  identifying: Promise<null> | null = null;
  initialized = false;

  async awaitLoading() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (this.configuring) {
        await this.configuring;
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (this.identifying) {
        await this.identifying;
      }
    } catch (e) {
      return false;
    }
    return this.initialized;
  }

  /**
   * setup will configure the launch darkly SDK using the given environment. this must be done before anything else and
   * should only be done once
   */

  public async setup(environment: string, virtaId?: string) {
    this.initialized = true;
    await this.awaitLoading();
    // if we're given a virtaId, we can log the user in immediately. if not, make the session anonymous
    // logging in causes all variant flag calls to use the given user for their context

    const userConfig = virtaId
      ? {
          key: virtaId,
          anonymous: false,
        }
      : { anonymous: true };

    // we use a promise model to transform the callback function into something we can awit
    this.configuring = new Promise((resolve) => {
      this.client = LDClient.initialize(WEB_KEYS[environment], userConfig);
      this.client.on("ready", () => {
        resolve(null);
      });
    });
  }

  /**
   * login will wait for initialization to finish and then attempt to log the given user into LD
   * this causes all variant flag calls to use the given user for their context
   * this function will fail if setup hasn't been called yet
   */

  public async identify(virtaId: string, custom: { [key: string]: any } = {}) {
    if (!(await this.awaitLoading())) {
      return;
    }

    this.client!.flush();
    const user = {
      key: virtaId,
      anonymous: false,
      custom,
      privateAttributeNames: Object.keys(custom),
    };

    // we use a promise model to transform the callback function into something we can awit
    this.identifying = new Promise((resolve) => {
      // @ts-ignore - (Garrett) TS doesn't think this should be null but leaving since it was already like this
      this.client!.identify(user, null, () => {
        resolve(null);
      });
    });
  }

  public async getUser() {
    if (!(await this.awaitLoading())) {
      return;
    }

    // we use a promise model to transform the callback function into something we can awit
    return this.client!.getUser();
  }

  /**
   * Getting all flags WILL return values relative to the current user
   */

  public async getAllFlags<
    LDFlagSet = Record<string, any>,
  >(): Promise<LDFlagSet> {
    if (!(await this.awaitLoading())) {
      return {} as LDFlagSet;
    }
    return this.client!.allFlags() as LDFlagSet;
  }

  /**
   * The following functions will wait for any ongoing setup to complete, then attempt to retrieve the given
   * flag value for the current user. The provided default return value will be returned if setup is not
   * complete or launch darkly has an error
   */

  async getVariationFlag<T>(
    flag: string,
    defaultReturnValue: any,
    logAmplitude = true
  ): Promise<T> {
    if (!(await this.awaitLoading())) {
      return defaultReturnValue;
    }
    const val = this.client!.variation(flag, defaultReturnValue);
    if (logAmplitude) {
      window?.virta?.amplitude
        ?.getInstance()
        ?.logEvent(`Feature Flag Evaluated`, {
          product: "Participant App Web",
          type: flag,
          status: val,
        });
    }
    return val as T;
  }

  public async getJSONFlag<T = Record<string, any>>(
    flag: string,
    defaultReturnValue: Record<string, any>
  ): Promise<T> {
    return this.getVariationFlag<T>(flag, defaultReturnValue);
  }

  public async getBooleanFlag(
    flag: string,
    defaultReturnValue: boolean,
    project?: string,
    logAmplitude?: boolean
  ): Promise<boolean> {
    return this.getVariationFlag(flag, defaultReturnValue, logAmplitude);
  }

  public async getStringFlag(
    flag: string,
    defaultReturnValue: string,
    project?: string,
    logAmplitude?: boolean
  ): Promise<string> {
    return this.getVariationFlag(flag, defaultReturnValue, logAmplitude);
  }

  public async getIntFlag(
    flag: string,
    defaultReturnValue: number
  ): Promise<number> {
    return this.getVariationFlag(flag, defaultReturnValue);
  }

  public async getFloatFlag(
    flag: string,
    defaultReturnValue: number
  ): Promise<number> {
    if (!(await this.awaitLoading())) {
      return defaultReturnValue;
    }
    return this.client!.variation(flag, defaultReturnValue) as number;
  }
}

export const VirtaLaunchDarklyClient = new VirtaLDClient();
